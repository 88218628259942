/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from "react"
import Helmet from "react-helmet"

interface SEOPros {
  title: string
  description?: string
  lang?: string
  meta?: JSX.IntrinsicElements["meta"][]
}

const SEO: FC<SEOPros> = ({ description = "", lang = "en", meta = [], title }) => {
  const metaDescription =
    description ||
    "The best way to be a movie watcher. Track movies you watch, send movie recommendations to friends, and join others to watch movies together."

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | MovieOtter`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "@isaiahbydayah",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...meta,
      ]}
    />
  )
}

export default SEO

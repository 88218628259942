import React, { FC, useState, useEffect } from "react"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core"
import { AddRounded, SearchRounded, CloseRounded } from "@material-ui/icons"
import { Link, useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"

import { useAuth } from "providers/AuthProvider"

import useSnapshot from "hooks/useSnapshot"
import useListSearch from "hooks/useListSearch"

import SEO from "components/SEO"
import PromptLoginDisplay from "components/PromptLoginDisplay"
import MovieList from "components/MovieList"
import RaisedTextField from "components/RaisedTextField"
import Heading from "components/Heading"

const MAX_LIST_ITEMS_TO_SHOW = 12

const ListsPage: FC = () => (
  <>
    <SEO title="Your Lists" />
    <ListsPageContent />
  </>
)

const ListsPageContent: FC = () => {
  const history = useHistory()
  const location = useLocation()

  // SEARCHING
  const { query, debouncedQuery, setQuery, loading, lists } = useListSearch()
  useEffect(() => {
    // Default to url param
    const queryFromUrl = queryString.parse(location.search)
    setQuery((queryFromUrl.query as string) || "")
  }, [])
  useEffect(() => {
    let newUrl
    if (debouncedQuery) {
      firebase.analytics().logEvent("search-lists", {
        query: debouncedQuery,
      })
      newUrl = location.pathname + "?" + queryString.stringify({ query: debouncedQuery })
      // newUrl =
      //   window.location.protocol +
      //   "//" +
      //   window.location.host +
      //   window.location.pathname +
      //   "?" +
      //   queryString.stringify({ query: debouncedQuery })
    } else {
      // newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname
      newUrl = location.pathname
    }

    history.replace(newUrl)
    // window.history.pushState?.({ path: newUrl }, "", newUrl)
  }, [debouncedQuery])

  // USER LISTS
  const { currentUser } = useAuth()

  const { data: favoritesData } = useSnapshot<movieotter.FavoritesData>(
    movieotter.Favorites.documentPathForUser(currentUser?.userId ?? "_")
  )
  const { data: wlData } = useSnapshot<movieotter.WatchLatersData>(
    movieotter.WatchLaters.documentPathForUser(currentUser?.userId ?? "_")
  )

  const [favoritedMovies, setFavoritedMovies] = useState<movieotter.FavoritedMovie[]>([])
  const [watchLaterMovies, setWatchLaterMovies] = useState<movieotter.WatchLateredMovie[]>([])
  const [userLists, setUserLists] = useState<movieotter.MovieList[]>([])

  useEffect(() => {
    let favoriteUnsubscribe: (() => void) | undefined
    let watchLaterUnsubscribe: (() => void) | undefined
    let userListsUnsubscribe: (() => void) | undefined

    if (currentUser?.userId) {
      // TODO: See if we can refactor this into a collectionGroup to query? Might be easier
      const favoritesCollectionPath = `${movieotter.Favorites.documentPathForUser(currentUser?.userId)}/${
        movieotter.FavoritedMovie.COLLECTION_NAME
      }`
      const watchLaterCollectionPath = `${movieotter.WatchLaters.documentPathForUser(currentUser?.userId)}/${
        movieotter.WatchLateredMovie.COLLECTION_NAME
      }`

      favoriteUnsubscribe = firebase
        .firestore()
        .collection(favoritesCollectionPath)
        .where("userId", "==", currentUser?.userId)
        .limit(MAX_LIST_ITEMS_TO_SHOW)
        .onSnapshot(snapshot => {
          const movieDatas = snapshot.docs.map(
            doc =>
              doc.data({
                serverTimestamps: "estimate",
              }) as movieotter.FavoritedMovieData<movieotter.FirebaseTimestamp>
          )
          const movies = movieDatas.map(data => movieotter.FavoritedMovie.fromDocument(data))

          setFavoritedMovies(movies)
        })

      watchLaterUnsubscribe = firebase
        .firestore()
        .collection(watchLaterCollectionPath)
        .where("userId", "==", currentUser?.userId)
        .limit(MAX_LIST_ITEMS_TO_SHOW)
        .onSnapshot(snapshot => {
          const movieDatas = snapshot.docs.map(
            doc =>
              doc.data({
                serverTimestamps: "estimate",
              }) as movieotter.WatchLateredMovieData<movieotter.FirebaseTimestamp>
          )
          const movies = movieDatas.map(data => movieotter.WatchLateredMovie.fromDocument(data))

          setWatchLaterMovies(movies)
        })

      userListsUnsubscribe = firebase
        .firestore()
        .collection(movieotter.MovieList.collectionPath())
        .where("creatorId", "==", currentUser?.userId)
        .onSnapshot(snapshot => {
          const listDatas = snapshot.docs.map(
            doc =>
              doc.data({
                serverTimestamps: "estimate",
              }) as movieotter.IMovieList<movieotter.FirebaseTimestamp>
          )
          const lists = listDatas.map(data => movieotter.MovieList.fromDocument(data))

          setUserLists(lists)
        })
    }

    return () => {
      favoriteUnsubscribe?.()
      watchLaterUnsubscribe?.()
      userListsUnsubscribe?.()
    }
  }, [currentUser?.userId])

  return (
    <>
      <Box py={2}>
        <Container>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={10} md={6}>
              <RaisedTextField
                placeholder="Search Lists..."
                trailing={
                  loading ? (
                    <CircularProgress size={16} />
                  ) : query ? (
                    <CloseRounded onClick={() => setQuery("")} />
                  ) : (
                    <SearchRounded color="disabled" />
                  )
                }
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {lists.length ? (
        <Container maxWidth="sm">
          <Typography variant="h5">Search Results</Typography>
          <List>
            {lists.map(list => (
              <ListItem key={list.listId}>
                <Link to={`/user/${list.creatorUsername}`}>
                  <ListItemAvatar>
                    <Avatar src={list.creatorPhotoURL} alt={list.creatorDisplayName} />
                  </ListItemAvatar>
                </Link>

                <ListItemText
                  primary={
                    <Link
                      to={`/list/${list.listId}`}
                    >{`@${list.creatorUsername} - ${list.title} (${list.count})`}</Link>
                  }
                  secondary={list.description || null}
                />
              </ListItem>
            ))}
          </List>
        </Container>
      ) : (
        <>
          {currentUser ? (
            <>
              <Container>
                <Heading variant="h5">Your Lists</Heading>
              </Container>

              <MovieList
                movies={favoritedMovies}
                headingProps={{
                  heading: favoritesData ? `Favorited Movies (${favoritesData.movies})` : "Favorited Movies",
                  to: "/favorites",
                }}
              />
              <MovieList
                movies={watchLaterMovies}
                headingProps={{
                  heading: wlData ? `Watch Later (${wlData.movies})` : "Watch Later",
                  to: "/watch-later",
                }}
              />
              <Container>
                <Heading
                  variant="h5"
                  onClick={() => history.push(`/list`)}
                  onClickLabel={
                    <>
                      <AddRounded fontSize="small" />
                      Create
                    </>
                  }
                >
                  Custom Lists
                </Heading>
              </Container>
              {userLists.map(list => (
                <Box key={list.listId} py={1}>
                  <MovieList
                    movies={list.recentlyAdded}
                    headingProps={{
                      heading: `${list.title} (${list.count})`,
                      to: `/list/${list.listId}`,
                      onClick: () => history.push(`/list/${list.listId}/edit`),
                      onClickLabel: "Edit",
                    }}
                  />
                </Box>
              ))}
            </>
          ) : (
            <PromptLoginDisplay title="Get started building your own personal lists" />
          )}
        </>
      )}
    </>
  )
}

export default ListsPage

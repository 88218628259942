import React, { FC } from "react"
import { Box, Container, Typography, Grid } from "@material-ui/core"

import MoviePoster, { MoviePosterProps } from "components/MoviePoster"
import Heading, { HeadingProps } from "components/Heading"

const MovieGrid: FC<{
  className?: string
  movies?: MoviePosterProps[]
  generateKey?: (movie: MoviePosterProps, index: number) => string
  headingProps?: HeadingProps
}> = ({ className, movies = [], headingProps, generateKey }) => (
  <Box className={className}>
    <Container>
      {headingProps && <Heading {...headingProps} />}
      <Grid container spacing={2}>
        {movies.length ? (
          movies.map((movie, index) => (
            <Grid
              key={generateKey?.(movie, index) ?? `${index}-${movie.movieData?.movieId}`}
              item
              xs={6}
              sm={4}
              md={3}
              xl={2}
            >
              <MoviePoster {...movie} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography align="center">List is Empty...</Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  </Box>
)

export default MovieGrid

import { useState, useEffect } from "react"

// NOTE: Might can improve this by using MutationObserver
// Ref: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
const useLoadScript = (src: string, id: string) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (typeof window === "undefined") {
      console.warn("NO WINDOW!")
      return
    }

    let script = document.getElementById(id)!

    if (!script) {
      script = document.createElement("script")
      script.setAttribute("id", id)
      script.setAttribute("src", src)
    }

    if (script.dataset.loaded === "true") {
      setLoaded(true)
      return
    }

    let running = true
    script.addEventListener("load", () => {
      script.dataset["loaded"] = "true"
      if (running) setLoaded(true)
    })
    document.body.appendChild(script)

    return () => {
      running = false
    }
  }, [src, id])

  return loaded
}

export default useLoadScript

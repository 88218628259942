import React, { FC } from "react"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import { useAuth } from "providers/AuthProvider"

import useQuery from "hooks/useQuery"
import useSnapshot from "hooks/useSnapshot"

import ErrorDisplay from "components/ErrorDisplay"
import LoadingDisplay from "components/LoadingDisplay"
import MovieGrid from "components/MovieGrid"

const WatchLatersPage: FC = () => {
  const { currentUser } = useAuth()

  // NOTE: when we make favorite pages publicable, add <SEO /> component
  return <WatchLatersPageContent user={currentUser} />
}

interface WatchLatersPageContentProps {
  user?: movieotter.User
}

const WatchLatersPageContent: FC<WatchLatersPageContentProps> = ({ user }) => {
  const { loading: wlLoading, data: wlData, error: wlError } = useSnapshot<movieotter.WatchLatersData>(
    movieotter.WatchLaters.documentPathForUser(user?.userId ?? "_")
  )

  const { loading: moviesLoading, error: moviesError, data: movies } = useQuery<movieotter.WatchLateredMovieData>(
    `/${movieotter.WatchLaters.COLLECTION_NAME}/${user?.userId ?? "_"}/movies`
  )

  // if user is logged in - Error
  if (!user) {
    return <ErrorDisplay message="Please login..." />
  }

  if (wlError || moviesError) {
    console.log("Favorites Error: ", wlError?.type)
    console.log("Movies Error: ", moviesError?.type)
    return <ErrorDisplay message="Something went wrong grabbing your favorites..." />
  }

  // if users favorite movies are loading - loading
  if (wlLoading || moviesLoading) {
    return <LoadingDisplay />
  }

  // TODO: fix - this extra check shouldn't be necessary
  if (!wlData) {
    return <ErrorDisplay message="Something went wrong grabbing your favorites data..." />
  }

  return (
    <MovieGrid
      movies={movies.map(movie => ({
        movieData: movie,
      }))}
      headingProps={{
        heading: `Watch Later (${wlData.movies})`,
      }}
    />
  )
}

export default WatchLatersPage

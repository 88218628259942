import React, { FC } from "react"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import { Box, Container, Typography, makeStyles } from "@material-ui/core"
import cx from "classnames"

import useBreakpoint, { Breakpoint } from "hooks/useBreakpoint"

import MoviePoster, { MovieSource, MoviePosterAction } from "components/MoviePoster"
import Heading, { HeadingProps } from "components/Heading"

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  list: {
    display: "flex",
    overflowX: "auto",
    paddingBottom: spacing(),
    paddingLeft: spacing(2),

    [breakpoints.up("sm")]: {
      paddingLeft: 0,
    },
  },
  item: {
    flexBasis: "40%",
    flexGrow: 0,
    flexShrink: 0,

    "&:not(:first-child)": {
      marginLeft: spacing(2),
    },

    [breakpoints.up("sm")]: {
      flexBasis: "30%",
    },
    [breakpoints.up("md")]: {
      flexBasis: "20%",
    },
    [breakpoints.up("lg")]: {
      flexBasis: "15%",
    },

    "&.end": {
      flexBasis: 0,
      margin: 0,
      paddingLeft: spacing(2),

      [breakpoints.up("sm")]: {
        display: "none",
      },
    },
  },
}))

// TODO: Refactor to take in MoviePosterData[]
const MovieList: FC<{
  className?: string
  to?: string
  movies?: movieotter.SimpleMovieData[]
  source?: MovieSource
  onClick?: (movie: movieotter.SimpleMovieData) => void
  headingProps?: HeadingProps
  action?: MoviePosterAction
  onDelete?: (movieData: movieotter.SimpleMovieData) => void
  generateKey?: (movie: movieotter.SimpleMovieData, index: number) => string
}> = ({ className, movies = [], source, onClick, headingProps, action, onDelete, generateKey }) => {
  const classes = useStyles()
  const breakpoint = useBreakpoint()
  return (
    <Box className={className} py={2}>
      {headingProps && (
        <Container>
          <Heading {...headingProps} />
        </Container>
      )}
      {/* <Container>{headingProps && <Heading {...headingProps} />}</Container> */}
      <Container disableGutters={breakpoint === Breakpoint.xs}>
        {movies.length ? (
          <Box className={classes.list}>
            {movies.map((movie, index) => (
              <Box key={generateKey?.(movie, index) ?? movie.movieId} className={classes.item}>
                <MoviePoster movieData={movie} source={source} onClick={onClick} action={action} onDelete={onDelete} />
              </Box>
            ))}
            <Box className={cx(classes.item, "end")} />
          </Box>
        ) : (
          <Box px={2}>
            <Typography align="center">List is Empty...</Typography>
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default MovieList

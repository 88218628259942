import { useState, useEffect } from "react"
import firebase from "firebase"

import MOError, { MOErrorType } from "utils/error"

const useDocExists = (path?: string) => {
  const [loading, setLoading] = useState(true)
  const [exists, setExists] = useState(false)
  const [error, setError] = useState<MOError | null>(null)

  useEffect(() => {
    let unsubscribe: (() => void) | undefined
    if (!path) {
      setLoading(false)
      setExists(false)
      setError(new MOError(MOErrorType.MISSING_PARAMETERS, "Please provide a document path."))
    } else {
      setLoading(true)
      unsubscribe = firebase
        .firestore()
        .doc(path)
        .onSnapshot(
          snapshot => {
            setLoading(false)
            setError(null)
            setExists(snapshot.exists)
          },
          (e: Error) => {
            console.log("DOC EXISTS ERROR: ", JSON.stringify(e))
            if (e.name == "permission-denied") return setError(new MOError(MOErrorType.REQUIRES_LOGIN))
            setError(new MOError(MOErrorType.FAILED_OPERATION))
          }
        )
    }

    return () => {
      unsubscribe?.()
    }
  }, [path])

  return { loading, exists, error }
}

export default useDocExists

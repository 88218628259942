import React, { FC, ReactNode } from "react"
import { Box, Typography } from "@material-ui/core"

interface ErrorDisplayProps {
  title?: string
  message?: ReactNode
}

const ErrorDisplay: FC<ErrorDisplayProps> = ({ title = "Uh Oh!", message, children }) => (
  <Box py={4}>
    <Typography variant="h4" align="center" gutterBottom>
      {title}
    </Typography>
    {(message || children) && (
      <Typography variant="body2" align="center">
        {message || children}
      </Typography>
    )}
  </Box>
)

export default ErrorDisplay

import { useState, useEffect } from "react"

const useDebouncedState = <T>(
  initialValue: T | (() => T),
  delay = 500
): [T, T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(initialValue)
  const [internalValue, setInternalValue] = useState<T>(initialValue)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(internalValue)
    }, delay)

    return () => clearTimeout(timeout)
  }, [internalValue, delay])

  return [internalValue, value, setInternalValue]
}

export default useDebouncedState

import { useState, useEffect } from "react"
import firebase from "firebase"
import algoliasearch from "algoliasearch"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import useDebounceState from "hooks/useDebounceState"

// TODO: refactor into api for DRY searching
const algolia = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID ?? "", process.env.REACT_APP_ALGOLIA_API_KEY ?? "")
const userIndex = algolia.initIndex(
  `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX ?? "err_"}${movieotter.User.COLLECTION_NAME}`
)
if (
  !process.env.REACT_APP_ALGOLIA_APP_ID ||
  !process.env.REACT_APP_ALGOLIA_API_KEY ||
  !process.env.REACT_APP_ALGOLIA_INDEX_PREFIX
) {
  console.error("MISSING ALGOLIA ENVIRONMENT VARIABLES")
}

const useUserSearch = (initialQuery = "") => {
  const [loading, setLoading] = useState(false)
  const [query, debouncedQuery, setQuery] = useDebounceState(initialQuery)
  const [users, setUsers] = useState<movieotter.SimpleUser[]>([])

  useEffect(() => {
    let running = true

    if (debouncedQuery) {
      firebase.analytics().logEvent("search-users", {
        query: debouncedQuery,
      })
      userIndex.search<movieotter.SimpleUserData>(debouncedQuery).then(({ hits }) => {
        console.log("USER HITS: ", hits)
        const users: movieotter.SimpleUser[] = hits.map(hit => movieotter.SimpleUser.fromDocument(hit))
        if (running) {
          setLoading(false)
          setUsers(users)
        }
      })
    } else {
      setLoading(false)
      setUsers([])
    }

    return () => {
      running = false
    }
  }, [initialQuery, debouncedQuery])

  useEffect(() => {
    if (query === debouncedQuery) setLoading(false)
    else setLoading(true)
  }, [query])

  return { query, loading, users, setQuery }
}

export default useUserSearch

import React, { FC } from "react"
import { Button } from "@material-ui/core"
import { ButtonProps } from "@material-ui/core/Button"

import { useAuth } from "providers/AuthProvider"

const LoginButton: FC<ButtonProps> = props => {
  const { currentUser, openLoginModal } = useAuth()

  if (currentUser) return null

  return (
    <Button color="primary" variant="contained" {...props} onClick={openLoginModal}>
      Log In / Sign Up
    </Button>
  )
}

export default LoginButton

import React, { FC, useEffect, useState } from "react"
// import { useMatch, navigate } from "@reach/router"
import { useRouteMatch, useParams, useHistory } from "react-router-dom"
import {
  Box,
  Typography,
  Button,
  Modal,
  Paper,
  makeStyles,
  Container,
  ClickAwayListener,
  Grid,
  Dialog,
  CircularProgress,
} from "@material-ui/core"
import { SearchRounded } from "@material-ui/icons"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import dayjs from "dayjs"

import { MOErrorType } from "utils/error"
import { generateId } from "utils/firestore"

import { useAuth } from "providers/AuthProvider"
import { UserProvider } from "providers/UserProvider"

import useMovieList, { IUseMovieList } from "hooks/useMovieList"
import useDebounceState from "hooks/useDebounceState"
import useMovie from "hooks/useMovie"
import useQuery from "hooks/useQuery"
import useTrackVisit from "hooks/useTrackVisit"
import useMovieOtterUser from "hooks/useMovieOtterUser"

import SEO from "components/SEO"
import RaisedTextField from "components/RaisedTextField"
import LoadingDisplay from "components/LoadingDisplay"
import PromptLoginDisplay from "components/PromptLoginDisplay"
import MovieGrid from "components/MovieGrid"
import { UserProfileData } from "components/UserProfile"
import Heading from "components/Heading"

const ListPage: FC = () => {
  const listEditMatch = useRouteMatch("/list/:listId/edit")
  const { listId } = useParams<{ listId?: string }>()

  const editing = !listId || Boolean(listEditMatch)

  const movieListState = useMovieList(listId)

  useTrackVisit("list", { listId, listTitle: movieListState?.list?.title }, movieListState?.list?.listId)

  return (
    <>
      <SEO title={listId ?? "New List"} />
      <ListPageContent {...movieListState} editing={editing} isNew={!listId} />
    </>
  )
}

interface ListPageContentProps extends IUseMovieList {
  editing?: boolean
  isNew: boolean
}

const useStyles = makeStyles(({ palette }) => ({
  contentContainer: {
    outline: 0,
  },
  paper: {
    height: "75vh",
    backgroundColor: palette.background.default,
  },
}))

const ListPageContent: FC<ListPageContentProps> = ({ loading, error, list, editing = false, isNew }) => {
  const history = useHistory()
  const { currentUser } = useAuth()
  const [newMovies, setNewMovies] = useState<movieotter.Movie[]>([])
  const [deletedMovies, setDeletedMovies] = useState<movieotter.SimpleMovieData[]>([])
  const [listData, setListData] = useState(list?.data)
  const [open, setOpen] = useState(false)

  // TODO: Refactor into a query with limit users can see more
  const { loading: queryLoading, error: queryError, data: queryData } = useQuery<movieotter.IMovieListItem>(
    movieotter.MovieListItem.collectionPathFor(listData?.listId ?? "_")
  )

  const creatorContextProps = useMovieOtterUser({ userId: listData?.creatorId })

  const classes = useStyles()

  useEffect(() => setListData(list?.data), [list?.updatedAt])

  if (loading) return <LoadingDisplay />
  if (error) {
    if (error.type == MOErrorType.REQUIRES_LOGIN) {
      return <PromptLoginDisplay />
    }
    return <>{error.message}</>
  }

  const setTitle = (title: string) => {
    if (listData) setListData({ ...listData, title: movieotter.MovieList.formatTitle(title) })
  }

  const setDescription = (title: string) => {
    if (listData)
      setListData({
        ...listData,
        description: movieotter.MovieList.formatDescription(title),
      })
  }

  const saveList = async () => {
    if (!list || !currentUser || !listData) return

    const isNewList = isNew

    const batch = firebase.firestore().batch()

    const newItems = newMovies.map(newMovie => {
      const newItem = movieotter.MovieListItem.new(
        list.listId,
        generateId(),
        currentUser,
        newMovie,
        firebase.firestore.FieldValue.serverTimestamp()
      )
      return newItem
    })

    // Update list data
    const newList = list.copyWith({
      ...listData,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any, // ignore type of server timestamp
    })

    batch.set(firebase.firestore().doc(newList.documentPath), newList.data)

    // Add new Movies
    newItems.forEach(newItem => {
      batch.set(firebase.firestore().doc(newItem.documentPath), newItem.data)
    })

    // TODO: remove deleted movies from recently added
    deletedMovies.map(movie => {
      const movieListItem = queryData.find(m => m.movieId === movie.movieId)
      if (movieListItem) {
        batch.delete(
          firebase.firestore().doc(movieotter.MovieListItem.documentPathFor(list.listId, movieListItem.itemId))
        )
      }
    })

    try {
      await batch.commit()
      if (isNewList) {
        firebase.analytics().logEvent("list-created", {
          listId: listData.listId,
          listTitle: listData.title,
          moviesAdded: newMovies.length,
          moviesDeleted: deletedMovies.length,
        })
      } else {
        firebase.analytics().logEvent("list-edit-saved", {
          listId: listData.listId,
          listTitle: listData.title,
          moviesAdded: newMovies.length,
          moviesDeleted: deletedMovies.length,
        })
      }
      setNewMovies([])
      setDeletedMovies([])
      history.replace(`/list/${listData.listId}`)
    } catch (e) {
      console.log("BATCH ERROR: ", e)
      firebase.analytics().logEvent("error-list-edit-save", {
        error: e,
      })
    }
  }

  const editable = currentUser && listData?.creatorId === currentUser.userId
  const editMode = editable && editing

  return (
    <>
      <Container>
        <Box pb={2}>
          <UserProvider {...creatorContextProps}>
            <UserProfileData />
          </UserProvider>
        </Box>
        <Box pb={2}>
          <Heading
            onClickLabel={editable ? (editMode ? "Save" : "Edit") : undefined}
            onClick={
              editable ? (editMode ? saveList : () => history.replace(`/list/${listData?.listId}/edit`)) : undefined
            }
          >
            Title:
          </Heading>
          {editMode ? (
            <RaisedTextField
              value={listData?.title || ""}
              onChange={e => setTitle(e.target.value)}
              placeholder="List Title..."
            />
          ) : (
            <Typography>{listData?.title}</Typography>
          )}
        </Box>
        <Box pb={2}>
          <Heading>Description:</Heading>
          {editMode ? (
            <RaisedTextField
              value={listData?.description || ""}
              onChange={e => setDescription(e.target.value)}
              placeholder="List Description..."
              multiline
              rowsMax={4}
            />
          ) : (
            <Typography>{listData?.description}</Typography>
          )}
        </Box>
        <Box>
          {/* TODO: Refactor heading into seperate component to be shared */}
          <Box display="flex" justifyContent="space-between">
            <Heading>Movies:</Heading>
            {editMode && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    firebase.analytics().logEvent("list-edit-add_movie_clicked", {
                      listId: listData?.listId,
                      listTitle: listData?.title,
                    })
                    setOpen(true)
                  }}
                >
                  Add Movie
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
      <Box pb={2}>
        <MovieGrid
          movies={[
            ...newMovies,
            ...queryData
              ?.sort((a, b) => (b?.addedAt?.valueOf() ?? 0) - (a?.addedAt?.valueOf() ?? 0))
              .map(itemData => movieotter.MovieListItem.fromData(itemData))
              .map(item => ({
                movieId: item.movieId,
                title: item.title,
                posterURL: item.posterURL,
                releaseDate: item.releaseDate,
                runtime: item.runtime,
                lastImportDateTime: item.lastImportDateTime ?? null,
              }))
              .filter(movie => newMovies.find(m => m.movieId === movie.movieId) == null)
              .filter(movie => deletedMovies.find(m => m.movieId === movie.movieId) == null),
          ].map(movie => ({
            movieData: movie,
            action: editMode ? "delete" : "menu",
            onDelete: data => {
              firebase.analytics().logEvent("list-edit-movie_deleted", {
                movieId: data.movieId,
                movieTitle: data.title,
              })
              const newMovieDeleted = newMovies.find(m => m.movieId === data.movieId)
              if (newMovieDeleted) {
                setNewMovies(newMovies.filter(m => m.movieId !== data.movieId))
              } else {
                setDeletedMovies([...deletedMovies, data])
              }
            },
          }))}
        />
      </Box>
      <Container>
        {editMode && (
          <Box>
            <Button variant="contained" color="primary" fullWidth onClick={saveList}>
              Save
            </Button>
          </Box>
        )}
        <Modal
          open={open}
          onClose={() => {
            firebase.analytics().logEvent("list-edit-add_movie_canceled", {
              listId: listData?.listId,
              listTitle: listData?.title,
            })
            setOpen(false)
          }}
        >
          <Box
            className={classes.contentContainer}
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Container>
              <ClickAwayListener
                onClickAway={() => {
                  firebase.analytics().logEvent("list-edit-add_movie_canceled", {
                    listId: listData?.listId,
                    listTitle: listData?.title,
                  })
                  setOpen(false)
                }}
              >
                <Paper className={classes.paper}>
                  <MovieSearch
                    onSelection={selectedMovie => {
                      firebase.analytics().logEvent("list-edit-movie_selected", {
                        movieId: selectedMovie.movieId,
                        movieTitle: selectedMovie.title,
                      })
                      console.log("Movie: ", selectedMovie)
                      if (
                        newMovies.find(movie => movie.movieId === selectedMovie.movieId) == null &&
                        queryData.find(movie => movie.movieId === selectedMovie.movieId) == null
                      ) {
                        setNewMovies([selectedMovie, ...newMovies])
                      }
                      setOpen(false)
                    }}
                  />
                </Paper>
              </ClickAwayListener>
            </Container>
          </Box>
        </Modal>
      </Container>
    </>
  )
}

const useMovieSearchStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  list: {
    overflowY: "scroll",
  },
})

interface MovieSearchProps {
  onSelection: (movie: movieotter.Movie) => void
}

const MovieSearch: FC<MovieSearchProps> = ({ onSelection }) => {
  const [selectedMovie, setSelectedMovie] = useState<number>()
  const [query, debouncedQuery, setQuery] = useDebounceState("")
  const [movieSearchResults, setMovieSearchResults] = useState<movieotter.SimpleMovieData[]>([])
  const { loading, error, movie } = useMovie({ tmdbId: selectedMovie })

  const classes = useMovieSearchStyles()

  const tmdb = new movieotter.TMDB(process.env.REACT_APP_TMDB_V3_API_KEY ?? "")
  if (!process.env.REACT_APP_TMDB_V3_API_KEY) console.error("MISSING TMDB KEY")

  const dialogOpen = Boolean(selectedMovie && loading)

  useEffect(() => {
    if (error && error.type !== MOErrorType.DOES_NOT_MEET_EXPECTATIONS) {
      console.log("ERROR: ", error?.message)
    } else if (movie) {
      onSelection(movie)
    }
  }, [loading, error, movie])

  useEffect(() => {
    let running = true
    if (debouncedQuery) {
      firebase.analytics().logEvent("search-movies", {
        query: debouncedQuery,
      })
      // search TMDB for movies
      tmdb.searchMovies(debouncedQuery, 1).then(movies => {
        if (running) {
          // Store results we get back
          setMovieSearchResults(
            movies.map(m => ({
              movieId: m.id.toString(),
              title: m.title,
              posterURL: m.posterUrl,
              releaseDate: dayjs(m.release_date).format(movieotter.DATE_FORMAT),
              runtime: m.runtime,
              lastImportDateTime: null,
            }))
          )
        }
      })
    } else {
      setMovieSearchResults([])
    }

    return () => {
      running = false
    }
  }, [debouncedQuery])

  return (
    <Box className={classes.root}>
      <Box p={2}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={10} md={6}>
            <RaisedTextField
              placeholder="Search..."
              trailing={<SearchRounded color="disabled" />}
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      {movieSearchResults.length > 0 && (
        <MovieGrid
          className={classes.list}
          movies={movieSearchResults.map(movie => ({
            movieData: movie,
            source: "TMDB",
            onClick: movieData => setSelectedMovie(parseInt(movieData.movieId)),
          }))}
        />
      )}
      <Dialog open={dialogOpen}>
        <Box p={4} display="flex" flexDirection="column" alignItems="center">
          <CircularProgress />
          <Box pt={2}>
            <Typography>Loading movie...</Typography>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}

export default ListPage

import React, { FC, useEffect } from "react"

import useLoadScript from "hooks/useLoadScript"

declare global {
  interface Window {
    Twitch: any
  }
}

interface TwitchStreamProps {
  channel: string
  layout?: "video-with-chat" | "video"
  height?: string | number
  width?: string | number
}

const TwitchStream: FC<TwitchStreamProps> = ({ channel, layout = "video", width = "100%", height = "auto" }) => {
  const targetId = `twitch-stream-embed-${channel}`

  const twitchLoaded = useLoadScript("https://embed.twitch.tv/embed/v1.js", "twitch-script")

  useEffect(() => {
    let _: any
    if (twitchLoaded) {
      _ = new window.Twitch.Embed(targetId, {
        channel,
        layout,
        width,
        height,
        muted: true,
      })
    }

    return () => {
      const targetElem = document.getElementById(targetId)
      if (_ && targetElem) {
        targetElem.innerHTML = ""
      }
    }
  }, [twitchLoaded, channel, layout, height, width])

  return <div key={targetId} id={targetId} style={{ display: "flex", width: "100%" }} />
}

export default TwitchStream

import React, { FC } from "react"
import { Avatar, makeStyles } from "@material-ui/core"
import { AvatarProps } from "@material-ui/core/Avatar"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import cx from "classnames"

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  primary: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
  },
  secondary: {
    backgroundColor: palette.secondary.main,
    color: palette.secondary.contrastText,
  },

  borderPrimary: {
    borderColor: palette.primary.main,
  },
  borderSecondary: {
    borderColor: palette.secondary.main,
  },

  default: {},
  large: {
    height: spacing(10),
    width: spacing(10),
    fontSize: typography.fontSize * 2,
  },
  xLarge: {
    height: spacing(15),
    width: spacing(15),
    fontSize: typography.fontSize * 3,
  },
}))

interface UserAvatarProps extends AvatarProps {
  user: movieotter.SimpleUser
  size?: "default" | "large" | "xLarge"
  color?: "primary" | "secondary"
  borderColor?: "primary" | "secondary" | "initial"
}

const UserAvatar: FC<UserAvatarProps> = ({
  user,
  size = "default",
  color = "primary",
  borderColor = "primary",
  children,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Avatar
      className={cx({
        [classes.default]: size === "default",
        [classes.large]: size === "large",
        [classes.xLarge]: size === "xLarge",
        [classes.primary]: color === "primary",
        [classes.secondary]: color === "secondary",
        [classes.borderPrimary]: borderColor === "primary",
        [classes.borderSecondary]: borderColor === "secondary",
      })}
      alt={user.displayName || "O"}
      src={user.photoURL}
      {...rest}
    >
      {user.initials}
    </Avatar>
  )
}

export default UserAvatar

import React, { FC, createContext, useContext, useState, useRef, ReactNode } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(({ palette }) => ({
  paper: {
    backgroundColor: palette.background.default,
  },
  confirmButton: {
    fontWeight: "bold",
  },
}))

interface ConfirmationOptions {
  title?: string
  body?: string
  cancelLabel?: ReactNode
  confirmLabel?: ReactNode
}

interface ConfirmationProviderProps {
  confirm: (callback: () => void, options?: ConfirmationOptions) => void
}

const ConfirmationContext = createContext<ConfirmationProviderProps>({
  confirm: () => null,
})

const ConfirmationProvider: FC = ({ children }) => {
  const classes = useStyles()
  const [title, setTitle] = useState("")
  const [body, setBody] = useState("")
  const [cancelLabel, setCancelLabel] = useState<ReactNode>()
  const [confirmLabel, setConfirmLabel] = useState<ReactNode>()
  const [open, setOpen] = useState(false)
  const callbackRef = useRef<() => void>()

  const confirm = (callback: () => void, options?: ConfirmationOptions) => {
    callbackRef.current = callback
    setTitle(options?.title ?? "Confirm?")
    setBody(options?.body ?? "Are you sure?")
    setCancelLabel(options?.cancelLabel ?? "No")
    setConfirmLabel(options?.confirmLabel ?? "Yes")
    setOpen(true)
  }

  const onCancel = () => {
    callbackRef.current = undefined
    setOpen(false)
  }

  const onConfirm = () => {
    callbackRef.current?.()
    setOpen(false)
  }

  return (
    <ConfirmationContext.Provider value={{ confirm }}>
      {children}
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ className: classes.paper }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{cancelLabel}</Button>
          <Button className={classes.confirmButton} onClick={onConfirm} color="primary" autoFocus variant="contained">
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmationContext.Provider>
  )
}

export default ConfirmationProvider

export const useConfirmation = () => useContext(ConfirmationContext)

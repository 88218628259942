import React, { FC } from "react"

interface RatingImageProps {
  color?: string
  height?: number
  width?: number
}

const RatingImage: FC<RatingImageProps> = ({ color = "#6c5c43" }) => (
  <svg viewBox="0 0 802.884 710.172" style={{ maxHeight: 175 }}>
    <path fill={color} d="M633.038 710.172L93.241 520.955l153.49-437.877 556.153 171.547-169.846 455.547z" />
    <path fill="#2f2e41" d="M83.884 707.625h648v2h-648z" />
    <path
      d="M631.469 709.144l-2.198-1.135c28.545-55.186 13.28-126.854-4.55-177.257a543.265 543.265 0 00-46.1-97.296l2.09-1.321a546.042 546.042 0 0146.341 97.79c17.995 50.866 33.366 123.248 4.417 179.219zM636.592 520.386l16.045-87.81 2.433.445-16.045 87.81z"
      fill="#2f2e41"
    />
    <path
      fill="#2f2e41"
      d="M544.133 509.076l1.811-1.685 82.858 89.041-1.811 1.686zM652.874 663.338l42.027-58.138 2.005 1.45-42.027 58.137z"
    />
    <circle cx="525.251" cy="492.156" r="12.367" fill="#3f3d56" />
    <circle cx="579.665" cy="504.523" r="12.367" fill="#3f3d56" />
    <circle cx="554.932" cy="552.754" r="12.367" fill="#f2f2f2" />
    <circle cx="709.518" cy="583.671" r="12.367" fill="#3f3d56" />
    <circle cx="668.707" cy="597.274" r="12.367" fill="#f2f2f2" />
    <circle cx="697.151" cy="636.848" r="12.367" fill="#3f3d56" />
    <circle cx="567.299" cy="411.772" r="12.367" fill="#3f3d56" />
    <path
      d="M645.963 554.63l-1.744-1.755c44.11-43.757 51.586-116.65 50.092-170.093a543.266 543.266 0 00-14.003-106.75l2.395-.616a546.042 546.042 0 0114.08 107.296c1.509 53.932-6.085 127.538-50.82 171.918zM708.79 376.556l42.229-78.643 2.179 1.17-42.23 78.643z"
      fill="#2f2e41"
    />
    <path
      fill="#2f2e41"
      d="M624.27 337.41l2.24-1.048 51.519 110.18-2.241 1.048zM680.397 517.609l57.848-42.427 1.463 1.995-57.847 42.427z"
    />
    <circle cx="769.018" cy="266.271" r="12.367" fill="#f2f2f2" />
    <circle cx="611.494" cy="315.511" r="12.367" fill="#3f3d56" />
    <circle cx="659.484" cy="343.987" r="12.367" fill="#3f3d56" />
    <circle cx="621.137" cy="382.295" r="12.367" fill="#f2f2f2" />
    <circle cx="758.764" cy="459.179" r="12.367" fill="#3f3d56" />
    <circle cx="715.748" cy="459.596" r="12.367" fill="#3f3d56" />
    <circle cx="730.668" cy="505.992" r="12.367" fill="#3f3d56" />
    <circle cx="676.19" cy="251.918" r="12.367" fill="#3f3d56" />
    <path
      d="M284.482 109.044a24.897 24.897 0 01-3.093-33.567c-.501.47-.991.958-1.46 1.476a24.9 24.9 0 0036.939 33.4c.469-.52.905-1.056 1.322-1.602a24.897 24.897 0 01-33.708.293z"
      fill="#2f2e41"
    />
    <circle cx="282.148" cy="63.731" r="51.281" fill="#2f2e41" />
    <path
      d="M144.014 695.114s-13.635 13.635 0 13.635 26.679-8.892 26.679-8.892zM342.347 686.568s-3.774 18.91 7.568 11.343 17.255-22.205 17.255-22.205z"
      fill="#2f2e41"
    />
    <path
      d="M195 243.957l-8.893 20.75-54.542 72.327s-37.35 40.314-16.6 42.685 34.385-37.35 34.385-37.35l64.028-63.434 7.114-29.05z"
      fill="#ffb8b8"
    />
    <path
      d="M222.27 159.772l-8.593 2.406s-33.499 59.25-29.942 71.7 19.564 27.864 26.678 26.679 11.857-100.785 11.857-100.785z"
      fill="#2f2e41"
    />
    <path
      d="M249.541 95.745s8.893 36.756 2.372 43.87-20.75 12.45-20.75 12.45l2.371 66.4s27.271-9.486 28.457-10.672 25.493-39.72 25.493-39.72l23.713-22.529s-36.163-17.192-25.492-46.835-36.164-2.964-36.164-2.964z"
      fill="#ffb8b8"
    />
    <circle cx="271.477" cy="78.552" r="30.828" fill="#ffb8b8" />
    <path
      d="M236.499 149.101s-21.936 5.929-23.121 14.229 0 25.492-1.779 29.049-7.114 8.893-4.743 31.421 1.186 36.164 1.186 36.164-120.348 424.479-77.663 436.928 105.527-1.185 104.934-9.485-11.264-172.519-11.264-172.519 62.249 218.168 139.319 167.183 59.878-60.47 52.763-64.027-114.42-172.519-113.826-203.94-1.186-129.834-14.229-144.655c0 0 13.636-45.056 20.157-50.985s22.528-61.656 22.528-61.656-10.078-13.635-18.378-13.635-16.007 2.964-25.492 11.857-44.464 52.17-44.464 52.17-11.264-49.799-5.928-58.099z"
      fill="#2f2e41"
    />
    <ellipse cx="271.18" cy="50.985" rx="26.085" ry="17.785" fill="#2f2e41" />
    <circle cx="315.644" cy="24.9" r="24.9" fill="#2f2e41" />
    <path
      d="M333.43 42.685a24.897 24.897 0 01-24.808-22.825 25.158 25.158 0 00-.092 2.075 24.9 24.9 0 1049.799 0c0-.7-.036-1.39-.093-2.075a24.897 24.897 0 01-24.807 22.825z"
      fill="#2f2e41"
    />
    <path fill="#3f3d56" d="M0 251.002h122.623v122.623H0z" />
    <path
      d="M41.941 310.6s15.513-2.215 20.684-16.25 9.603-25.855 18.467-23.639 3.694 30.287 3.694 30.287 18.467-2.216 19.206 4.432c.611 5.504.21 26.707.054 33.84a14.616 14.616 0 01-2.548 8.08 10.656 10.656 0 01-10.803 4.617 86.239 86.239 0 00-12.356-.646 37.668 37.668 0 01-20.885-6.002l-15.513-.738z"
      fill="#f2f2f2"
    />
    <path fill={color} d="M18.303 300.259h31.764v53.925H18.303z" />
    <path
      d="M71.706 280.449a11.045 11.045 0 01-3.152-.453l.21-.708a10.461 10.461 0 0013.262-8.071l.726.137a11.167 11.167 0 01-11.046 9.095zM318.146 404.625h122.623v122.623H318.146z"
      fill="#3f3d56"
    />
    <path
      d="M360.087 467.649s15.513 2.216 20.684 16.25 9.603 25.855 18.467 23.64 3.693-30.287 3.693-30.287 18.468 2.216 19.206-4.432c.612-5.505.21-26.707.055-33.84a14.616 14.616 0 00-2.548-8.081 10.656 10.656 0 00-10.803-4.617 86.239 86.239 0 01-12.356.646 37.668 37.668 0 00-20.885 6.002l-15.513.739z"
      fill="#f2f2f2"
    />
    <path fill={color} d="M336.449 424.066h31.764v53.925h-31.764z" />
    <path
      d="M389.851 497.8a11.045 11.045 0 00-3.152.454l.211.707a10.461 10.461 0 0113.262 8.072l.726-.138a11.167 11.167 0 00-11.047-9.094z"
      fill="#3f3d56"
    />
    <path
      d="M332.54 238.621l-2.964 33.2 5.928 99.598s13.636 50.392-7.114 49.206-13.043-52.763-13.043-52.763l-9.485-115.605z"
      fill="#ffb8b8"
    />
    <path
      d="M315.347 148.508l15.414 8.3s20.75 72.328 8.893 85.963-36.164 18.971-37.35 13.636-5.335-45.057-5.335-45.057z"
      fill="#2f2e41"
    />
  </svg>
)

export default RatingImage

import React, { FC } from "react"

interface SharingImageProps {
  color?: string
  height?: number
  width?: number
}

const SharingImage: FC<SharingImageProps> = ({ color = "#6c5c43" }) => (
  <svg viewBox="0 0 1109.871 651" style={{ maxHeight: 200 }}>
    <path
      d="M871.698 18.238c-26.542 0-48.059 23.878-48.059 53.334v37.423h11.51l6.664-13.866-1.666 13.866h73.98l6.058-12.605-1.514 12.605H927V79.611c0-33.896-24.76-61.373-55.302-61.373z"
      fill="#2f2e41"
    />
    <ellipse cx="588.429" cy="616" rx="521.442" ry="35" fill="#3f3d56" />
    <path
      d="M237.146 603.521c-.229-.375-5.64-9.41-7.516-28.171-1.72-17.213-.614-46.227 14.432-86.699 28.506-76.67-6.569-138.533-6.927-139.15l1.73-1.003c.091.156 9.142 15.929 14.489 41.044a179.061 179.061 0 01-7.416 99.807c-28.457 76.54-7.301 112.773-7.084 113.13z"
      fill="#3f3d56"
    />
    <circle cx="226" cy="332" r="13" fill={color} />
    <circle cx="267" cy="380" r="13" fill="#3f3d56" />
    <circle cx="239" cy="412" r="13" fill={color} />
    <circle cx="273" cy="439" r="13" fill={color} />
    <circle cx="229" cy="481" r="13" fill="#3f3d56" />
    <path d="M245 604s-13-32 26-56zM229.012 603.42s-5.916-34.03-51.708-33.738z" fill="#3f3d56" />
    <path
      d="M848.5 85.5s15 35-5 42l20 66 34-2 9-22-2-33-3-22s-21 5-19-32zM840.5 126.5s-33-3-30 21 6 29 6 29l3 36-18 11h-17s-52 12-26 25 35-10 35-10 48 0 51-8-4-104-4-104zM904.5 116.5s31-11 33 13 2 26 2 26l6 34s12 30 12 39 9 37 9 37 12 39-1 38-15-42-15-42l-27-54-8-33z"
      fill="#ffb8b8"
    />
    <path
      d="M845.5 122.5h-8l-1 42s-13 18 3 34l4 26 73-3 5-27s10-22-4-42l-13-41h-7s16.213 59.778-18 61c-28 1-34-50-34-50z"
      fill={color}
    />
    <path
      d="M847.5 219.5l-6 20s-125 352-87 361 91 21 96 12 31-272 31-272-4 275 9 275 99 2 104-11-45-361-74-369l-8-18z"
      fill="#2f2e41"
    />
    <path
      d="M919.5 209.5l-79 3s-3.606 27 0 27c1 0 83 7 82-5s-3-25-3-25zM511 400a113.011 113.011 0 01-63.93 101.82c-.01 0-.01 0-.02.01a110.036 110.036 0 01-10.54 4.43c-.57.22-1.15.42-1.74.61-1.2.43-2.42.82-3.65 1.19q-3.795 1.17-7.7 2.07h-.01l-.07.02-.16.03c-.34.08-.69.16-1.04.23q-2.91.645-5.88 1.11A112.114 112.114 0 01398 513c-3.28 0-6.54-.14-9.75-.42a4.556 4.556 0 01-.52-.05 113.435 113.435 0 01-25.86-5.43c-1.24-.42-2.48-.86-3.7-1.33A113.01 113.01 0 11511 400z"
      fill="#3f3d56"
    />
    <path d="M384.574 401.268s26.504-11.55 38.953.65-33.331 8.184-38.953-.65z" fill="#3f3d56" />
    <path
      d="M430.34 346.497c-8.908-15.05-26.529-15.751-26.529-15.751s-17.171-2.196-28.186 20.725c-10.267 21.364-24.437 41.991-2.281 46.993l4.002-12.456 2.478 13.383a86.686 86.686 0 009.48.162c23.726-.766 46.322.224 45.595-8.29-.967-11.318 4.01-30.285-4.56-44.766zM434.77 506.87c.59-.19 1.17-.39 1.74-.61l-.13-.18-1.61-.09-10.42-.56h-.01l-1.75-.09-8.29-.45-18.05-.98-9.67-.52-.49-.03h-.12l-6.81-.37-2.22-.12-2.11 7.74a109.53 109.53 0 0012.9 1.92 4.573 4.573 0 00.52.05c3.21.28 6.47.42 9.75.42a112.118 112.118 0 0018.26-1.48q2.97-.465 5.88-1.11c.35-.07.7-.15 1.04-.23l.16-.03.07-.02h.01q3.9-.9 7.7-2.07z"
      fill="#2f2e41"
    />
    <path
      d="M388.991 379.181s4.016 26.504-4.016 29.717 16.063 16.866 16.063 16.866h13.654l8.835-18.473s-8.032-14.456-3.213-28.11-31.323 0-31.323 0z"
      fill="#a0616a"
    />
    <path
      d="M434.77 506.87v-93.96s-1.02-9.65-9.11-7.14a15.238 15.238 0 00-2.4 1 27.74 27.74 0 00-3.82 2.49c-.46.35-.93.73-1.42 1.14-.04.03-.08.07-.12.1-15.26 12.85-22.48 4.02-24.09 2.41-.59-.58-2.13-2.23-3.86-4.09v-.01c-.75-.8-1.53-1.64-2.27-2.46-2.29-2.47-4.31-4.68-4.31-4.68l-8.03 6.42 1.96 48.68.46 11.48v.01l.15 3.57v.02l1.25 31.14.19 4.7a85.339 85.339 0 008.27 2.18c.82.17 1.69.34 2.58.5 6.96 1.26 15.72 1.91 21.28-1.08a19.752 19.752 0 013.52-1.47 24.45 24.45 0 017.46-1.03c.54.01 1.07.03 1.6.06h.01a36.073 36.073 0 017.05 1.21z"
      fill="#3f3d56"
    />
    <path
      d="M396.25 503.91a48.912 48.912 0 01-6.05 6.46c-.68.62-1.36 1.22-2.05 1.81-.14.12-.28.23-.42.35a113.435 113.435 0 01-25.86-5.43c-1.24-.42-2.48-.86-3.7-1.33a37.334 37.334 0 001.81-6.03c5.53-25.92-10.55-65.26-11.95-71.57-.51-2.27 1.37-5.42 4.38-8.77 6.57-7.29 18.52-15.53 22.93-17.73a16.553 16.553 0 0110.65-.98h.01a19.215 19.215 0 013.27.98v.01c.01.15.12 1.29.31 3.24.1 1.07.22 2.37.37 3.89v.01c.27 2.84.61 6.38 1.01 10.42v.01c2.41 24.89 6.77 68.21 8.47 75.58.63 2.73-.77 5.89-3.18 9.08zM434.77 506.87c.59-.19 1.17-.39 1.74-.61a110.027 110.027 0 0010.54-4.43c-5.14-15.02 10.05-62.77 14.4-77.55v-.02c.51-1.73.87-3.01 1.03-3.72 1.61-7.23-22.89-14.05-23.69-15.66-.62-1.22-10.57-2.45-15.26-2.96-1.45-.16-2.4-.25-2.4-.25-1.24.96-2.19 3.72-2.93 7.73-.07.32-.13.66-.18 1-.53 3.1-.94 6.85-1.27 11.06-1.82 22.96-1.2 59.24-2.86 74.18a30.421 30.421 0 00.41 9.25 29.04 29.04 0 00.7 2.93 34.288 34.288 0 001.26 3.7q2.97-.465 5.88-1.11c.35-.07.7-.15 1.04-.23l.16-.03.07-.02h.01q3.9-.9 7.7-2.07z"
      fill="#d0cde1"
    />
    <ellipse cx="428.747" cy="368.339" rx="1.606" ry="3.213" fill="#a0616a" />
    <ellipse cx="381.361" cy="368.339" rx="1.606" ry="3.213" fill="#a0616a" />
    <path
      d="M434.77 506.87c.59-.19 1.17-.39 1.74-.61a110.027 110.027 0 0010.54-4.43.037.037 0 00.01.03.06.06 0 01.01-.04c6-31.68 12.36-65.57 14.38-77.54v-.02c.35-2.08.57-3.49.63-4.12.8-8.03-19.28-8.03-19.28-8.03l-3.2 16.17-4.83 24.43-1.66 8.39-8.77 44.33-.28 1.42-.65 3.28h.01q3.9-.9 7.7-2.07zM387.73 512.53a113.435 113.435 0 01-25.86-5.43c-.63-2.43-1.26-4.89-1.89-7.36-7.81-30.65-15.16-63.08-15.16-69.16 0-5.03 3.51-8.66 7.59-11.18a43.088 43.088 0 0112.49-4.88s2.56 10.65 6.24 26.01c1.97 8.2 4.25 17.74 6.62 27.72v.01c2.76 11.58 5.65 23.74 8.33 35.1q.645 2.76 1.28 5.44v.04a.1.1 0 01.01.04l.24.99c.18.78.36 1.55.53 2.31.04.13.07.26.1.4a4.556 4.556 0 01-.52-.05z"
      fill="#d0cde1"
    />
    <path
      d="M369.314 438.213l18.07 70.67s-8.432-61.836-18.07-70.67zM416.26 511.52l5.88-1.14v.03q-2.91.645-5.88 1.11zM439.6 428.28l-4.83 24.43-1.66 8.39-8.76 44.33h-.01l-.27 1.42h-.01l-.64 3.28h-.01l-.07.02-.16.03-1.04.2c.09-1.12.2-2.32.32-3.59.04-.47.08-.95.13-1.45 1.88-20.43 5.89-55.72 12.18-70.5 1.48-3.48 3.09-5.82 4.83-6.56z"
      opacity=".2"
    />
    <circle cx="405.054" cy="368.74" r="23.291" fill="#a0616a" />
    <path
      fill="#2f2e41"
      d="M426.401 345.046l-17.409-9.119-24.041 3.73-4.974 21.969 12.382-.476 3.459-8.071v7.938l5.713-.22 3.316-12.85 2.073 13.679 20.31-.414-.829-16.166z"
    />
    <path
      d="M226 257a112.991 112.991 0 01-47.1 91.79 111.28 111.28 0 01-12.47 7.8 113.297 113.297 0 01-77.37 10.86 110.597 110.597 0 01-16.42-4.88A113.01 113.01 0 11226 257z"
      fill="#3f3d56"
    />
    <circle cx="105.873" cy="223.642" r="25.989" fill="#ffb8b8" />
    <path
      d="M95.721 243.54s3.249 15.43 3.249 17.055 15.43 8.934 15.43 8.934l13.807-2.437 4.873-14.618s-8.121-12.182-8.121-17.055z"
      fill="#ffb8b8"
    />
    <path
      d="M113 370a113.063 113.063 0 01-23.94-2.55c-.39-7.95-.65-16.71-.65-16.71l-7.92-22.98-16.44-47.67 33.85-25.84s8.38 13.65 20.56 9.59a16.804 16.804 0 0011.92-15.7l40.06 14.08-6.92 56.15-.39 3.14 3.3 35.08A112.443 112.443 0 01113 370z"
      fill="#d0cde1"
    />
    <path
      d="M83.336 202.704s8.251-17.878 24.066-13.752 24.754 10.314 25.442 16.503-.344 15.47-.344 15.47-1.72-12.72-12.72-9.97-28.193.688-28.193.688l-2.75 24.754s-3.095-4.47-6.533-1.719-9.97-26.473 1.032-31.974z"
      fill="#2f2e41"
    />
    <path
      d="M49.07 350.18l5.23-44.1c-.81-17.06 9.75-25.99 9.75-25.99h12.18l8.93 30.05-4.67 17.62-5.88 22.17-1.63 2.44s1.63 2.43.81 3.25c-.81.81-1.62 4.06-1.62 4.06s.17 1.16.47 2.89a111.979 111.979 0 01-23.57-12.39zM198.87 330.45a112.843 112.843 0 01-19.97 18.34 37.727 37.727 0 01-2.78-6.17l-12.6-24.25-8.51-16.36 1.62-42.23 13.81 2.44s8.12 8.12 11.37 17.87l6.5 29.23 10.55 21.12z"
      fill="#d0cde1"
    />
    <path
      d="M778 192a112.917 112.917 0 01-54.27 96.55c-2 1.22-4.04 2.37-6.12 3.47-3.1 1.64-6.29 3.14-9.55 4.47v.01c-1.04.43-2.08.84-3.13 1.23-.49.19-.99.38-1.49.56A113.267 113.267 0 01665 305a113.432 113.432 0 01-25.57-2.9 111.727 111.727 0 01-12.85-3.8h-.01a112.535 112.535 0 01-15.5-6.98q-1.95-1.05-3.84-2.19c-.88-.52-1.74-1.05-2.6-1.59A113.006 113.006 0 11778 192z"
      fill="#3f3d56"
    />
    <path
      d="M651.974 175.504s.67 17.392-3.344 21.405 20.067 18.06 20.067 18.06l14.047-22.073s-5.351-9.365-4.013-17.392z"
      fill="#a0616a"
    />
    <circle cx="663.68" cy="161.122" r="24.081" fill="#a0616a" />
    <path
      d="M704.49 292.15q.225 2.79.44 5.58c-.49.19-.99.38-1.49.56A113.267 113.267 0 01665 305a113.432 113.432 0 01-25.57-2.9 111.727 111.727 0 01-12.85-3.8l-.01.09a.139.139 0 010-.09 27.264 27.264 0 011.14-6.01v-.01c.56-2.06 1.32-4.44 2.19-7.07.94-2.81 1-8.95.8-14.72-.02-.44-.03-.89-.05-1.32-.08-1.87-.18-3.66-.28-5.25-.23-3.59-.47-6.14-.47-6.14l-14.72-39.47 35.55-25.96c2.1 6.81 6.6 6.57 6.6 6.57 19.08 1.31 22.59-11.87 22.7-12.3v-.01l34.15 22.34-14.04 34.11s.57 5.78 1.38 14.6c.12 1.3.24 2.67.38 4.1.78 8.66 1.73 19.45 2.59 30.39z"
      fill="#d0cde1"
    />
    <path
      d="M631.57 264.13l-.7 5.08-.17 1.28-2.97 21.7-.02.09v.01l-1.13 6.01-.01.09a.139.139 0 010-.09 112.535 112.535 0 01-15.5-6.98q-1.95-1.05-3.84-2.19a69.566 69.566 0 012.94-7.6l2.04-15.88.63-4.86 17.53 3.13z"
      fill="#a0616a"
    />
    <path
      d="M624.883 217.31l-9.699 1.004s-6.354 47.158-4.348 47.158 24.75 5.352 25.419 4.014-11.372-52.175-11.372-52.175z"
      fill="#d0cde1"
    />
    <path
      d="M645.1 136.438l-4.34-1.579s9.076-9.076 21.704-8.287l-3.552-3.552s8.682-3.157 16.575 5.13c4.149 4.357 8.95 9.478 11.942 15.246h4.649l-1.94 3.88 6.79 3.881-6.97-.697a19.581 19.581 0 01-.66 10.05l-1.578 4.341s-6.314-12.628-6.314-14.206v3.946s-4.34-3.552-4.34-5.92l-2.368 2.763-1.184-4.341-14.602 4.34 2.368-3.55-9.076 1.183 3.551-4.34s-10.26 5.13-10.655 9.47-5.525 9.866-5.525 9.866l-2.367-3.946s-3.552-17.759 7.892-23.678z"
      fill="#2f2e41"
    />
    <path
      d="M723.73 288.55c-2 1.22-4.04 2.37-6.12 3.47-3.1 1.64-6.29 3.14-9.55 4.47v.01c-1.04.43-2.08.84-3.13 1.23-.49.19-.99.38-1.49.56l1.05-6.14-4.76-30.25-.6-3.79 2.39-.45 22.36-4.23v27.43s.06 2.97-.15 7.69z"
      fill="#a0616a"
    />
    <path d="M701.14 207.277l13.043 1.672s15.72 47.828 11.706 49.834-30.101 3.345-30.101 3.345z" fill="#d0cde1" />
    <circle cx="186" cy="171" r="33" fill={color} />
    <circle cx="476" cy="316" r="33" fill={color} />
    <circle cx="747" cy="112" r="33" fill={color} />
    <circle cx="863.956" cy="68.856" r="28.098" fill="#ffb8b8" />
    <path
      d="M885.36 32.93a25.204 25.204 0 00-19.887-10.149h-.944c-18.191 0-32.939 16.461-32.939 36.768h6.096l.984-7.493 1.444 7.493h36.152l3.03-6.34-.758 6.34h7.112q4.978 24.723-14.306 49.446h12.116l6.058-12.678-1.515 12.678h23.095l4.544-29.16c0-21.84-12.69-40.358-30.282-46.906z"
      fill="#2f2e41"
    />
    <circle cx="892" cy="18" r="18" fill="#2f2e41" />
  </svg>
)

export default SharingImage

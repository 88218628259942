import React, { FC } from "react"
import { Box, Typography, makeStyles } from "@material-ui/core"

import { useUser } from "providers/UserProvider"

import FollowActions from "components/FollowActions"

const useStyles = makeStyles(({ spacing, palette, shape, breakpoints }) => ({
  root: {
    flexGrow: 1,
    margin: spacing(),

    [breakpoints.up("sm")]: {
      margin: 0,
    },
    [breakpoints.up("md")]: {
      flexGrow: 0.2,
    },
  },
  followNumbers: {
    borderRadius: shape.borderRadius,
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    justifyContent: "space-evenly",

    [breakpoints.up("sm")]: {
      flexDirection: "column",
    },
  },
  numberContainer: {
    padding: spacing(0, 1),
    flexBasis: "50%",
    flexGrow: 0,
    flexShrink: 0,

    [breakpoints.up("sm")]: {
      "&:not(:first-child)": {
        marginTop: spacing(),
      },
    },
  },
}))

const UserFollowings: FC = () => {
  const { user, error, loading } = useUser()
  const classes = useStyles()

  if (loading) return null

  if (error) return null

  if (!user) return null

  return (
    <Box className={classes.root}>
      <Box className={classes.followNumbers} p={2} display="flex">
        <Box className={classes.numberContainer}>
          <Typography variant="h6">Following:</Typography>
          <Typography>{user.following}</Typography>
        </Box>
        <Box className={classes.numberContainer}>
          <Typography variant="h6">Followers:</Typography>
          <Typography>{user.followers}</Typography>
        </Box>
      </Box>
      <FollowActions user={user} />
    </Box>
  )
}

export default UserFollowings

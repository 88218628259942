import React, { FC, Children } from "react"
import { Box, Container, Typography, makeStyles } from "@material-ui/core"
import cx from "classnames"

import useBreakpoint, { Breakpoint } from "hooks/useBreakpoint"

import Heading, { HeadingProps } from "components/Heading"

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  list: {
    display: "flex",
    overflowX: "auto",
    paddingBottom: spacing(),
    paddingLeft: spacing(2),

    [breakpoints.up("sm")]: {
      paddingLeft: 0,
    },
  },
  item: {
    flexBasis: "25%",
    flexGrow: 0,
    flexShrink: 0,

    "&:not(:first-child)": {
      marginLeft: spacing(2),
    },

    [breakpoints.up("sm")]: {
      // flexBasis: "30%",
    },
    [breakpoints.up("md")]: {
      flexBasis: "20%",
    },
    [breakpoints.up("lg")]: {
      // flexBasis: "15%",
    },

    "&.end": {
      flexBasis: 0,
      margin: 0,
      paddingLeft: spacing(2),

      [breakpoints.up("sm")]: {
        display: "none",
      },
    },
  },
}))

const ScrollList: FC<{
  className?: string
  headingProps?: HeadingProps
}> = ({ className, children, headingProps }) => {
  const classes = useStyles()
  const breakpoint = useBreakpoint()
  return (
    <Box className={className} py={2}>
      {headingProps && <Heading {...headingProps} />}
      <Container disableGutters={breakpoint === Breakpoint.xs}>
        {Children.count(children) ? (
          <Box className={classes.list}>
            {Children.map(children, (child, index) => (
              <Box key={index} className={classes.item}>
                {child}
              </Box>
            ))}
            <Box className={cx(classes.item, "end")} />
          </Box>
        ) : (
          <Box px={2}>
            <Typography align="center">List is Empty...</Typography>
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default ScrollList

import React, { FC, useState, useEffect } from "react"
import { Box, Container, Grid } from "@material-ui/core"
import { SearchRounded } from "@material-ui/icons"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import queryString from "query-string"
import dayjs from "dayjs"
import firebase from "firebase"

import useDebounceState from "hooks/useDebounceState"
import useRecentlyVisitedMovies from "hooks/useRecentlyVisitedMovies"

import SEO from "components/SEO"
import MovieList from "components/MovieList"
import MovieGrid from "components/MovieGrid"
import RaisedTextField from "components/RaisedTextField"

const MoviesPage: FC = () => {
  const [query, debouncedQuery, setQuery] = useDebounceState("")

  const [movieSearchResults, setMovieSearchResults] = useState<movieotter.SimpleMovieData[]>([])
  const [popularMoviesResults, setPopularMoviesResults] = useState<movieotter.SimpleMovieData[]>([])

  useEffect(() => {
    // Default to url param
    const queryFromUrl = queryString.parse(window.location.search)
    setQuery((queryFromUrl.query as string) || "")
  }, [])

  const tmdb = new movieotter.TMDB(process.env.REACT_APP_TMDB_V3_API_KEY ?? "")

  useEffect(() => {
    let running = true
    tmdb.getPopularMovies().then(movies => {
      if (running) {
        setPopularMoviesResults(
          movies.map(m => ({
            movieId: m.id.toString(),
            title: m.title,
            posterURL: m.posterUrl,
            releaseDate: dayjs(m.release_date).format(movieotter.DATE_FORMAT),
            runtime: m.runtime,
            lastImportDateTime: null,
          }))
        )
      }
    })

    return () => {
      running = false
    }
  }, [])

  useEffect(() => {
    let running = true
    let newUrl
    if (debouncedQuery) {
      firebase.analytics().logEvent("search-movies", {
        query: debouncedQuery,
      })
      newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        queryString.stringify({ query: debouncedQuery })
      // search TMDB for movies
      tmdb.searchMovies(debouncedQuery, 1).then(movies => {
        if (running) {
          // Store results we get back
          setMovieSearchResults(
            movies.map(m => ({
              movieId: m.id.toString(),
              title: m.title,
              posterURL: m.posterUrl,
              releaseDate: dayjs(m.release_date).format(movieotter.DATE_FORMAT),
              runtime: m.runtime,
              lastImportDateTime: null,
            }))
          )
        }
      })
    } else {
      setMovieSearchResults([])
      newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname
    }

    window?.history?.pushState?.({ path: newUrl }, "", newUrl)

    return () => {
      running = false
    }
  }, [debouncedQuery])

  const { movies: recentlyVisitedMovies, clear: clearRecentlyVisitedMovies } = useRecentlyVisitedMovies()

  return (
    <>
      <SEO title="Movies" />
      <Box py={2}>
        <Container>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={10} md={6}>
              <RaisedTextField
                placeholder="Search Movies..."
                trailing={<SearchRounded color="disabled" />}
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {movieSearchResults.length ? (
        <MovieGrid
          movies={movieSearchResults.map(movie => ({
            movieData: movie,
            source: "TMDB",
            action: "result",
          }))}
          headingProps={{
            heading: "Results",
          }}
        />
      ) : (
        <>
          <MovieList
            movies={popularMoviesResults.slice(0, 6)}
            source="TMDB"
            headingProps={{
              heading: "Popular Movies",
            }}
          />
          <MovieList
            movies={recentlyVisitedMovies}
            headingProps={{
              heading: "Recently Visited",
              onClickLabel: "Clear",
              onClick: clearRecentlyVisitedMovies,
            }}
          />
        </>
      )}
    </>
  )
}

export default MoviesPage

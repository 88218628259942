import React, { FC, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  makeStyles,
  Typography,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Button,
  ListSubheader,
  Badge,
} from "@material-ui/core"
import { MenuRounded } from "@material-ui/icons"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import firebase from "firebase"

import { useAuth } from "providers/AuthProvider"

import useBreakpoint, { Breakpoint } from "hooks/useBreakpoint"

import LoginButton from "components/LoginButton"
import LogoutButton from "components/LogoutButton"
import UserAuthButton from "components/UserAuthButton"
import UserAvatar from "components/UserAvatar"

const useStyle = makeStyles(({ spacing, palette }) => ({
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
  },
  menuButton: {
    marginLeft: spacing(),
  },
  drawer: {
    backgroundColor: palette.background.default,
  },
  list: {
    width: spacing(30),
  },
  listHeader: {
    lineHeight: 1,
    paddingTop: spacing(),
    paddingBottom: spacing(),
  },
  supportListItem: {
    justifyContent: "center",
  },
}))

const Header = () => {
  const [open, setOpen] = useState(false)

  const { currentUser, unreadNotifications } = useAuth()

  const classes = useStyle()
  const breakpoint = useBreakpoint()

  const collapse = breakpoint < Breakpoint.sm

  const history = useHistory()

  // TODO: Refactor so "small" size doesn't "pop" on page loads?
  return (
    <AppBar>
      <Container disableGutters={true}>
        <Toolbar>
          <Typography className={classes.title} variant="h6">
            <Link to="/" className={classes.link}>
              MovieOtter
            </Link>
          </Typography>
          {!collapse && (
            <>
              <Box pr={2}>
                <Button color="inherit" onClick={() => history.push("/movies")}>
                  Movies
                </Button>
              </Box>
              <Box pr={2}>
                <Button color="inherit" onClick={() => history.push("/lists")}>
                  Lists
                </Button>
              </Box>
              <Box pr={2}>
                <Button color="inherit" onClick={() => history.push("/users")}>
                  Users
                </Button>
              </Box>
              <LoginButton variant="outlined" color="inherit" />
              {currentUser && (
                <Box pl={2}>
                  <UserAuthButton />
                </Box>
              )}
            </>
          )}
          {collapse && (
            <>
              <IconButton className={classes.menuButton} color="inherit" onClick={() => setOpen(!open)}>
                <Badge badgeContent={unreadNotifications} color="error">
                  <MenuRounded color="inherit" />
                </Badge>
              </IconButton>
              <Drawer
                PaperProps={{
                  className: classes.drawer,
                }}
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
              >
                <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                  <List className={classes.list}>
                    {!currentUser && (
                      <>
                        <Box pb={1}>
                          <ListItem>
                            <Box flexGrow={1}>
                              <Typography variant="h6" align="center" color="primary">
                                Get Started!
                              </Typography>
                            </Box>
                          </ListItem>
                          <ListItem>
                            <LoginButton fullWidth />
                          </ListItem>
                        </Box>
                        <Divider />
                      </>
                    )}
                    {currentUser && (
                      <>
                        <Box pb={1}>
                          <ListItem>
                            <UserSummary user={currentUser} onNavigate={() => setOpen(false)} />
                          </ListItem>
                        </Box>
                        <Divider />
                      </>
                    )}
                    <ListSubheader className={classes.listHeader}>Navigation</ListSubheader>
                    <ListItem
                      button
                      onClick={() => {
                        history.push("/movies")
                        setOpen(false)
                      }}
                    >
                      <ListItemText>Movies</ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        history.push("/lists")
                        setOpen(false)
                      }}
                    >
                      <ListItemText>Lists</ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        history.push("/users")
                        setOpen(false)
                      }}
                    >
                      <ListItemText>Users</ListItemText>
                    </ListItem>
                    {currentUser && (
                      <>
                        <Divider />
                        <ListItem
                          button
                          onClick={() => {
                            history.push(`/notifications`)
                            setOpen(false)
                          }}
                        >
                          <ListItemText>
                            Notifications
                            {unreadNotifications ? ` (${unreadNotifications})` : ""}
                          </ListItemText>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            history.push(`/favorites`)
                            setOpen(false)
                          }}
                        >
                          <ListItemText>View Favorites</ListItemText>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            history.push(`/watch-later`)
                            setOpen(false)
                          }}
                        >
                          <ListItemText>View Watch Later</ListItemText>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            history.push(`/history`)
                            setOpen(false)
                          }}
                        >
                          <ListItemText>View Watch History</ListItemText>
                        </ListItem>
                      </>
                    )}
                  </List>
                  <Box>
                    {currentUser && (
                      <List className={classes.list}>
                        <ListItem>
                          <LogoutButton fullWidth />
                        </ListItem>
                      </List>
                    )}
                    <List className={classes.list}>
                      <Divider />
                      <ListItem className={classes.supportListItem}>
                        <a
                          onClick={() => {
                            firebase.analytics().logEvent("navigation-support_form")
                          }}
                          href="https://forms.gle/KdTT2FLN2augzZWVA"
                          target="_blank"
                        >
                          Need help with something?
                        </a>
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              </Drawer>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header

const UserSummary: FC<{ user: movieotter.User; onNavigate?: () => void }> = ({ user, onNavigate }) => {
  const { currentUser } = useAuth()

  const to = currentUser && user?.userId === currentUser.userId ? "/self" : `/user/${user.username}`
  return (
    <Box flexGrow={1}>
      <Paper variant="outlined">
        <Box p={2} display="flex" flexDirection="column">
          <Box pb={1} display="flex" justifyContent="center">
            <UserAvatar user={user} size="large" />
          </Box>
          <Typography>
            <Link to={to} onClick={onNavigate}>
              {user.displayName}
            </Link>
          </Typography>
          <Typography variant="body2">@{user.username}</Typography>
        </Box>
      </Paper>
    </Box>
  )
}

import React, { FC } from "react"

interface TrackImageProps {
  color?: string
  height?: number
  width?: number
}

const TrackImage: FC<TrackImageProps> = ({ color = "#6c5c43" }) => (
  <svg viewBox="0 0 925.497 645.184" style={{ maxHeight: 200 }}>
    <circle cx="233.088" cy="328.457" r="18.409" fill="#f2f2f2" />
    <circle cx="170.088" cy="540.457" r="11.409" fill="#f2f2f2" />
    <path fill="#f2f2f2" d="M379.997 21.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M417.997 3.5h2V22h-2zM478.997 3.5h2V22h-2zM605.997 505.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M643.997 487.5h2V506h-2zM704.997 487.5h2V506h-2zM612.997 200.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M650.997 182.5h2V201h-2zM711.997 182.5h2V201h-2zM612.997 128.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M650.997 110.5h2V129h-2zM711.997 110.5h2V129h-2zM305.997 138.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M343.997 120.5h2V139h-2zM404.997 120.5h2V139h-2zM203.997 91.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M241.997 73.5h2V92h-2zM302.997 73.5h2V92h-2zM140.997 276.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M178.997 258.5h2V277h-2zM239.997 258.5h2V277h-2zM70.997 480.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M108.997 462.5h2V481h-2zM169.997 462.5h2V481h-2zM732.997 396.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M770.997 378.5h2V397h-2zM831.997 378.5h2V397h-2zM691.997 268.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M791.997 270h2v18.5h-2zM730.997 270h2v18.5h-2zM13.997 118.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M113.997 120h2v18.5h-2zM52.997 120h2v18.5h-2zM22.997 380.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M122.997 382h2v18.5h-2zM61.997 382h2v18.5h-2zM463.997 53.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M563.997 55h2v18.5h-2zM502.997 55h2v18.5h-2zM70.997 192.5h140v2h-140z" />
    <path fill="#f2f2f2" d="M108.997 174.5h2V193h-2zM169.997 174.5h2V193h-2z" />
    <path
      d="M233.497 173v471h436V173zm218 34a15 15 0 1115-15 15 15 0 01-15 15zM37.255 603.797c6.914 25.559 30.596 41.387 30.596 41.387s12.472-25.609 5.558-51.167-30.596-41.387-30.596-41.387-12.472 25.61-5.558 51.167z"
      fill="#3f3d56"
    />
    <path
      d="M47.415 598.305c18.972 18.468 21.289 46.859 21.289 46.859s-28.443-1.553-47.415-20.022S0 578.283 0 578.283s28.442 1.553 47.415 20.022z"
      fill={color}
    />
    <path fill="#3f3d56" d="M16.497 643h909v2h-909z" />
    <path
      d="M191.997 581.338a22.031 22.031 0 00-14.031-20.5 22 22 0 10-15.938 0 21.991 21.991 0 000 41 22 22 0 1015.938 0 22.031 22.031 0 0014.031-20.5zm-42-41a20 20 0 1124.513 19.467 22.023 22.023 0 00-9.026 0 20.02 20.02 0 01-15.487-19.467zm40 82a20 20 0 11-24.513-19.467 22.024 22.024 0 009.026 0 20.02 20.02 0 0115.487 19.467zm-15.487-21.533a22.023 22.023 0 00-9.026 0 19.979 19.979 0 010-38.934 22.024 22.024 0 009.026 0 19.979 19.979 0 010 38.934z"
      fill="#3f3d56"
    />
    <path
      d="M510.997 271.5h-229a7 7 0 110-14h229a7 7 0 010 14zM510.997 343.5h-229a7 7 0 110-14h229a7 7 0 010 14zM510.997 415.5h-229a7 7 0 110-14h229a7 7 0 010 14zM510.997 487.5h-229a7 7 0 110-14h229a7 7 0 010 14zM510.997 559.5h-229a7 7 0 110-14h229a7 7 0 010 14z"
      fill="#f2f2f2"
    />
    <path
      d="M470.997 271.5h-190a7 7 0 110-14h190a7 7 0 010 14zM411.997 343.5h-131a7 7 0 110-14h131a7 7 0 010 14zM339.997 415.5h-59a7 7 0 110-14h59a7 7 0 010 14zM488.997 487.5h-208a7 7 0 110-14h208a7 7 0 010 14zM442.997 559.5h-162a7 7 0 110-14h162a7 7 0 010 14zM603.997 257.5h2v14h-2zM620.997 272.5a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6zM603.997 329.5h2v14h-2zM620.997 344.5a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6zM603.997 401.5h2v14h-2zM620.997 416.5a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6zM603.997 473.5h2v14h-2zM620.997 488.5a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6zM603.997 545.5h2v14h-2zM620.997 560.5a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-14a6 6 0 106 6 6.007 6.007 0 00-6-6z"
      fill={color}
    />
    <circle cx="620.997" cy="264.5" r="4" fill={color} />
    <circle cx="620.997" cy="480.5" r="4" fill={color} />
    <circle cx="620.997" cy="552.5" r="4" fill={color} />
    <circle cx="451.497" cy="192" r="7" fill="#3f3d56" />
    <circle cx="451.497" cy="7" r="7" fill="#3f3d56" />
    <path fill="#3f3d56" d="M450.497 7h2v185h-2z" />
    <path
      d="M856.614 384.479v24.775s5.964 39.915 5.964 74.783-9.635 116.075-9.635 116.075-19.27 6.882-20.646-3.67-5.046-66.525-5.046-74.784-16.058-71.113-16.058-71.113L801.1 520.282s3.211 82.583 0 84.877-18.811 6.423-20.646 2.294-11.47-92.676-11.47-92.676-7.8-115.617-3.212-122.498 8.717-12.388 8.717-12.388 65.608-16.058 82.125 4.588z"
      fill="#2f2e41"
    />
    <path
      d="M850.65 597.36l1.15 7.478a73.664 73.664 0 01.752 7.7c.179 3.756.804 9.544 2.945 10.462 3.212 1.376 24.056 18.956 9.375 19.873s-22.026 2.503-26.375-.873c-5.141-3.992.682-2.184-1.612-2.184s-5.046 0-5.964-3.67 1.377-10.094 1.377-10.094v-28.693zM782.145 597l-1.15 7.479a73.664 73.664 0 00-.752 7.699c-.18 3.757-.805 9.545-2.946 10.463-3.211 1.376-24.056 18.955-9.374 19.873s22.026 2.503 26.374-.873c5.142-3.992-.682-2.185 1.612-2.185s5.047 0 5.964-3.67-1.376-10.094-1.376-10.094V597z"
      fill="#2f2e41"
    />
    <path
      d="M795.135 185.362s7.8 19.27 13.305 22.481 2.753 17.893 2.753 17.893l-13.764 19.27h-11.01l-13.306-22.94s-.918-15.14-3.67-18.81 25.692-17.894 25.692-17.894z"
      fill="#ffb9b9"
    />
    <path
      d="M795.135 185.362s7.8 19.27 13.305 22.481 2.753 17.893 2.753 17.893l-13.764 19.27h-11.01l-13.306-22.94s-.918-15.14-3.67-18.81 25.692-17.894 25.692-17.894z"
      opacity=".1"
    />
    <path
      d="M806.605 224.819l20.646 4.588 15.14 152.32s-8.258 1.376-16.517 12.387-18.351 9.176-23.398 6.423-25.234-10.552-25.693-13.764.918-59.643.918-59.643l-12.846-88.089 6.423-9.634h11.47l9.176 7.8z"
      fill={color}
    />
    <circle cx="775.407" cy="185.362" r="23.857" fill="#ffb9b9" />
    <path
      d="M809.817 217.02s44.961 4.587 49.09 14.222-2.752 52.302-2.752 52.302 16.516 37.163 16.058 45.88 15.599 68.36-1.836 61.478-11.928-24.775-20.645-11.929-12.388 11.011-12.388 11.011-8.717 14.223-11.47 12.847-4.588-11.929 1.377-16.976 5.047-13.763-6.423-30.28-23.399-103.229-16.517-116.534 5.506-22.022 5.506-22.022z"
      fill="#575a89"
    />
    <path fill="#ffb9b9" d="M856.614 393.196l-7.8 7.341-9.635-6.423 4.13-9.635 13.305 8.717z" />
    <path
      d="M776.783 224.819s-34.868 12.846-34.868 26.61 21.105 73.866 21.105 73.866 0 15.599-.918 19.269-15.353 35.683-2.753 38.539c7.865 1.782 14.327 6.466 14.911 13.075.693 7.849-5.315 17.756-4.817 19.499.917 3.212 6.423 7.8 5.964 5.506s3.67-35.328 10.093-50.009-2.752-113.322-4.587-126.168a141.988 141.988 0 00-4.13-20.187z"
      fill="#575a89"
    />
    <path fill="#ffb9b9" d="M758.432 392.278l8.258 19.729 6.194-12.617-7.112-15.829-7.34 8.717z" />
    <path
      d="M802.284 200.143s7.533 3.112 12.12 3.112 23.399 9.635 16.976 15.6-16.058 7.34-20.646 16.057-6.882 10.094-6.882 10.094l-8.168-10.973s11.38-12.885 10.921-17.014-4.32-16.876-4.32-16.876zM771.874 211.174s-5.643 2.175-7.02 5.845-10.093 10.552-6.422 12.388 19.728 9.176 22.94 10.552 9.196-3.905 9.196-3.905-8.05-4.583-8.05-7.794-10.644-17.086-10.644-17.086z"
      fill={color}
    />
    <path
      d="M752.926 245.006l-11.01 2.752s-15.141 67.902-10.553 84.877 26.151 67.902 26.151 67.902l13.764-16.058s-6.004-36.515-9.425-38.903-1.127-7.435-1.127-8.812 1.376-.458-1.835-3.67 11.928-21.104 7.799-26.151-13.764-61.937-13.764-61.937z"
      fill="#575a89"
    />
    <path
      d="M861.43 257.622l-6.652-25.004s17.893 12.846 21.564 31.657 17.893 56.432 8.717 72.948-25.234 64.231-30.74 63.314-18.81-12.388-19.269-14.223 23.858-44.044 20.187-47.256-2.753-11.47 0-13.305 5.964-10.552 3.67-10.093-9.175-4.13-7.799-9.176-6.882-38.997-6.882-38.997z"
      opacity=".1"
    />
    <path
      d="M852.484 229.407l6.424 1.835s17.893 12.846 21.563 31.657 17.893 56.431 8.717 72.948-25.234 64.231-30.74 63.313-18.81-12.387-19.269-14.222 23.858-44.044 20.187-47.256-2.752-11.47 0-13.305 5.965-10.552 3.67-10.093-9.175-4.13-7.799-9.176-6.882-38.998-6.882-38.998z"
      fill="#575a89"
    />
    <path
      d="M793.92 163.827l4.475-1.627s-9.356-9.356-22.373-8.542l3.662-3.661s-8.95-3.254-17.085 5.288c-4.277 4.49-9.225 9.769-12.31 15.715h-4.792l2 4-7 4 7.185-.718a20.184 20.184 0 00.68 10.36l1.626 4.474s6.509-13.017 6.509-14.644v4.067s4.475-3.66 4.475-6.101l2.44 2.847 1.22-4.474 15.052 4.474-2.441-3.66 9.356 1.22-3.661-4.475s10.576 5.288 10.983 9.763 5.695 10.17 5.695 10.17l2.44-4.069s3.662-18.305-8.135-24.407z"
      fill="#2f2e41"
    />
    <ellipse cx="792.997" cy="182.5" rx="3.5" ry="5.5" fill="#ffb9b9" />
  </svg>
)

export default TrackImage

import React, { FC } from "react"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import { useAuth } from "providers/AuthProvider"

import useQuery from "hooks/useQuery"
import useSnapshot from "hooks/useSnapshot"

import ErrorDisplay from "components/ErrorDisplay"
import LoadingDisplay from "components/LoadingDisplay"
import MovieGrid from "components/MovieGrid"

const FavoritesPage: FC = () => {
  const { currentUser } = useAuth()

  // NOTE: when we make favorite pages publicable, add <SEO /> component
  return <FavoritesPageContent user={currentUser} />
}

interface FavoritesPageContentProps {
  user?: movieotter.User
}

const FavoritesPageContent: FC<FavoritesPageContentProps> = ({ user }) => {
  const { loading: favoritesLoading, data: favoritesData, error: favoritesError } = useSnapshot<
    movieotter.FavoritesData
  >(movieotter.Favorites.documentPathForUser(user?.userId ?? "_"))

  const { loading: moviesLoading, error: moviesError, data: movies } = useQuery<movieotter.FavoritedMovieData>(
    `/favorites/${user?.userId ?? "_"}/movies`
  )

  // if user is logged in - Error
  if (!user) {
    return <ErrorDisplay message="Please login..." />
  }

  if (favoritesError || moviesError) {
    console.log("Favorites Error: ", favoritesError?.type)
    console.log("Movies Error: ", moviesError?.type)
    return <ErrorDisplay message="Something went wrong grabbing your favorites..." />
  }

  // if users favorite movies are loading - loading
  if (favoritesLoading || moviesLoading) {
    return <LoadingDisplay />
  }

  // TODO: fix - this extra check shouldn't be necessary
  if (!favoritesData) {
    return <ErrorDisplay message="Something went wrong grabbing your favorites data..." />
  }

  return (
    <MovieGrid
      movies={movies.map(movie => ({
        movieData: movie,
      }))}
      headingProps={{
        heading: `Favorite Movies (${favoritesData.movies})`,
      }}
    />
  )
}

export default FavoritesPage

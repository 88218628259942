import React, { FC, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import {
  Button,
  IconButton,
  Popover,
  MenuList,
  MenuItem,
  Divider,
  ListItemText,
  ListItemIcon,
  Badge,
  makeStyles,
} from "@material-ui/core"
import { ExitToAppRounded } from "@material-ui/icons"

import { useAuth } from "providers/AuthProvider"

import Person from "components/Person"

const useStyles = makeStyles(({ spacing }) => ({
  avatar: {
    height: spacing(4),
    width: spacing(4),
  },
}))

const UserAuthButton: FC = () => {
  const classes = useStyles()
  const { currentUser, openLoginModal, logout, unreadNotifications } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)
  const avatarRef = useRef(null)

  const history = useHistory()

  if (!currentUser) {
    return (
      <Button variant="outlined" color="inherit" onClick={openLoginModal}>
        Sign Up / Log In
      </Button>
    )
  }

  const navigateTo = (to: string) => {
    setAnchorEl(null)
    history.push(to)
  }

  const onLogout = () => {
    setAnchorEl(null)
    logout()
  }

  return (
    <>
      <IconButton onClick={() => setAnchorEl(avatarRef.current)}>
        <Badge badgeContent={unreadNotifications} color="error">
          <Person
            className={classes.avatar}
            innerRef={avatarRef}
            src={currentUser?.photoURL}
            alt={currentUser?.displayName}
          />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList>
          <MenuItem onClick={() => navigateTo("/notifications")}>
            <ListItemText>
              Notifications
              {unreadNotifications ? ` (${unreadNotifications})` : ""}
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigateTo("/self")}>
            <ListItemText>View Profile</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigateTo(`/favorites`)}>
            <ListItemText>View Favorites</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigateTo(`/watch-later`)}>
            <ListItemText>View Watch Later</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigateTo(`/history`)}>
            <ListItemText>View Watch History</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={onLogout}>
            <ListItemIcon>
              <ExitToAppRounded color="primary" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  )
}

export default UserAuthButton

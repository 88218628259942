import React, { FC, createContext, useContext } from "react"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import MOError from "utils/error"

interface UserProviderProps {
  loading: boolean

  user?: movieotter.User
  error?: MOError
}

const UserContext = createContext<UserProviderProps>({
  loading: true,
  user: undefined,
  error: undefined,
})

export const UserProvider: FC<UserProviderProps> = ({ children, ...props }) => {
  return <UserContext.Provider value={props}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)

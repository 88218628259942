import * as movieotter from "@isaiahbydayah/movieotter-core"
import firebase from "firebase"

import MOError, { MOErrorType } from "utils/error"

import { useAuth } from "providers/AuthProvider"

import useDocExistence from "hooks/useDocExistence"

const useFavoriteMovie = (movie: movieotter.SimpleMovieData) => {
  const { currentUser, openLoginModal } = useAuth()

  const path = movieotter.FavoritedMovie.documentPathFor(currentUser?.userId ?? "", movie?.movieId)

  const pathOk = Boolean(currentUser?.userId && movie?.movieId)

  const onCreate = () => {
    console.log("On create Fave!")
    if (currentUser) {
      if (movie) {
        const m = movie
        firebase
          .firestore()
          .doc(path)
          .set(
            movieotter.FavoritedMovie.new(currentUser.userId, movie, firebase.firestore.FieldValue.serverTimestamp())
          )
          .then(() => {
            firebase.analytics().logEvent("user-favorite_movie", {
              movieId: m.movieId,
              movieTitle: m.title,
            })
          })
      }
    } else {
      openLoginModal()
    }
  }

  const onDelete = () => {
    if (currentUser && movie) {
      const m = movie
      firebase
        .firestore()
        .doc(path)
        .delete()
        .then(() => {
          firebase.analytics().logEvent("user-unfavorite_movie", {
            movieId: m.movieId,
            movieTitle: m.title,
          })
        })
    }
  }

  const onError = (error: MOError) => {
    if (error.type == MOErrorType.REQUIRES_LOGIN || error.type == MOErrorType.MISSING_PARAMETERS) {
      openLoginModal()
    }
  }

  return useDocExistence({
    path: pathOk ? path : undefined,
    onCreate,
    onDelete,
    onError,
  })
}

export default useFavoriteMovie

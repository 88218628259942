import { useState, useEffect } from "react"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import MOError, { MOErrorType } from "utils/error"

interface UseUserOptions {
  userId?: string
  username?: string
}

export const useMovieOtterUser = ({ userId, username }: UseUserOptions) => {
  // TODO: Refactor into reducer pattern in order to re-renders / improve consumption
  const [uid, setUID] = useState(userId)
  const [user, setUser] = useState<movieotter.User>()
  const [error, setError] = useState<MOError>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    let unsubscribe: (() => void) | undefined

    setUID(userId)
    setUser(undefined)
    setLoading(true)
    setError(undefined)

    if (username && !userId) {
      unsubscribe = firebase
        .firestore()
        .doc(movieotter.User.documentPathForUsername(username))
        .onSnapshot(snapshot => {
          if (!snapshot.exists) {
            setError(
              new MOError(MOErrorType.USERNAME_DOES_NOT_EXISTS, `User with username ${username} does not exists.`)
            )
            setLoading(false)
          } else {
            setUID(snapshot.data()!.userId)
          }
        })
    }

    if (!userId && !username) {
      setError(new MOError(MOErrorType.FAILED_OPERATION, `Please provide either a username or userId.`))
      setLoading(false)
    }

    return () => unsubscribe?.()
  }, [userId, username])

  useEffect(() => {
    let unsubscribe: (() => void) | undefined
    if (uid) {
      unsubscribe = firebase
        .firestore()
        .doc(movieotter.User.documentPathForUser(uid))
        .onSnapshot(snapshot => {
          if (snapshot.exists) {
            const userData = snapshot.data({
              serverTimestamps: "estimate",
            }) as movieotter.UserData<movieotter.FirebaseTimestamp>
            const u = movieotter.User.fromDocument(userData)
            if (u.isUpToDate) {
              setUser(u)
              setLoading(false)
            } else {
              try {
                firebase.functions().httpsCallable("updateUser")({
                  userId: u.userId,
                })
              } catch (e) {
                console.warn("UPDATE USER ERROR: ", e)
              }
            }
          } else {
            setError(new MOError(MOErrorType.USER_DOES_NOT_EXISTS, `User with userId ${uid} does not exists.`))
            setLoading(false)
            setUser(undefined)
          }
        })
    }

    return () => unsubscribe?.()
  }, [uid])

  return { user, error, loading }
}

export default useMovieOtterUser

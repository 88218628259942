export default class MOColors {
  static SPACE_CADET = "#233659"
  static DARK_CORNFLOWER_BLUE = "#2e4775"
  static PLATINUM = "#E7E2D9"
  static BEAVER = "#9B8461"
  static WHITE = "#FFFFFF"
  static ONYX = "#303741"
  static DEEP_PUCE = "#B26061"
  static PERSIAN_GREEN = "#2A9D8F"
}

import React, { FC } from "react"
import { Box, Container, Typography } from "@material-ui/core"
import { Discord } from "mdi-material-ui"
import firebase from "firebase"

import Heading from "components/Heading"

const DiscordServer: FC = () => {
  return (
    <Box py={4} color="primary.contrastText" bgcolor="primary.main" display="flex" justifyContent="center">
      <Container>
        <Heading variant="h5" align="center">
          Get chattin' on{" "}
          <a
            onClick={() => {
              firebase.analytics().logEvent("navigation-discord")
            }}
            href="https://discord.gg/DMvuyZf"
            target="_blank"
          >
            Discord
          </a>{" "}
          <Discord style={{ position: "relative", top: 4 }} />
        </Heading>
        <Typography variant="body2" align="center">
          Missed the stream? No worries! We're still hanging out on our{" "}
          <a
            onClick={() => {
              firebase.analytics().logEvent("navigation-discord")
            }}
            style={{ fontWeight: "bold" }}
            href="https://discord.gg/DMvuyZf"
            target="_blank"
          >
            Discord Server
          </a>
          ! Stop by anytime with a question, a suggestion for our next Watch Party, or just to hangout.
        </Typography>
      </Container>
    </Box>
  )
}

export default DiscordServer

import React, { FC } from "react"
import { Button } from "@material-ui/core"
import { ButtonProps } from "@material-ui/core/Button"

import { useAuth } from "providers/AuthProvider"

const LogoutButton: FC<ButtonProps> = props => {
  const { currentUser, logout } = useAuth()

  if (!currentUser) return null

  return (
    <Button color="primary" variant="contained" {...props} onClick={() => logout()}>
      Log Out
    </Button>
  )
}

export default LogoutButton

import React, { FC } from "react"
import { ThemeProvider, createMuiTheme, Theme, ThemeOptions } from "@material-ui/core"

import MOColors from "utils/colors"

interface ThemeOverrideProps {
  theme: ThemeOptions
}

const ThemeOverride: FC<ThemeOverrideProps> = ({ theme, children }) => {
  return (
    <ThemeProvider
      theme={(parentTheme: Theme) =>
        createMuiTheme({
          ...parentTheme,
          palette: {
            ...parentTheme.palette,
            primary: {
              ...theme.palette?.primary,
            },
          },
        })
      }
    >
      {children}
    </ThemeProvider>
  )
}

export const DestructiveThemeOverride: FC = props => (
  <ThemeOverride {...props} theme={{ palette: { primary: { main: MOColors.DEEP_PUCE } } }} />
)

export default ThemeOverride

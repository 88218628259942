import { useEffect, useReducer, Reducer } from "react"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import MOError, { MOErrorType } from "utils/error"
import { generateId } from "utils/firestore"

import { useAuth } from "providers/AuthProvider"

export interface IUseMovieList {
  list?: movieotter.MovieList
  error?: MOError
  loading: boolean
}

type UseMovieListAction =
  | { type: "ON_ERROR"; error: MOError }
  | {
      type: "ON_LIST_SNAPSHOT"
      data: movieotter.IMovieList<movieotter.FirebaseTimestamp>
    }
  | {
      type: "ON_NEW_LIST"
    }

export const useMovieList = (listId?: string) => {
  const { currentUser } = useAuth()

  const [state, dispatch] = useReducer<Reducer<IUseMovieList, UseMovieListAction>>(
    (currentState, action) => {
      switch (action.type) {
        case "ON_ERROR":
          return {
            ...currentState,
            loading: false,
            error: action.error,
            list: undefined,
          }
        case "ON_LIST_SNAPSHOT":
          return {
            ...currentState,
            loading: false,
            error: undefined,
            list: movieotter.MovieList.fromDocument(action.data),
          }
        case "ON_NEW_LIST":
          if (currentUser) {
            return {
              ...currentState,
              loading: false,
              error: undefined,
              list: movieotter.MovieList.new(
                generateId(),
                currentUser,
                "",
                "",
                firebase.firestore.FieldValue.serverTimestamp()
              ),
            }
          } else {
            return currentState
          }
      }
    },
    {
      list: undefined,
      error: undefined,
      loading: true,
    }
  )

  useEffect(() => {
    if (!listId) {
      // try to make a new list
      if (!currentUser?.userId) {
        dispatch({
          type: "ON_ERROR",
          error: new MOError(MOErrorType.REQUIRES_LOGIN, "Please provide a listId OR signin to create a new list"),
        })
      } else {
        dispatch({
          type: "ON_NEW_LIST",
        })
      }
    } else {
      let unsubscribe = firebase
        .firestore()
        .doc(movieotter.MovieList.documentPathFor(listId))
        .onSnapshot(snapshot => {
          if (snapshot.exists) {
            const data = snapshot.data({
              serverTimestamps: "estimate",
            }) as movieotter.IMovieList<movieotter.FirebaseTimestamp>
            dispatch({ type: "ON_LIST_SNAPSHOT", data })
          } else {
            dispatch({
              type: "ON_ERROR",
              error: new MOError(MOErrorType.DOCUMENT_DOES_NOT_EXISTS, `No list with listId ${listId} exists.`),
            })
          }
        })
      return () => unsubscribe()
    }
  }, [listId, currentUser?.userId])

  return state
}

export default useMovieList

import firebase from "firebase/app"
import "firebase/analytics"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"
import React from "react"
import { Switch, Route } from "react-router-dom"

import Base from "components/Base"
import MoviesPage from "components/MoviesPage"
import MoviePage from "components/MoviePage"
import UsersPage from "components/UsersPage"
import UserPage from "components/UserPage"
import SelfPage from "components/SelfPage"
import ListsPage from "components/ListsPage"
import ListPage from "components/ListPage"
import NotificationsPage from "components/NotificationsPage"
import FavoritesPage from "components/FavoritesPage"
import WatchLaterPage from "components/WatchLaterPage"
import HistoryPage from "components/HistoryPage"
import AuthPage from "components/AuthPage"
import HomePage from "components/HomePage"
import NotFoundPage from "components/NotFoundPage"

const WORKING_ENV = process.env.REACT_APP_WORKING_ENV ?? "DEV"

let config: { [key: string]: any } = {
  apiKey: process.env[`REACT_APP_${WORKING_ENV}_FIREBASE_API_KEY`],
  authDomain: process.env[`REACT_APP_${WORKING_ENV}_FIREBASE_AUTH_DOMAIN`],
  databaseURL: process.env[`REACT_APP_${WORKING_ENV}_FIREBASE_DATABASE_URL`],
  projectId: process.env[`REACT_APP_${WORKING_ENV}_FIREBASE_PROJECT_ID`],
  storageBucket: process.env[`REACT_APP_${WORKING_ENV}_FIREBASE_STORAGE_BUCKET`],
  messagingSenderId: process.env[`REACT_APP_${WORKING_ENV}_FIREBASE_MESSAGING_SENDER_ID`],
  appId: process.env[`REACT_APP_${WORKING_ENV}_FIREBASE_APP_ID`],
  measurementId: process.env[`REACT_APP_${WORKING_ENV}_FIREBASE_MEASUREMENT_ID`],
}

firebase.initializeApp(config)

if (process.env.REACT_APP_FIREBASE_EMULATOR === "true") {
  // TODO: CONFIGURE MovieOtter to work with local firebase emulator suite for easier local development / testing

  // config = {
  //   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  //   databaseURL: `http://localhost:9000?ns=${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  // }
  firebase.firestore().settings({
    host: "localhost:8080",
    ssl: false,
  })
  firebase.functions().useFunctionsEmulator("http://localhost:5001")
} else {
  firebase.firestore().enablePersistence()
}
firebase.analytics()

const App = () => (
  <Base>
    <Switch>
      <Route path="/users">
        <UsersPage />
      </Route>
      <Route path="/user/:username">
        <UserPage />
      </Route>
      <Route path="/self">
        <SelfPage />
      </Route>
      <Route path="/lists">
        <ListsPage />
      </Route>
      <Route path="/list/:listId">
        <ListPage />
      </Route>
      <Route path="/movies">
        <MoviesPage />
      </Route>
      <Route path="/movie/tmdb/:tmdbId">
        <MoviePage />
      </Route>
      <Route path="/movie/:movieId">
        <MoviePage />
      </Route>
      <Route path="/notifications">
        <NotificationsPage />
      </Route>
      <Route path="/favorites">
        <FavoritesPage />
      </Route>
      <Route path="/watch-later">
        <WatchLaterPage />
      </Route>
      <Route path="/history">
        <HistoryPage />
      </Route>
      <Route path="/auth/:handler">
        <AuthPage />
      </Route>
      <Route path="/auth">
        <AuthPage />
      </Route>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  </Base>
)

export default App

import React, { FC } from "react"
import { Box, Grid, Typography, Container } from "@material-ui/core"
import firebase from "firebase"

import useBreakpoint, { Breakpoint } from "hooks/useBreakpoint"

import TwitchStream from "components/TwitchStream"

const DevelopmentStream: FC = () => {
  const breakpoint = useBreakpoint()

  const layout = breakpoint >= Breakpoint.md ? "video-with-chat" : "video"

  const getHeight = () => {
    switch (breakpoint) {
      case Breakpoint.xl:
      case Breakpoint.lg:
      case Breakpoint.md:
        return 600
      case Breakpoint.sm:
        return 400
      default:
        return 200
    }
  }
  return (
    <Box py={4} color="primary.contrastText" bgcolor="primary.main" display="flex" justifyContent="center">
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" gutterBottom>
              Join in on the fun
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="center" gutterBottom>
              MovieOtter is being made by{" "}
              <a
                onClick={() => {
                  firebase.analytics().logEvent("navigation-twitter")
                }}
                style={{ fontWeight: "bold" }}
                href="https://www.twitter.com/isaiahbydayah"
                target="_blank"
              >
                1 person
              </a>{" "}
              live on{" "}
              <a
                onClick={() => {
                  firebase.analytics().logEvent("navigation-twitch")
                }}
                style={{ fontWeight: "bold" }}
                href={`https://www.twitch.tv/${process.env.REACT_APP_TWITCH_CHANNEL}`}
                target="_blank"
              >
                Twitch
              </a>{" "}
              every <b>Monday</b>, <b>Wednesday</b>, and <b>Friday</b> starting at <b>8pm Pacific</b>. Stop by and say
              "Hello!" 👋
            </Typography>
          </Grid>

          {/* TODO: Refactor how to fetch stream data to only show twitch embed when live with MovieOtter content */}
          <Grid item xs={12}>
            <Box mt={4}>
              <TwitchStream
                channel={process.env.REACT_APP_TWITCH_CHANNEL || "@IsaiahByDayah"}
                layout={layout}
                height={getHeight()}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default DevelopmentStream

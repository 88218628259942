import React, { FC, useEffect, useState } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import { Container, Box, Grid, Typography, Button, CircularProgress, colors, makeStyles } from "@material-ui/core"
import { CheckCircleRounded } from "@material-ui/icons"
import firebase from "firebase"

import SEO from "components/SEO"
import RaisedTextField from "components/RaisedTextField"
import UserLogin from "components/UserLogin"

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    marginBottom: spacing(),
  },
  directions: {
    marginBottom: spacing(4),
  },
  field: {
    marginBottom: spacing(2),
  },
  submit: {
    marginTop: spacing(2),
  },
  error: {
    marginTop: spacing(),
  },
}))

const AuthPage: FC = () => {
  const history = useHistory()
  const { handler } = useParams<{ handler?: string }>()

  let pageContent
  switch (handler) {
    case "action":
      pageContent = <AuthAction />
      break
    case "login":
      pageContent = <AuthLogin />
      break
    default:
      pageContent = <AuthLogin />
      history.replace("/auth/login")
      break
  }

  return (
    <>
      <SEO title="Auth" />
      {pageContent}
    </>
  )
}

enum Action {
  PASSWORD_RESET = "resetPassword",
  RECOVERY_EMAIL = "recoverEmail",
  VERIFY_EMAIL = "verifyEmail",
}

const AuthAction = () => {
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  let action
  switch (query?.mode) {
    case Action.PASSWORD_RESET:
      if (query?.oobCode) {
        action = <PasswordReset code={query.oobCode as string} />
      } else {
        history.replace("/auth/login")
        action = <AuthLogin />
      }
      break
    default:
      history.replace("/auth/login")
      action = <AuthLogin />
      break
  }

  return action
}

const PasswordReset: FC<{
  code: string
}> = ({ code }) => {
  const history = useHistory()
  const classes = useStyles()

  const [verified, setVerified] = useState(false)
  const [error, setError] = useState("")
  const [newPassword1, setNewPassword1] = useState("")
  const [newPassword2, setNewPassword2] = useState("")

  const passwordMinLength = newPassword1.length >= 6
  const canSubmit = newPassword1 && newPassword2 && newPassword1 === newPassword2 && passwordMinLength

  useEffect(() => {
    setVerified(false)
    if (code) {
      firebase
        .auth()
        .verifyPasswordResetCode(code)
        .then(() => {
          setVerified(true)
        })
        .catch((e: firebase.auth.Error) => {
          setError(e.message)
        })
    } else {
      history.push("/auth/login")
    }
  }, [code])

  const submit = async () => {
    firebase
      .auth()
      .confirmPasswordReset(code, newPassword1)
      .then(() => {
        history.push("/auth/login")
      })
      .catch((e: firebase.auth.Error) => {
        setError(e.message)
      })
  }

  if (!verified) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Container maxWidth="sm">
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h5" align="center">
            Reset Password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.directions} align="center">
            Enter a new password for your account.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <RaisedTextField
            className={classes.field}
            placeholder="new password"
            type="password"
            value={newPassword1}
            onChange={e => setNewPassword1(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <RaisedTextField
            // className={classes.field}
            placeholder="confirm password"
            type="password"
            value={newPassword2}
            onChange={e => setNewPassword2(e.target.value)}
            trailing={<CheckCircleRounded htmlColor={canSubmit ? colors.green[400] : colors.grey[400]} />}
          />
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography className={classes.error} variant="caption" align="center" color="error">
              {error}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} sm="auto">
          <Button
            className={classes.submit}
            variant="contained"
            color="primary"
            disabled={!canSubmit}
            onClick={submit}
            fullWidth
          >
            Reset Password
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

const AuthLogin: FC = () => {
  return (
    <Container maxWidth="sm">
      <UserLogin />
    </Container>
  )
}

export default AuthPage

import React, { FC } from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"

import SEO from "components/SEO"
import NewsletterSignup from "components/NewsletterSignup"
import Feature from "components/Feature"
import DevelopmentStream from "components/DevelopmentStream"
import PromptLoginDisplay from "components/PromptLoginDisplay"
import DiscordServer from "components/DiscordServer"

import {
  TrackImage,
  WatchingImage,
  SharingImage,
  RatingImage,
  ListsImage,
  StatsImage,
  AwardsImage,
  FeaturesImage,
} from "components/svgs"

const HomePage: FC = () => {
  return (
    <Box>
      <SEO title="Home" />
      {/* Heading */}
      <Box py={4}>
        <Container>
          <Grid container>
            <Grid item xs={12} container justify="center">
              <Grid item xs={12}>
                <Box pb={6}>
                  <Typography variant="h3" align="center">
                    Sharing what to watch just got a lot easier.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  Track what you’ve seen, build your collection, share recommendations, and more!
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
              <Grid item xs={12}>
                <Box pt={4}>
                  <PromptLoginDisplay title="Get up and running!" />
                  <NewsletterSignup message="Sign up for the newsletter to stay up-to-date with all new features" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Twitch Stream */}
      <DevelopmentStream />

      {/* Discord Server */}
      <DiscordServer />

      {/* Features */}
      <Box py={4}>
        <Container>
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                What does MovieOtter offer?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                The goal for MovieOtter is to create a simple and easy to use hub for movie-viewers of all kinds. More
                features are always being worked on but here are some things available now.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
              <Feature
                img={<SharingImage />}
                title="Easy sharing for everyone"
                blurb="Think of a movie that your friend would absolutely love? Send them a link and they can instantly add it to their queue."
              />
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
              <Feature
                img={<TrackImage />}
                title="Browse and track movies"
                blurb="Never forget where you left off again. Keep a log of what movies you’ve seen and what’s up next for you to watch."
              />
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
              <Feature
                img={<ListsImage />}
                title="Build your shelf"
                blurb="With custom lists, you can better organize movies into collections and display them on your profile."
              />
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
              <Feature
                comingSoon
                img={<WatchingImage />}
                title="Watching is better with friends"
                blurb="With communities you can schedule watch parties, join a movie “book club”, chat about that new release date, and more."
              />
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
              <Feature
                comingSoon
                img={<StatsImage />}
                title="Get a breakdown of your viewing habits"
                blurb="See details and stats about your viewing history from cast members you tend to like, production studios you should avoid, and other useful information."
              />
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
              <Feature
                comingSoon
                img={<RatingImage />}
                title="See what your friends think"
                blurb="You can tell a lot about a movie based on what your friends think. Instead of traditional reviews, you'll see what your friends' initial reactions are."
              />
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
              <Feature
                comingSoon
                img={<AwardsImage />}
                title="Earn awards"
                blurb="Cross milestones and earn rewards and achievements as you use MovieOtter."
              />
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
              <Feature
                comingSoon
                img={<FeaturesImage />}
                title="Whatever WE come up with next"
                blurb="MovieOtter is being built in the open, so you can let me know what you think would be great to add!"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Mailing List */}
      <Box py={4}>
        <Container>
          <Grid container justify="center">
            <Grid item xs={12}>
              <PromptLoginDisplay title="Sounds great? Signup now and get started!" />
              <NewsletterSignup
                message="Want to know what new features are coming next? Signup for our newsleter to find out."
                tags={["beta"]}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default HomePage

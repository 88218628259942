import React, { FC, useEffect } from "react"
// import { useMatch, useNavigate } from "@reach/router"
import { useParams, useHistory, useRouteMatch } from "react-router-dom"

import { UserProvider } from "providers/UserProvider"
import { useAuth } from "providers/AuthProvider"

import useMovieOtterUser from "hooks/useMovieOtterUser"
import useTrackVisit from "hooks/useTrackVisit"

import SEO from "components/SEO"
import UserProfile from "components/UserProfile"

const UserPage: FC = () => {
  // const navigate = useNavigate()
  const match = useRouteMatch({
    path: "/user",
    exact: true,
  })
  // const usernameMatch = useMatch("user/:username")
  // const username = usernameMatch?.username
  const history = useHistory()
  const { username } = useParams<{ username?: string }>()
  const userContextProps = useMovieOtterUser({ username })
  const { currentUser } = useAuth()

  useEffect(() => {
    if (match) history.push("/users")
  }, [match])

  useEffect(() => {
    if (currentUser && userContextProps.user && userContextProps.user.userId === currentUser.userId) {
      history.push(`/self`, { replace: true })
    }
  }, [currentUser?.userId, userContextProps.user?.userId])

  useTrackVisit(
    "user",
    {
      userId: userContextProps?.user?.userId,
      verified: userContextProps?.user?.verified,
      mvp: userContextProps?.user?.mvp,
      followers: userContextProps?.user?.followers,
      following: userContextProps?.user?.following,
      watchedMovies: userContextProps?.user?.watchedMovies,
      lastSignin: userContextProps?.user?.lastSignin,
      hasPhoto: !!userContextProps?.user?.photoURL,
      hasBio: !!userContextProps?.user?.bio,
      hasLocation: !!userContextProps?.user?.location,
    },
    userContextProps?.user?.userId
  )

  return (
    <>
      <SEO
        title={
          (userContextProps.user && `@${userContextProps.user.username} - ${userContextProps.user.displayName}`) ||
          "User"
        }
      />
      <UserProvider {...userContextProps}>
        <UserProfile />
      </UserProvider>
    </>
  )
}

export default UserPage

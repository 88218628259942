import { useEffect, useReducer, Reducer } from "react"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import MOError, { MOErrorType } from "utils/error"

interface UseMovieOptions {
  movieId?: string
  tmdbId?: number
}

interface UseMovieState {
  movie?: movieotter.Movie
  error?: MOError
  loading: boolean
}

type UseMovieAction =
  | { type: "RESET" }
  | { type: "ON_ERROR"; error: MOError }
  | { type: "ON_IMPORT_ERROR"; error: MOError }
  | { type: "ON_MOVIE_SNAPSHOT"; movie: movieotter.Movie }

export const useMovie = ({ movieId, tmdbId }: UseMovieOptions) => {
  const [useMovieState, dispatch] = useReducer<Reducer<UseMovieState, UseMovieAction>>(
    (state, action) => {
      switch (action.type) {
        case "RESET":
          return {
            ...state,
            error: undefined,
            movie: undefined,
            loading: true,
          }
        case "ON_IMPORT_ERROR":
          return {
            ...state,
            error: state.movie ? undefined : action.error,
            loading: false,
          }
        case "ON_ERROR":
          return {
            ...state,
            error: action.error,
            movie: undefined,
            loading: false,
          }
        case "ON_MOVIE_SNAPSHOT":
          return {
            ...state,
            error: undefined,
            movie: action.movie,
            loading: false,
          }
      }
    },
    {
      movie: undefined,
      error: undefined,
      loading: true,
    }
  )

  useEffect(() => {
    dispatch({
      type: "RESET",
    })
    if (!movieId && !tmdbId) {
      dispatch({
        type: "ON_ERROR",
        error: new MOError(
          MOErrorType.DOES_NOT_MEET_EXPECTATIONS,
          "Please provide either a MovieOtter movieId or a TMDB id"
        ),
      })
    } else if (movieId) {
      let unsubscribe = firebase
        .firestore()
        .doc(movieotter.Movie.documentPathForMovie(movieId))
        .onSnapshot(snapshot => {
          if (snapshot.exists) {
            const data = snapshot.data({
              serverTimestamps: "estimate",
            }) as movieotter.MovieData<movieotter.FirebaseTimestamp>
            const movie = movieotter.Movie.fromDocument(data)
            dispatch({ type: "ON_MOVIE_SNAPSHOT", movie })
            if (!movie.isUpToDate) {
              try {
                firebase.functions().httpsCallable("reimportMovie")({
                  movieId: movie.movieId,
                })
              } catch (e) {
                console.warn("REIMPORT MOVIEID MOVIE ERROR: ", e)
              }
            }
          } else {
            dispatch({
              type: "ON_ERROR",
              error: new MOError(MOErrorType.DOCUMENT_DOES_NOT_EXISTS, `No movie with movieId ${movieId} exists.`),
            })
          }
        })
      return () => unsubscribe()
    } else {
      let unsubscribe = firebase
        .firestore()
        .collection(movieotter.Movie.COLLECTION_NAME)
        .where("externalIds.tmdb", "==", tmdbId)
        .limit(1)
        .onSnapshot(snapshot => {
          if (snapshot.empty) {
            firebase
              .functions()
              .httpsCallable("importTMDBMovie")({ tmdbId })
              .catch(e => {
                dispatch({
                  type: "ON_IMPORT_ERROR",
                  error: new MOError(
                    MOErrorType.DOCUMENT_DOES_NOT_EXISTS,
                    `Could not find a movie on TMDB with id ${tmdbId}.`
                  ),
                })
              })
          } else {
            const data = snapshot.docs[0].data({
              serverTimestamps: "estimate",
            }) as movieotter.MovieData<movieotter.FirebaseTimestamp>
            const movie = movieotter.Movie.fromDocument(data)
            dispatch({ type: "ON_MOVIE_SNAPSHOT", movie })
            if (!movie.isUpToDate) {
              try {
                firebase.functions().httpsCallable("reimportMovie")({
                  movieId: movie.movieId,
                })
              } catch (e) {
                console.warn("REIMPORT TMDBID MOVIE ERROR: ", e)
              }
            }
          }
        })
      return () => unsubscribe()
    }
  }, [movieId, tmdbId])

  return useMovieState
}

export default useMovie

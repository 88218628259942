import React, { FC, useState } from "react"
import { Container, Typography, Button, Grid, Box } from "@material-ui/core"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import dayjs from "dayjs"
import firebase from "firebase"
import { useSnackbar } from "notistack"

import { useAuth } from "providers/AuthProvider"
import { useConfirmation } from "providers/ConfirmationProvider"

import useWatchedMovies from "hooks/useWatchedMovies"

import SEO from "components/SEO"
import ErrorDisplay from "components/ErrorDisplay"
import PromptLoginDisplay from "components/PromptLoginDisplay"
import MovieGrid from "components/MovieGrid"
import Heading from "components/Heading"

const HistoryPage: FC = () => {
  const { currentUser } = useAuth()

  return (
    <>
      <SEO title="Watch History" />
      <HistoryPageContent user={currentUser} />
    </>
  )
}

interface HistoryPageContentProps {
  user?: movieotter.User
}

const HistoryPageContent: FC<HistoryPageContentProps> = ({ user }) => {
  const { loading, error, movies, more } = useWatchedMovies()
  const [editing, setEditing] = useState(false)
  const { currentUser } = useAuth()
  const { confirm } = useConfirmation()
  const { enqueueSnackbar } = useSnackbar()

  if (!user) {
    return <PromptLoginDisplay />
  }

  if (error) {
    console.warn("Watched Movies Error: ", error)
    return <ErrorDisplay message="Something went wrong grabbing your watched movies..." />
  }

  const moviesByMonth = movieotter.utils.bucket(movies, movie => dayjs(movie.dateTime).format("MM/01/YYYY"))

  const editable = currentUser && user?.userId === currentUser.userId
  const editMode = editable && editing

  return (
    <>
      <Container>
        <Heading
          variant="h5"
          onClick={() => setEditing(!editing)}
          onClickLabel={editable ? (editMode ? "Done" : "Edit") : undefined}
          onClickVariant={editable ? (editMode ? "outlined" : "contained") : undefined}
        >
          Watch History
        </Heading>
      </Container>
      {!loading && movies.length === 0 && (
        <Box py={2}>
          <Container>
            <Typography>No movies in watch history...</Typography>
          </Container>
        </Box>
      )}

      {Object.keys(moviesByMonth)
        .sort((a, b) => (a < b ? 1 : -1))
        .map(key => (
          <Box key={key} pb={2}>
            <MovieGrid
              movies={moviesByMonth[key]
                .sort((a, b) => b.dateTime.valueOf() - a.dateTime.valueOf())
                .map(movie => ({
                  movieData: movie.movie,
                  action: editMode ? "delete" : "menu",
                  link: !editMode,
                  onDelete: () =>
                    confirm(
                      () => {
                        firebase
                          .firestore()
                          .doc(movie.documentPath)
                          .delete()
                          .then(() => {
                            firebase.analytics().logEvent("user-watched_movie_deleted", {
                              movieId: movie.movie.movieId,
                              movieTitle: movie.movie.title,
                            })
                            enqueueSnackbar(`"${movie.movie.title}" Removed`, {
                              variant: "success",
                            })
                          })
                      },
                      {
                        title: "Remove Logged Movie?",
                        body: `Are you sure you want to remove "${movie.movie.title}" from your watch history?`,
                      }
                    ),
                }))}
              generateKey={(_, index) => moviesByMonth[key][index].watchedId}
              headingProps={{
                heading: dayjs(key).format("MMM YYYY"),
              }}
            />
          </Box>
        ))}

      {movies.length != user.watchedMovies && (
        <Box pt={2}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button onClick={more} disabled={loading} variant="contained" color="primary" fullWidth>
                  {loading ? "Loading..." : "Load More"}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  )
}

export default HistoryPage

import { useEffect } from "react"
import firebase from "firebase"

import useDebounceState from "hooks/useDebounceState"

type VisitType = "user" | "movie" | "list"

const useTrackVisit = (
  visitType: VisitType,
  visitData: {
    [key: string]: any
  },
  trigger: any,
  delay = 3000
) => {
  const [_, debouncedState, setState] = useDebounceState(null, delay)

  useEffect(() => {
    setState(trigger)
  }, [trigger])

  useEffect(() => {
    if (debouncedState) {
      // Track visit
      firebase.analytics().logEvent(`visit-${visitType}`, visitData)
    }
  }, [debouncedState])
}

export default useTrackVisit

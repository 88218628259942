import React, { FC } from "react"

interface WatchingImageProps {
  color?: string
  height?: number
  width?: number
}

const WatchingImage: FC<WatchingImageProps> = ({ color = "#6c5c43" }) => (
  <svg viewBox="0 0 1041.32 577.51" style={{ maxHeight: 200 }}>
    <path
      d="M961.48 461.54q-1.74 3.75-3.47 7.4-2.7 5.67-5.33 11.12c-.78 1.61-1.56 3.19-2.32 4.77-8.6 17.57-16.63 33.11-23.45 45.89a73.21 73.21 0 01-63.81 38.73l-151.65 1.65h-1.6l-13 .14-11.12.12-34.07.39h-1.38l-17.36.19h-.53l-107 1.16-95.51 1-11.11.12-69 .75h-.11l-44.75.48h-.48l-141.5 1.53-42.33.46a88 88 0 01-10.79-.54c-1.22-.14-2.44-.3-3.65-.49a87.38 87.38 0 01-51.29-27.54c-18.21-20.12-31.46-43.42-40.36-68.81q-1.93-5.49-3.6-11.12C-19.9 364.75 17.66 230.39 85.26 140.5q4.25-5.64 8.64-11l.07-.08c20.79-25.52 44.1-46.84 68.93-62 44-26.91 92.75-34.49 140.7-11.9 40.57 19.12 78.45 28.11 115.17 30.55 3.71.24 7.42.42 11.11.53 84.23 2.65 163.17-27.7 255.87-47.29 3.69-.78 7.39-1.55 11.12-2.28C763 23.87 836.36 16.93 923.6 31.52a189.09 189.09 0 0126.76 6.4q5.77 1.86 11.12 4c41.64 16.94 64.35 48.24 74 87.46q1.37 5.46 2.37 11.11c17.11 94.34-33 228.16-76.37 321.05z"
      fill="#f2f2f2"
    />
    <path
      d="M497.02 468.94a95.22 95.22 0 01-1.87 11.12h93.7v-11.12zm-78.25 62.81l11.11-.09v-27.47c-3.81-.17-7.52-.34-11.11-.52zm-232.92-62.81v11.12h198.5v-11.12zm849.68-339.55h-74V41.93q-5.35-2.18-11.12-4v91.5H696.87V37c-3.73.73-7.43 1.5-11.12 2.28v90.11H429.88V86.57c-3.69-.11-7.4-.29-11.11-.53v43.35H162.9v-62c-24.83 15.16-48.14 36.48-68.93 62h-.07v.08q-4.4 5.4-8.64 11h8.64v328.47h-83q1.66 5.63 3.6 11.12h79.39v93.62a87 87 0 0012.2 2.79c1.21.19 2.43.35 3.65.49a88 88 0 0010.79.54l42.33-.46v-97h255.91v94.21l11.11-.12v-94.07h255.87v91.31l11.12-.12v-91.19h253.49v4.77c.76-1.58 1.54-3.16 2.32-4.77q2.63-5.44 5.33-11.12 1.73-3.65 3.47-7.4v-321h76.42q-1.01-5.69-2.37-11.15zM162.9 468.94V140.5h255.87v328.44zm267 0V140.5h255.85v328.44zm520.48 0H696.87V140.5h253.49z"
      opacity=".1"
    />
    <path
      d="M834.73 353.08a186.94 186.94 0 00-57.35-64.25 12.69 12.69 0 00-5.73-2.16c2.81-18.48 2.22-38.9-8.21-54.3-24.75-36.54-150.85 1.17-150.85 1.17-88.39-30.64-165 8.25-165 8.25s-97.82-15.32-142.6-3.53c-21.12 5.56-26.25 27.1-25.77 48.32h-.07a12.45 12.45 0 00-9.75 4.68c-11.91 14.88-47.89 60.71-46.91 69.56 1.18 10.61 40.07 148.49 38.31 215.08l543.29-6s9-134 31.51-207.35a12.44 12.44 0 00-.87-9.47z"
      fill="#3f3d56"
    />
    <path
      opacity=".1"
      d="M267.88 361.42l22.39-44.79 18.85 87.21.22 40.48-41.46-82.9zM781.7 361.42l-22.39-44.79-25.97 82.69 5 47 43.36-84.9z"
    />
    <path fill="#3f3d56" d="M307.48 401.46l425.9-2.33 4.72 47.14-428.98-1.18-1.64-43.63z" />
    <path opacity=".1" d="M307.48 401.46l425.9-2.33 4.72 47.14-428.98-1.18-1.64-43.63z" />
    <rect x="307.34" y="441.68" width="433" height="50.68" rx="10.47" fill="#fff" opacity=".1" />
    <path
      d="M651.73 272.14s-1.44-2-3.14-.92c-.81.53-4.62 5.33-8.36 10.21-1.84 2.39-3.67 4.8-5.12 6.72-1.79 2.36-3 4-3 4l-.72 1.35-4.37 8.17-4.11 7.68-1 3.62 13.88 4.72 15.8-4.31-.68-2.93-.12-.5.13-6.74.36-19 .17-8.55v-1z"
      fill="#ffe09c"
    />
    <path
      d="M619.95 318.98s15 5.35 22.69 2.55c0 0 6.65-2.12 9.27-4.17 0 0 2.79-1.46-1-6.22 0 0-9.71 5.95-18.9 3.61l-9.19-2.35z"
      fill="#e7c4c3"
    />
    <ellipse cx="713.92" cy="469.92" rx="4.91" ry="4.16" transform="rotate(-30.37 377.103 535.436)" fill="#ff7f8e" />
    <ellipse cx="723.89" cy="457.96" rx="4.91" ry="4.16" transform="rotate(-30.37 387.077 523.466)" fill="#ff7f8e" />
    <path
      d="M650.91 303.21a5.14 5.14 0 00-4.68.37c-2.34 1.37-3.3 4.09-2.13 6.07s4 2.48 6.33 1.11c.16-.1.32-.2.47-.31l-.12-.5zM633.94 295.28a3.83 3.83 0 00-2.56-1.78l-4.37 8.17a5.11 5.11 0 004.8-.32c2.34-1.37 3.29-4.09 2.13-6.07zM643.66 283.31a4 4 0 00-3.41-1.88c-1.84 2.39-3.67 4.8-5.12 6.72l.06.12c1.16 2 4 2.47 6.34 1.1s3.27-4.08 2.13-6.06zM651.44 275.65a5.62 5.62 0 00-1.47.6c-2.34 1.37-3.29 4.09-2.13 6.07a4 4 0 003.43 1.88z"
      fill="#ff7f8e"
    />
    <path
      d="M628.15 308.44l-.8-1.35a8.61 8.61 0 003.24-1.8c.06-.06.11-.13.16-.19 1-1.26.79 1.36.79 1.36s-2.46 2.09-3.39 1.98zM639.95 306.35l-.8-1.36a8.68 8.68 0 003.24-1.8c0-.06.11-.12.16-.19 1-1.25.79 1.36.79 1.36s-2.46 2.1-3.39 1.99zM638.87 300.75l-.8-1.36a8.68 8.68 0 003.24-1.79c.05-.07.11-.13.16-.19 1-1.26.79 1.35.79 1.35s-2.46 2.08-3.39 1.99zM640.3 290.65l-.8-1.36a8.68 8.68 0 003.24-1.8c0-.06.11-.12.16-.19 1-1.26.79 1.36.79 1.36s-2.46 2.09-3.39 1.99zM647.07 278.43l-.79-1.36a8.6 8.6 0 003.23-1.8 1.46 1.46 0 00.16-.19c1-1.25.8 1.36.8 1.36s-2.47 2.09-3.4 1.99zM646.31 290.9l-.8-1.36a8.6 8.6 0 003.24-1.79c0-.07.11-.13.16-.19 1-1.26.79 1.35.79 1.35s-2.46 2.1-3.39 1.99z"
      fill="#94cc79"
    />
    <path
      d="M617.49 314.75s-1.92 5 2.46 4.2a17.34 17.34 0 003.87-7.91s9.47-20.75 12.27-22.65c0 0 12.34-16.87 13.67-17.12 0 0 .43-1.64-3.88 1.83s-13.75 16.83-13.75 16.83l-9 14.67-2.12 5.85z"
      fill="#e7c4c3"
    />
    <path
      d="M617.49 314.75s-1.92 5 2.46 4.2a17.34 17.34 0 003.87-7.91s9.47-20.75 12.27-22.65c0 0 12.34-16.87 13.67-17.12 0 0 .43-1.64-3.88 1.83s-13.75 16.83-13.75 16.83l-9 14.67-2.12 5.85z"
      fill="#fff"
      opacity=".2"
    />
    <path
      d="M468.74 416.32a120.28 120.28 0 006.08 15.86 147.27 147.27 0 0020.27-5.78c.21-.07-3.79-5.3-4.21-5.73a20.12 20.12 0 00-5.73-4.4c-2.11-.92-4.71-.22-7-.1q-4.68.24-9.41.15z"
      fill="#fbbebe"
    />
    <path
      d="M485.19 424.53c-3.44-1.08-5.54-4.55-6.94-7.89s-2.53-7-5.19-9.44a3.09 3.09 0 00-2.34 1.76 29.32 29.32 0 01-1.39 2.74c-1.1 1.6-3 2.38-4.45 3.74s-2.26 3.43-3.77 4.8c-1.9 1.72-4.58 2.15-7.07 2.72a45.16 45.16 0 00-11.08 4.1 18.59 18.59 0 01-4.1 1.84 15.14 15.14 0 01-4.8.16l-11-.78a8.78 8.78 0 00-2.94.12 5.36 5.36 0 00-3.5 5.53 8.6 8.6 0 003.48 5.93 23.47 23.47 0 006.28 3.17 185 185 0 0029.54 8.44v2.18a50.85 50.85 0 0022.49 5.24 3.56 3.56 0 001.68-.29 3.64 3.64 0 001.42-2.16 87.26 87.26 0 004.59-24 18.24 18.24 0 00-.47-6.17c-.6-2 1.46-1.15-.45-1.93"
      fill="#2f2e41"
    />
    <path
      d="M696.51 382.99c-32.67 2-65-7.61-97.66-8.63a48 48 0 00-12.15.78c-4.2.94-8.12 2.84-12 4.66-17.55 8.16-35.69 14.92-53.82 21.67l-20.82 7.76c-4.16 1.55-8.47 3.12-12.9 2.85-2-.12-4.43-.42-5.54 1.23-3.39 5-1.79 12.39.45 18 .72 1.82 2 3.8 3.9 4 1.37.14 2.65-.65 4-1 3.79-1 7.57 1.54 11.47 1.95 4.82.51 9.29-2.23 13.84-3.91 7.54-2.79 15.79-2.7 23.72-4 13.3-2.22 25.62-8.39 37.14-15.42 4.38-2.68 8.82-5.55 13.86-6.53 5.79-1.13 11.74.36 17.43 2a401.22 401.22 0 0139.94 13.6c6.88 2.76 13.72 5.71 20.88 7.62a75.53 75.53 0 0046.78-2.73c5.22-2.07 10.91-5.72 11.21-11.36.17-3.22-2.33-6-4-8.8-1.06-1.77-2.13-3.53-3.3-5.23-5.67-8.26-12.51-19.1-22.43-18.51z"
      fill="#2f2e41"
    />
    <path
      d="M696.51 382.99c-32.67 2-65-7.61-97.66-8.63a48 48 0 00-12.15.78c-4.2.94-8.12 2.84-12 4.66-17.55 8.16-35.69 14.92-53.82 21.67l-20.82 7.76c-4.16 1.55-8.47 3.12-12.9 2.85-2-.12-4.43-.42-5.54 1.23-3.39 5-1.79 12.39.45 18 .72 1.82 2 3.8 3.9 4 1.37.14 2.65-.65 4-1 3.79-1 7.57 1.54 11.47 1.95 4.82.51 9.29-2.23 13.84-3.91 7.54-2.79 15.79-2.7 23.72-4 13.3-2.22 25.62-8.39 37.14-15.42 4.38-2.68 8.82-5.55 13.86-6.53 5.79-1.13 11.74.36 17.43 2a401.22 401.22 0 0139.94 13.6c6.88 2.76 13.72 5.71 20.88 7.62a75.53 75.53 0 0046.78-2.73c5.22-2.07 10.91-5.72 11.21-11.36.17-3.22-2.33-6-4-8.8-1.06-1.77-2.13-3.53-3.3-5.23-5.67-8.26-12.51-19.1-22.43-18.51z"
      opacity=".1"
    />
    <path
      d="M531.46 417.91q7.66 2.61 15.48 4.81c2.91-4.78 8.36-8.11 9.6-13.57a.93.93 0 00-.59-1.26 28.83 28.83 0 00-12.11-3.55c-3.36-.21-4.89 3.29-6.42 5.76-.61.99-4.59 8.28-5.96 7.81z"
      fill="#fbbebe"
    />
    <path
      d="M538.66 414.42a11.19 11.19 0 01-7.52-4.94c-.72-1.26-1.49-3-2.93-2.87s-2 1.69-2.26 3a85.2 85.2 0 01-4.21 14.53 27.27 27.27 0 01-9 11.88c-1.9 1.31-4 2.3-5.86 3.68-1.67 1.23-3.12 2.79-5 3.74a26.67 26.67 0 01-3.8 1.42 26.57 26.57 0 00-5.44 2.65 10.82 10.82 0 00-3.7 3.54 5.17 5.17 0 00-.31 5 5.36 5.36 0 003.63 2.48 15.47 15.47 0 004.51.12c5.95-.44 11.9-1 17.85-1.5a52.18 52.18 0 007.48-1c1.31-.32 2.58-.73 3.88-1.07a32.64 32.64 0 016.8-1 6 6 0 012 .18c1.38.45 2.35 1.82 3.78 2.1a6 6 0 002.69-.36c4.28-1.17 8.79-1.14 13.22-1.38 2-.1 4.24-.47 5.14-2.22 1.12-2.18-.74-4.62-1.47-7a21.14 21.14 0 01-.62-4.57 27.81 27.81 0 00-3.28-10.85c-1.51-2.78-3.58-5.86-2.52-8.85.36-1 1.08-2 1-3-.34-4.79-11.35-3.14-14.06-3.71z"
      fill="#2f2e41"
    />
    <path
      d="M647.52 330.58a25.57 25.57 0 01-13-12.93c-.55-1.24-1-2.53-1.52-3.78a28.86 28.86 0 00-7.78-10.81 3.76 3.76 0 00-2.89-1.22 3.1 3.1 0 00-2.12 2.28 9.06 9.06 0 00-.22 3.49 62.43 62.43 0 001.42 8.16l1.58 7.21a13.25 13.25 0 001 3.22 11.22 11.22 0 002.65 3.18c1.45 1.34 2.92 2.63 4.41 3.89a12.37 12.37 0 007.65 3.4c6.48 1.2 13.08 2.94 18.56 7.17-.07 0 0-4.84-.12-5.39-.25-1.45-.45-2.89-1.39-3.87-2.15-2.2-5.64-2.83-8.23-4z"
      fill="#fbbebe"
    />
    <path
      d="M647.52 330.58a25.57 25.57 0 01-13-12.93c-.55-1.24-1-2.53-1.52-3.78a28.86 28.86 0 00-7.78-10.81 3.76 3.76 0 00-2.89-1.22 3.1 3.1 0 00-2.12 2.28 9.06 9.06 0 00-.22 3.49 62.43 62.43 0 001.42 8.16l1.58 7.21a13.25 13.25 0 001 3.22 11.22 11.22 0 002.65 3.18c1.45 1.34 2.92 2.63 4.41 3.89a12.37 12.37 0 007.65 3.4c6.48 1.2 13.08 2.94 18.56 7.17-.07 0 0-4.84-.12-5.39-.25-1.45-.45-2.89-1.39-3.87-2.15-2.2-5.64-2.83-8.23-4z"
      opacity=".1"
    />
    <ellipse cx="714.05" cy="267.38" rx="7.95" ry="7.99" fill="#2f2e41" />
    <path
      d="M709.13 262.55c-2.15-.24-4.55-.41-6.28.91a8.5 8.5 0 00-2.15 2.92 42.24 42.24 0 00-4.13 9.73c-.71 2.92-.83 6-1.71 8.84-1.63 5.32-5.8 9.81-6.28 15.36-.21 2.48.21 5.39-1.61 7.09a10.14 10.14 0 01-1.42 1 10.8 10.8 0 00-3.13 3.22 10.42 10.42 0 01-2.22 3.07c-1.62 1.2-4.3 1.07-5.1 2.93s1.26 4 .78 6c-.39 1.64-2.22 2.39-3.48 3.51-1.62 1.43-2.36 3.58-3 5.64a18.74 18.74 0 01-15.2-3.79c2.21 5.46 2.26 11.49 2.36 17.39 0 1.91 0 4.06-1.4 5.39 6.33.14 12.5 1.85 18.67 3.25 2.7.61 5.63 1.14 8.14 0a13.11 13.11 0 003.73-3c3.36-3.41 6.88-7.12 7.7-11.85.46-2.64 0-5.36.14-8a45.7 45.7 0 012-9.76 115.57 115.57 0 003.57-20.08c.36-4.18 4-7.55 5.88-11.3 3.6-7.06 7.27-14.42 7.54-22.35.13-2.62-.77-5.83-3.4-6.12z"
      fill="#2f2e41"
    />
    <path
      d="M709.13 262.55c-2.15-.24-4.55-.41-6.28.91a8.5 8.5 0 00-2.15 2.92 42.24 42.24 0 00-4.13 9.73c-.71 2.92-.83 6-1.71 8.84-1.63 5.32-5.8 9.81-6.28 15.36-.21 2.48.21 5.39-1.61 7.09a10.14 10.14 0 01-1.42 1 10.8 10.8 0 00-3.13 3.22 10.42 10.42 0 01-2.22 3.07c-1.62 1.2-4.3 1.07-5.1 2.93s1.26 4 .78 6c-.39 1.64-2.22 2.39-3.48 3.51-1.62 1.43-2.36 3.58-3 5.64a18.74 18.74 0 01-15.2-3.79c2.21 5.46 2.26 11.49 2.36 17.39 0 1.91 0 4.06-1.4 5.39 6.33.14 12.5 1.85 18.67 3.25 2.7.61 5.63 1.14 8.14 0a13.11 13.11 0 003.73-3c3.36-3.41 6.88-7.12 7.7-11.85.46-2.64 0-5.36.14-8a45.7 45.7 0 012-9.76 115.57 115.57 0 003.57-20.08c.36-4.18 4-7.55 5.88-11.3 3.6-7.06 7.27-14.42 7.54-22.35.13-2.62-.77-5.83-3.4-6.12z"
      opacity=".1"
    />
    <path
      d="M701.66 389.87c-6.8-3.24-11.78-9.27-17.28-14.44-10.5-9.86-23.35-16.84-34.39-26.09-7.32-6.12-13.86-13.26-21.9-18.39a54.54 54.54 0 00-23.43-8.2 6.45 6.45 0 00-2.87.14 6.82 6.82 0 00-2.67 2.11 83.93 83.93 0 00-9.46 13c-14 23.75-33.88 43.75-50.35 65.82a3 3 0 00-.2 4l3.35 6.56c1.87 3.68 4.88 8 8.92 7.23 1.85-.34 3.32-1.7 4.68-3l15-14.37 15.77-15.08c9.24-8.83 18.63-17.87 24.66-29.16 4.7 8.16 13.06 13.5 18.79 21 2.18 2.84 4 6 5.9 9 3.12 4.82 6.67 9.35 10.21 13.87l18.09 23.09c2.46 3.14 5 6.36 8.37 8.49 4.29 2.71 9.5 3.43 14.54 3.88a193.65 193.65 0 0020.44.74c5.2-.09 10.56-.43 15.23-2.74s8.5-7 8.26-12.28a18.21 18.21 0 00-2.61-7.65c-6.42-11.9-14.87-21.75-27.05-27.53z"
      fill="#2f2e41"
    />
    <ellipse cx="718.94" cy="272.91" rx="6.11" ry="6.15" fill="#e8e8f0" />
    <path
      d="M717.59 268.29a11.65 11.65 0 01.67 8.82 34.34 34.34 0 01-3.93 8.14 40.58 40.58 0 01-5.11 7.29c9.43.46 18.58-3.24 26.9-7.73 6.7-3.6 13.38-8.06 17-14.8a7.8 7.8 0 01-6.25-1.15 23.22 23.22 0 01-4.78-4.46l-4.74-5.33a21.08 21.08 0 01-2.91-3.82 10.71 10.71 0 01-1.25-6.18 35.09 35.09 0 01-6.27 5.2c-3.38 2.2-7.11 3.8-10.5 6-1.2.77-3.88 2-3.19 3.59s3.43 2.75 4.36 4.43z"
      fill="#fbbebe"
    />
    <path
      d="M717.59 268.29a11.65 11.65 0 01.67 8.82 34.34 34.34 0 01-3.93 8.14 40.58 40.58 0 01-5.11 7.29c9.43.46 18.58-3.24 26.9-7.73 6.7-3.6 13.38-8.06 17-14.8a7.8 7.8 0 01-6.25-1.15 23.22 23.22 0 01-4.78-4.46l-4.74-5.33a21.08 21.08 0 01-2.91-3.82 10.71 10.71 0 01-1.25-6.18 35.09 35.09 0 01-6.27 5.2c-3.38 2.2-7.11 3.8-10.5 6-1.2.77-3.88 2-3.19 3.59s3.43 2.75 4.36 4.43z"
      opacity=".1"
    />
    <path
      d="M717.72 269.22c-1.77 3-2.15 6.5 0 9.22.27.34 1.26 2.44 1.69 2.46a1.67 1.67 0 001.08-.55c2.29-2.09 4-4.76 6-7.13s4.55-4.52 7.61-5c3.69-.57 7.26 1.38 10.49 3.27a10.05 10.05 0 013.58 2.93 9.63 9.63 0 011.18 4c1.11 8.41 1.09 16.93 1.06 25.41 0 6.14 0 12.31-1.2 18.33-.6 3.12-1.5 6.17-1.93 9.31a62.84 62.84 0 00-.37 8.35v11.68a29.76 29.76 0 00.45 6.4c.55 2.56 1.76 4.92 2.57 7.41a48.82 48.82 0 011.71 8.76l1.6 12.09c.5 3.81.87 8-1.43 11-1 1.38-2.41 2.49-3.46 3.87-4 5.14-2.69 12.49-4.34 18.78a23.51 23.51 0 01-7.66 11.75c-2.83 2.4-6.48 4.28-10.11 3.61-4.91-.91-7.86-6-9.2-10.87s-1.82-10.1-4.72-14.18c-2.37-3.34-6-5.44-9.36-7.82s-6.56-5.45-7.28-9.49c-.32-1.76-.12-3.57-.31-5.35a12.65 12.65 0 00-7.82-10.27c-2.51-1-5.61-1.33-7-3.63 3.66-2.06 6.08-5.87 7.36-9.88s1.54-8.27 1.79-12.47l.84-14.05c.37-6.28.54-12.5 3.31-18.14 1.23-2.53 2.77-4.89 4.06-7.38a83.26 83.26 0 004.35-10.83c2-5.76 4-11.52 5.8-17.35a34.58 34.58 0 013.18-8.08c1.42-2.44 3.64-5.63 6.48-6.16z"
      fill={color}
    />
    <path
      d="M686.52 360.5l3.72.13.79-5.61a11.73 11.73 0 01.78-3.28 20.63 20.63 0 012.14-3.13c3.66-5.17 3.39-12 4.3-18.31 1.21-8.38 4.71-16.22 8.17-23.93l7.24-16.09a17.6 17.6 0 002-8.88c.07-2.49.8-11.53.87-14 0-.92-2-.41-2.45-1.23-.9-1.79-2.14 3.06-3.83 4.12s-2.63 3-3.34 4.86c-1.56 4.05-2.51 8.31-4 12.4s-3.47 8.11-6.74 11c-1.48 1.3-3.18 2.32-4.58 3.71-4.49 4.44-4.93 11.45-5.09 17.78-.08 3.71-.84 40.46.02 40.46zM740.04 262.51c-3.61 1.42-5.59 5.34-6.6 9.1s-1.43 7.78-3.37 11.15c-4.54 7.89-16.1 10.19-19.29 18.72-1 2.79-1 5.85-1.29 8.82-.58 6.11-2.59 12-3.18 18.12a79.77 79.77 0 00.33 14.24l4 48.19c.59 7 .57 14.43-1.21 21.19 4.09.79 8.35-.27 12.14-2s7.26-4.11 10.91-6.12c6.28-3.47 13.21-5.92 18.71-10.54a10.79 10.79 0 002.52-2.79c1.1-1.9 1.2-4.21 1.72-6.35a34.62 34.62 0 011.81-5 148.83 148.83 0 005.26-14.86 80.83 80.83 0 002.41-10.2c.44-2.74.68-5.52.92-8.29a49.78 49.78 0 012.56-12.74 102.24 102.24 0 005.91-30.52 15.67 15.67 0 00-.31-4.49 24.87 24.87 0 00-2.44-5.1c-1.68-3.16-2.69-6.64-4.27-9.86-3.58-7.25-9.91-12.76-16.53-17.37-3.22-2.25-7.02-4.74-10.71-3.3z"
      fill="#2f2e41"
    />
    <path
      d="M655.57 341.13a29.06 29.06 0 01-7.69-5.85c-1-1.18-1.84-2.51-2.87-3.67a18.84 18.84 0 00-9.68-5.52 47.34 47.34 0 00-11.21-1.09 20.57 20.57 0 00-7.46.9 7.49 7.49 0 00-4.94 5.31c-.55 3 1.41 5.91 3.76 7.84a18.14 18.14 0 007.52 3.65 61.63 61.63 0 006.79.79c13.38 1.4 25.32 8.68 37 15.31q15.45 8.75 31.49 16.34a63.78 63.78 0 00.53-9.67c-.06-2.41-.18-6.09-2.46-7.51-2.65-1.65-7-2-10-3a161.11 161.11 0 01-20.15-8.15q-5.41-2.65-10.63-5.68z"
      fill="#fbbebe"
    />
    <ellipse cx="794.03" cy="403.38" rx="23.97" ry="23.84" transform="rotate(-75.27 649.792 374.212)" fill="#fbbebe" />
    <path
      d="M746.86 289.69c-2.13 2.48-3.2 5.68-4.63 8.62-2.94 6.06-7.56 11.26-9.87 17.6-2.44 6.68-2.36 14.62-7.14 19.87-.83.91-1.83 1.79-2.07 3a16 16 0 01-.25 2.19c-.68 1.9-3.8 2-4.38 3.94-.17.57-.08 1.19-.29 1.75-.48 1.25-2.13 1.44-3.24 2.2-2.32 1.57-1.85 5.21-3.5 7.48a6.27 6.27 0 01-6 2.08 21.32 21.32 0 01-6.18-2.31 5.69 5.69 0 00-2.22-.69 4.67 4.67 0 00-2.57.87c-4.4 2.8-6.47 8.35-6.44 13.59s1.83 10.28 3.67 15.18a6.59 6.59 0 001.22 2.26c1.46 1.54 4 1.3 5.94.44s3.7-2.21 5.78-2.59c3.69-.67 7.3 1.87 11 1.66 2.74-.16 5.17-1.75 7.44-3.29 3.64-2.46 7.34-5 10-8.48 1.64-2.14 2.84-4.57 4.27-6.86a79.39 79.39 0 016-8l13.74-16.82a36.3 36.3 0 003.7-5.11 35.86 35.86 0 002.88-7.68c1.31-4.49 2.63-9 2.85-13.69a54 54 0 00-1-11.47 39.79 39.79 0 00-3-10.92c-2.83-6.24-10.1-11.37-15.71-4.82z"
      fill="#2f2e41"
    />
    <path
      d="M788.42 278.99a26.83 26.83 0 00-5.12-23.9c3.65-15.57-4.3-31.16-18.3-36.93.5-14.6-8.75-28-23-31.76-14.81-3.89-30 3.93-36.39 17.83-9-4.67-18.52-4.17-21.66 1.32-3.28 5.74 1.65 14.77 11 20.16a29.33 29.33 0 009.53 3.53 31 31 0 0017.25 18.26 31.78 31.78 0 0016.4 29.15 26.56 26.56 0 004.43 11.43 20 20 0 00-.7 2.19c-2.94 11.15 3.42 22.49 14.19 25.32s21.89-3.91 24.82-15.06a21.36 21.36 0 00.4-9.11 27.1 27.1 0 007.15-12.43z"
      fill="#3f3d56"
    />
    <path
      d="M690.12 208.45c3.14-5.49 12.65-6 21.66-1.32 6.37-13.9 21.58-21.73 36.39-17.83.67.17 1.33.38 2 .6a30.21 30.21 0 00-8.16-3.5c-14.81-3.89-30 3.93-36.39 17.83-9-4.67-18.52-4.17-21.66 1.32-3.28 5.74 1.65 14.77 11 20.16a31.31 31.31 0 005.52 2.5c-8.94-5.46-13.56-14.16-10.36-19.76zM748.04 293.17a20 20 0 01.7-2.19 26.6 26.6 0 01-4.43-11.44 31.76 31.76 0 01-16.4-29.14 31 31 0 01-17.25-18.26 27.58 27.58 0 01-5.71-1.67 30.89 30.89 0 0016.77 17 31.78 31.78 0 0016.4 29.15 26.56 26.56 0 004.43 11.43 20 20 0 00-.7 2.19c-2.93 11.11 3.38 22.41 14.09 25.29a20.84 20.84 0 01-7.9-22.36z"
      opacity=".1"
    />
    <ellipse cx="801.34" cy="407.73" rx="3.67" ry="4.91" transform="rotate(-37.22 522.217 444.926)" fill="#fbbebe" />
    <circle cx="431.8" cy="23.05" r="16.29" fill="#3f3d56" />
    <ellipse cx="467.15" cy="59.2" rx="45.69" ry="59.2" fill="#3f3d56" />
    <path
      d="M467.15 100.52c-22.89 0-41.85-21.81-45.18-50.26a77.1 77.1 0 00-.52 8.94c0 32.69 20.46 59.2 45.7 59.2s45.69-26.51 45.69-59.2a77.1 77.1 0 00-.52-8.94c-3.32 28.49-22.28 50.26-45.17 50.26z"
      opacity=".1"
    />
    <path
      d="M564.51 232.58c-1 4-5.21 7.26-9.89 9.51-9.86 4.75-22 6.16-33.79 6.64a56.68 56.68 0 01-9-.17c-6.17-.73-11.89-3.36-15.46-7.11a2.85 2.85 0 01-1-1.62 2.88 2.88 0 011-2c6.29-6.86 16.77-11.08 27.29-14.08 8-2.31 21.94-8.29 30.67-7.48 8.48.73 11.42 11.21 10.18 16.31z"
      fill="#3f3d56"
    />
    <path
      d="M564.51 232.58c-1 4-5.21 7.26-9.89 9.51-9.86 4.75-22 6.16-33.79 6.64a56.68 56.68 0 01-9-.17c-6.17-.73-11.89-3.36-15.46-7.11a2.85 2.85 0 01-1-1.62 2.88 2.88 0 011-2c6.29-6.86 16.77-11.08 27.29-14.08 8-2.31 21.94-8.29 30.67-7.48 8.48.73 11.42 11.21 10.18 16.31z"
      opacity=".1"
    />
    <path
      d="M439.25 202.44a24.94 24.94 0 00-1.51 19 33.23 33.23 0 0011.15 15.78c12.77 10.25 30.29 11.66 46.62 11.53a360.64 360.64 0 0042.82-2.94c4.73-.61 9.51-1.33 13.89-3.22 5.33-2.31 10-6.54 11.91-12s.56-12.22-4-15.88c-4-3.22-9.43-3.66-14.52-4l-29.15-1.75c2.88-2.92 6.71-4.57 10.22-6.68 4.65-2.82 7.74-7.54 11.13-11.79 5-6.36 11-11.92 17-17.45 1 5.44 4.39 9.91 8.44 13.67s8.72 6.78 12.94 10.35c10.2 8.66 17.54 20.42 28.27 28.42a55.4 55.4 0 0020.27-10.12l-4.17-7.55a5 5 0 00-1.57-2c-1.19-.72-2.71-.35-4.08-.55-3.66-.55-5.26-4.78-6.7-8.19-3.94-9.38-11.5-16.65-18-24.51a122.82 122.82 0 01-12.55-18.57c-2.17-3.91-4.15-8-7.1-11.37s-7.06-6-11.54-6c-4 0-7.6 2-11 4a291.53 291.53 0 00-29.84 20.33c-5 3.86-9.93 7.95-15.77 10.32-2.46 1-5 1.68-7.41 2.89-4.13 2.13-7.27 5.73-10.65 8.92-12.04 11.39-28.53 18.77-45.1 19.36z"
      fill="#605d82"
    />
    <path
      d="M500.21 121.34a29.77 29.77 0 01-2.19 10.15 12.65 12.65 0 01-7.28 7 15.61 15.61 0 01-7.1.23 13.72 13.72 0 01-5.79-2c-2.73-1.88-4.09-5.23-4.59-8.51s-.27-6.63-.59-9.93a54.53 54.53 0 00-1.52-7.9c-.21-.86-.43-1.72-.65-2.58s-.41-1.72-.6-2.58-.34-1.61-.48-2.43-.22-1.38-.29-2.07a23.39 23.39 0 00-1-6.08 18.24 18.24 0 00-2.13-3.6c-.36-.52-.71-1-1-1.57a7.44 7.44 0 01-1.23-4.73 4 4 0 01.19-.76 2.33 2.33 0 01.11-.28 2.28 2.28 0 001.55-1.45 4.33 4.33 0 00-.19-2.25 14 14 0 00-1.21-2.89c-.33-.6-1.46-1.69-1.47-2.38 0-1.39 2.7-2.51 3.8-3.06a21.15 21.15 0 0110.86-2.2c2.4.16 5 .9 6.33 2.92 1.2 1.81 1 4.16 1.55 6.27a1.74 1.74 0 00.37.84 1.7 1.7 0 00.94.43 5.6 5.6 0 001.78.07 2.54 2.54 0 00.47-.07 6.3 6.3 0 002.56-1.1c.5-.14 1-.24 1.49-.33h.06c2 6.28 2.25 13 3.88 19.42.81 3.18 2 6.32 2.13 9.6v.43c0 1.06-.06 2.12 0 3.17a9.38 9.38 0 00.16 1.4 21.36 21.36 0 00.66 2.2c.07.23.14.46.2.7a4.7 4.7 0 01.12.51 18.87 18.87 0 01.1 5.41z"
      fill="#fbbebe"
    />
    <circle cx="472.33" cy="56.82" r="23.44" fill="#fbbebe" />
    <path
      d="M621.16 184.54a38.47 38.47 0 00-6.61-17.79l1.83 10.85a4.07 4.07 0 01-4.25-1.31 13 13 0 01-2.23-4.14 85 85 0 00-10.37-18.6c-14.92-1.25-29.68-7.43-42.8-14.65s-25.41-15.85-38.37-23.36a56.27 56.27 0 00-7-3.58c-4.05-1.62-8.6-2.46-12.44-4.37l-.77-.41a15.48 15.48 0 01-2.46-1.64 13.36 13.36 0 01-1.17-1.09 25.74 25.74 0 01-4.5-7.51c-2.06-4.56-2.8-10.07-4.85-14.63a14.49 14.49 0 013.15-2.23l.5-.25a15.31 15.31 0 012.53-.92c.5-.14 1-.24 1.49-.33h.06a14.71 14.71 0 016.94.55c3.26 1.11 6 3.31 8.76 5.4 4.35 3.29 8.87 6.39 12.82 10.16 2.26 2.17 4.33 4.55 6.76 6.52a53.62 53.62 0 006.39 4.24L574.11 129c4.46 2.65 8.93 5.31 13.29 8.13 6.63 4.27 13 8.9 19.4 13.53l11.29 8.18a12.38 12.38 0 013.2 3 10.74 10.74 0 011.41 4.33 39.62 39.62 0 01-1.54 18.37z"
      fill="#fbbebe"
    />
    <path
      d="M506.03 138.97c-1.59 7.09-8.75 11.38-12.5 17.61-4.66 7.73-2.76 16.72-1 25.58l-9.59 8.26c-1.76 1.51-3.53 3-5.42 4.37a72.89 72.89 0 01-8.67 5l-8.48 4.36a40.66 40.66 0 01-8.31 3.52c-5.58 1.43-11.5.43-17-1.22a7.58 7.58 0 01-1.86-.76 3.05 3.05 0 01-.88-.8 4.89 4.89 0 01-.56-3.21c.56-9.28 2.83-18.37 5.09-27.39a106.67 106.67 0 014.81-15.9 107.16 107.16 0 016.83-12.82c3-5.07 6-10.18 10-14.53 1.71-1.88 3.62-3.66 4.64-6 1.41-3.22.86-6.91 1.15-10.41a21.59 21.59 0 012.06-7.59 21.76 21.76 0 012.2-3.64c.39.9.77 1.8 1.16 2.69l.75 1.76c.33.75.65 1.51 1 2.26q1.4 3.22 2.78 6.45a27 27 0 004.21 7.51c1.91 2.16 4.72 3.73 7.58 3.42a9.18 9.18 0 006.46-4.5 26.56 26.56 0 002.87-7.59 24.16 24.16 0 00.32-9.89c-1-6.33-3.65-12.79-5.6-18.67a18.83 18.83 0 01-1.36-6.15 3.42 3.42 0 01.07-.71v-.18a15.31 15.31 0 012.53-.92c.5-.14 1-.24 1.49-.33q2.58 14 5.17 27.94c0 .22.09.44.13.66.24 1.34.49 2.67.77 4s.52 2.41.82 3.6c.1.4.21.81.32 1.21.31 1.1.65 2.18 1 3.26 2.44 6.53 6.51 13.04 5.02 19.75z"
      fill={color}
    />
    <path
      d="M601.17 238.64a8.14 8.14 0 00.77 4c1.46 2.68 5 3.29 8 3.54a237.62 237.62 0 0053.37-1.62 6.25 6.25 0 002.72-.85c2.51-1.76 1-6.05-1.78-7.43s-6-1-9-1.63a28.61 28.61 0 01-8.44-3.66q-9.15-5.26-18-11c-3.53-2.27-7.7-4.69-11.67-3.34-10.79 3.64-16.05 10.38-15.97 21.99z"
      fill="#2f2e41"
    />
    <path
      d="M469.99 104.75l-.09.49-.15.82c-.49 2.52-1.1 5-1.79 7.53-1.91 6.89-4.46 13.68-7 20.36l-20.41 53.8c-1.72 4.52-3.43 9-5.38 13.46-.65 1.47-1.32 3-2 4.46-2.39 5.37-4.69 10.92-5.45 16.65-.5 3.79-1 7.66-.25 11.41s3 7.42 6.61 8.8c1.74.68 4.23 1.59 3.75 3.4-.25 1-1.38 1.48-2.4 1.64a9.19 9.19 0 01-7.53-2.17c-1.9-1.68-3.37-4.31-5.9-4.59-1.75-.19-3.36.86-5 1.59-6.55 3-14.17.9-21.05-1.23.54-2.78 3.34-4.62 6.11-5.17s5.65-.17 8.49-.24a4.42 4.42 0 002.58-.67 4.75 4.75 0 001.43-2.34 99.75 99.75 0 004.05-16.6c.92-5.9 1.3-11.88 2.6-17.7 1-4.54 2.58-8.94 3.92-13.4a281.37 281.37 0 006.39-27.88 98.33 98.33 0 001.8-11.82c.23-3.3.12-6.62.44-9.9a76.76 76.76 0 012.66-12.62c1.22-4.51 2.43-9 3.65-13.5 1.8-6.66 3.73-13.57 8.29-18.74a17.61 17.61 0 0112.66-6.05 12.11 12.11 0 012.69.29l.44.11a10.6 10.6 0 012.75 1.14c1.41-.9 2.12-.58 3.06.79a8.87 8.87 0 011.11 4.8 66.53 66.53 0 01-1.08 13.08z"
      fill="#fbbebe"
    />
    <path
      d="M471.53 110.06l-.06.06-.32.32-3.19 3.19-1.57-6.58-5.37-22.54a12.11 12.11 0 012.69.29 4 4 0 01.19-.76l.25.87 1.82 6.19 3.45 11.75.57 1.94z"
      fill={color}
    />
    <rect x="450.58" y="22.25" width="50.86" height="31.79" rx="12" fill="#3f3d56" />
    <circle cx="504.41" cy="23.05" r="16.29" fill="#3f3d56" />
    <path
      d="M504.02 6.75h-.6a16.29 16.29 0 010 32.55h.6a16.29 16.29 0 000-32.58zM431.8 6.75h.6a16.29 16.29 0 000 32.55h-.6a16.29 16.29 0 110-32.58zM501.94 50.85h-41.28a4.77 4.77 0 01-4.77-4.76v3.17a4.78 4.78 0 004.77 4.77h41.32a4.78 4.78 0 004.77-4.77v-3.17a4.78 4.78 0 01-4.81 4.76z"
      opacity=".1"
    />
    <path
      d="M641.98 217.6s2.58.45 3-1.64c.21-1-.5-7.42-1.31-13.84-.39-3.16-.81-6.31-1.15-8.83-.41-3.09-.71-5.22-.71-5.22l-.47-1.56-2.78-9.34-2.62-8.8-1.93-3.46-13.89 6.76-8.64 14.93 2.68 1.69.47.3 4.93 5.16 13.82 14.5 6.22 6.53.73.76z"
      fill="#ffe09c"
    />
    <path
      d="M631.06 158.97s-15.22 7.14-18.88 14.9c0 0-3.42 6.53-3.85 10 0 0-1 3.17 5.31 3.95 0 0 2.87-11.65 11.48-16.71l8.62-5.05z"
      fill="#e7c4c3"
    />
    <ellipse cx="707.08" cy="338.78" rx="5.17" ry="4.38" transform="rotate(-75 560.875 308.357)" fill="#ff7f8e" />
    <ellipse cx="708.49" cy="355.13" rx="5.17" ry="4.38" transform="rotate(-75.08 563.89 326.13)" fill="#ff7f8e" />
    <path
      d="M619.56 193.75a5.42 5.42 0 003.23-3.75c.74-2.76-.56-5.51-2.9-6.13s-4.83 1.11-5.56 3.87a3.9 3.9 0 00-.13.57l.47.3zM638.15 187.08a4 4 0 003.23-.57l-2.78-9.34a5.38 5.38 0 00-3.36 3.79c-.73 2.79.57 5.5 2.91 6.12zM639.76 203.24a4.22 4.22 0 003.95-1.12c-.39-3.16-.81-6.31-1.15-8.83h-.13c-2.34-.63-4.83 1.11-5.57 3.87s.57 5.46 2.9 6.08zM639.6 214.75a6.12 6.12 0 00.65-1.55c.74-2.76-.56-5.5-2.9-6.12a4.22 4.22 0 00-4 1.14z"
      fill="#ff7f8e"
    />
    <path
      d="M632.73 172.93l1.6.43a9.16 9.16 0 00-1.09 3.75 2.46 2.46 0 000 .26c.21 1.65-1.6-.43-1.6-.43s.31-3.39 1.09-4.01zM625.44 183.25l1.61.43a8.93 8.93 0 00-1.09 3.74 2.28 2.28 0 000 .26c.21 1.65-1.6-.43-1.6-.43s.3-3.39 1.08-4zM630.42 186.66l1.6.42a9.16 9.16 0 00-1.09 3.75 2.28 2.28 0 000 .26c.22 1.65-1.6-.43-1.6-.43s.33-3.39 1.09-4zM636.82 195.27l1.61.42a9 9 0 00-1.09 3.75 2.28 2.28 0 000 .26c.21 1.65-1.6-.43-1.6-.43s.31-3.39 1.08-4zM640.81 209.44l1.6.43a9.15 9.15 0 00-1.09 3.74 2.11 2.11 0 000 .26c.22 1.65-1.6-.42-1.6-.42s.31-3.4 1.09-4.01zM632.13 199.53l1.6.43a9 9 0 00-1.07 3.79 2.11 2.11 0 000 .26c.21 1.65-1.6-.42-1.6-.42s.3-3.45 1.07-4.06z"
      fill="#94cc79"
    />
    <path
      d="M636.01 160.29s-2.29-5.19-5-1.32a18.36 18.36 0 003 8.8s8.29 22.55 7.6 26c0 0 3.27 21.78 2.46 23 0 0 .9 1.54 1.54-4.25s-2.17-22.8-2.17-22.8l-4.11-17.68-2.75-6z"
      fill="#e7c4c3"
    />
    <path
      d="M636.01 160.29s-2.29-5.19-5-1.32a18.36 18.36 0 003 8.8s8.29 22.55 7.6 26c0 0 3.27 21.78 2.46 23 0 0 .9 1.54 1.54-4.25s-2.17-22.8-2.17-22.8l-4.11-17.68-2.75-6z"
      fill="#fff"
      opacity=".2"
    />
    <path d="M608.51 168.89s4.11 11.84 7.87 8.69l-3-15.47z" fill="#fbbebe" />
    <path
      d="M327.41 217.52s36.25 61.07 44.72 69.23c0 0 36.22 37.6 17.81 35-1.35-.19-27.86-22.6-29.41-26.28s-51.67-68.79-51.67-68.79l7.15-6.58z"
      fill="#a0616a"
    />
    <path
      d="M327.41 217.52s36.25 61.07 44.72 69.23c0 0 36.22 37.6 17.81 35-1.35-.19-27.86-22.6-29.41-26.28s-51.67-68.79-51.67-68.79l7.15-6.58z"
      opacity=".1"
    />
    <path
      d="M360.56 416.97s-11.6 14.5-6.76 20.3 28.95 16.89 36.73 16.43c8.22-.48 35.77 1 36.74-11.6 0 0 1.93-5.8-23.2-6.77s-17.41-27.07-17.41-27.07z"
      fill="#2f2e41"
    />
    <path
      d="M222.32 294.19s-11.6 39.63 23.2 46.4l37.7-1.93s53.17-10.64 67.68-2.9c0 0 5.8 1 3.86 8.7s2.9 59.94 2.9 59.94v17.4s32.87 0 36.74-3.87c0 0-2.9-15.47 0-28s-4.83-52.21-4.83-52.21l-.77-23.81a16.62 16.62 0 00-8.77-14.32 13 13 0 00-5.93-1.51s-65.74-10.63-88.94-8.7-62.84 4.81-62.84 4.81z"
      fill="#605d82"
    />
    <path
      d="M287.09 167.54s17.4 20.3 19.33 27.07 23.24 24.14 23.24 24.14-15.47 11.6-12.57 17.4-20.3-19.33-20.3-19.33l-27.13-39.61z"
      fill={color}
    />
    <path
      d="M287.09 167.54s17.4 20.3 19.33 27.07 23.24 24.14 23.24 24.14-15.47 11.6-12.57 17.4-20.3-19.33-20.3-19.33l-27.13-39.61z"
      opacity=".1"
    />
    <path
      d="M298.66 144.34s-8.7 28-8.7 38.67-36.74-2.9-35.77-8.7 21.27-27.07 22.24-35.77 22.23 5.8 22.23 5.8z"
      fill="#a0616a"
    />
    <path
      d="M269.66 163.67s-33.84-8.7-38.67 40.61 3.86 55.1-10.64 85.07 98.61 3.87 98.61 3.87-5.8-6.77-24.17-3.87l-8.7-26.1s-2.9-11.6 13.54-11.6 8.7-33.84 8.7-33.84-8.7-29-13.54-31.9-13.5-20.3-25.13-22.24z"
      opacity=".1"
    />
    <path
      d="M269.66 161.75s-33.84-8.7-38.67 40.6 3.86 55.11-10.64 85.08 98.61 3.87 98.61 3.87-5.8-6.77-24.17-3.87l-8.7-26.1s-2.87-11.58 13.57-11.58 8.7-33.84 8.7-33.84-8.7-29-13.54-31.9-13.53-20.34-25.16-22.26z"
      fill={color}
    />
    <circle cx="289.99" cy="129.84" r="31.9" fill="#a0616a" />
    <path fill="#dadae5" d="M283.83 293.18L364 341.1l74.59-69.5-88.92-30.97-63.99 43.56-1.85 8.99z" />
    <path fill="none" d="M371.67 152.85l-24.57 75.42 89.11 28.76 37.45-106.42-101.99 2.24z" />
    <path fill="#dadae5" d="M378.44 159.04l-24.95 74.2 82.72 23.79 35.31-100.34-93.08 2.35z" />
    <path opacity=".03" d="M371.78 153.56l6.77 6.19 93.08-2.35 2.14-6.08-101.99 2.24z" />
    <path opacity=".03" d="M347.21 228.97l24.57-75.41 6.77 6.19-24.32 72.34-7.02-3.12z" />
    <path
      fill="#eff0f4"
      d="M371.67 152.85l6.77 6.19 93.08-2.35 2.14-6.08-101.99 2.24zM347.1 228.27l24.57-75.42 6.77 6.19-24.32 72.34-7.02-3.11z"
    />
    <path
      opacity=".03"
      d="M371.67 152.85l10.35 9.68 93.09-2.35-1.45-9.57-101.99 2.24zM347.1 228.27l24.57-75.42 10.35 9.68-24.32 72.34-10.6-6.6z"
    />
    <path opacity=".03" d="M347.26 229.33l2.26 14.35 89.47 31.06-2.61-16.65-89.12-28.76z" />
    <path opacity=".03" d="M281.12 276.49l66.14-47.16 2.89 14.34-64.97 48.32-4.06-15.5z" />
    <path fill="#eff0f4" d="M347.1 228.27l1.94 12.37 89.47 31.06-2.3-14.67-89.11-28.76z" />
    <path fill="#eff0f4" d="M280.95 275.42l66.15-47.15 2.57 12.36-64.97 48.32-3.75-13.53z" />
    <path opacity=".03" d="M347.1 228.27l1.94 12.37 89.47 31.06-2.3-14.67-89.11-28.76z" />
    <path opacity=".03" d="M280.95 275.42l66.15-47.15 2.57 12.36-64.97 48.32-3.75-13.53z" />
    <path fill="#cccad6" d="M280.95 275.42l2.99 17.83 79.95 47.77-1.97-18.35-80.97-47.25z" />
    <path
      d="M317.38 272.6s-1.39 2.65.73 3.87c1 .58 8.23 2 15.49 3.36 3.56.66 7.13 1.3 10 1.8 3.5.62 5.92 1 5.92 1h1.84l11.12.21 10.46.2 4.43-.91-2.55-17.38-13.24-14.55-2.77 2.34-.47.4-7.28 3.54-20.54 10-9.24 4.5-1.08.53z"
      fill="#ffe09c"
    />
    <path
      d="M384.85 280.99s-2.49-19-9.67-25.66c0 0-5.9-6-9.53-7.64 0 0-3.09-2.19-6.13 4.41 0 0 11.67 7.14 14.19 18.25l2.51 11.11z"
      fill="#e7c4c3"
    />
    <ellipse cx="445.17" cy="432.23" rx="5" ry="5.9" transform="rotate(-57.43 258.381 424.033)" fill="#ff7f8e" />
    <ellipse cx="426.93" cy="428.11" rx="5" ry="5.9" transform="rotate(-57.43 240.135 419.91)" fill="#ff7f8e" />
    <path
      d="M351.06 256.49a6.15 6.15 0 003 4.81c2.74 1.75 6.17 1.29 7.65-1s.47-5.63-2.28-7.38a6.65 6.65 0 00-.58-.33l-.47.4zM351.86 278.98a4.6 4.6 0 00-.51 3.71l11.12.21a6.1 6.1 0 00-3-5c-2.7-1.71-6.13-1.24-7.61 1.08zM333.74 275.15a4.78 4.78 0 00-.14 4.68c3.56.66 7.13 1.3 10 1.8l.09-.13c1.48-2.32.46-5.63-2.28-7.38s-6.18-1.29-7.67 1.03zM321.28 271a7.09 7.09 0 001.46 1.24c2.74 1.75 6.17 1.29 7.65-1a4.77 4.77 0 00.13-4.7z"
      fill="#ff7f8e"
    />
    <path
      d="M369.1 277.98l-1 1.59a10.31 10.31 0 00-3.69-2.47l-.29-.07c-1.87-.34 1-1.59 1-1.59s3.59 1.49 3.98 2.54zM360.41 266.5l-1 1.6a10.29 10.29 0 00-3.69-2.48l-.29-.07c-1.86-.33 1-1.59 1-1.59s3.59 1.49 3.98 2.54zM354.99 270.75l-1 1.59a10.31 10.31 0 00-3.69-2.47l-.29-.07c-1.87-.34 1-1.59 1-1.59s3.58 1.47 3.98 2.54zM343.42 274.75l-1 1.6a10.29 10.29 0 00-3.69-2.48l-.29-.07c-1.87-.33 1-1.59 1-1.59s3.58 1.45 3.98 2.54zM326.66 274.15l-1 1.6a10.36 10.36 0 00-3.7-2.48l-.29-.07c-1.86-.33 1-1.59 1-1.59s3.58 1.48 3.99 2.54zM340.41 268.15l-1 1.59a10.29 10.29 0 00-3.69-2.48l-.29-.07c-1.87-.33 1-1.59 1-1.59s3.58 1.49 3.98 2.55z"
      fill="#94cc79"
    />
    <path
      d="M381.66 285.92s6.43-.7 3.15-4.93a20.86 20.86 0 00-10.58.18s-27.37 1.22-30.92-.74c0 0-24.8-4-25.8-5.26 0 0-2 .44 4.08 3.14s25.53 5.52 25.53 5.52l20.63 1.64 7.42-.92z"
      fill="#e7c4c3"
    />
    <path
      d="M381.66 285.92s6.43-.7 3.15-4.93a20.86 20.86 0 00-10.58.18s-27.37 1.22-30.92-.74c0 0-24.8-4-25.8-5.26 0 0-2 .44 4.08 3.14s25.53 5.52 25.53 5.52l20.63 1.64 7.42-.92z"
      fill="#fff"
      opacity=".2"
    />
    <path
      d="M362.16 276.11a6.16 6.16 0 00.93 5.57c1.88 2.65 5.23 3.51 7.49 1.92s2.55-5 .66-7.7c-.13-.18-.27-.36-.41-.53l-.6.2z"
      fill="#ff7f8e"
    />
    <path
      fill="#cccad6"
      d="M361.92 322.67l74.29-65.64 2.28 14.49-74.6 69.5-1.97-18.35zM445.85 260.77l-9.64-3.74 37.45-106.42 7.14 5.04-34.95 105.12z"
    />
    <g opacity=".1" fill="#fff">
      <path d="M385.56 415.88a33.4 33.4 0 011.11-7.62l-.49.17a34 34 0 00-.62 7.45zM390.53 451.75c-7.78.46-31.9-10.64-36.73-16.44a5.07 5.07 0 01-1.12-2.74 6.06 6.06 0 001.12 4.68c4.83 5.8 28.95 16.89 36.73 16.43 8.22-.48 35.77 1 36.74-11.6a1.58 1.58 0 00-.13-1c-2.22 11.58-28.6 10.22-36.61 10.67z" />
    </g>
    <path
      d="M418.78 217.13s1.72-2 .16-3.44c-.75-.7-6.63-3.38-12.55-6-2.91-1.28-5.84-2.54-8.17-3.54l-4.85-2.07-1.57-.4-9.45-2.41-8.89-2.27-4-.11-1.34 15.39 8.39 15.08 2.82-1.44.49-.25 6.93-1.56 19.58-4.36 8.79-2 1-.23z"
      fill="#ffe09c"
    />
    <path
      d="M362.86 196.42s-1.69 16.72 3.1 23.85c0 0 3.84 6.28 6.6 8.44 0 0 2.2 2.48 6.13-2.54 0 0-8.54-8.44-8.45-18.43l.08-10z"
      fill="#e7c4c3"
    />
    <ellipse cx="456.43" cy="370.04" rx="4.38" ry="5.17" transform="rotate(-44.19 218.191 387.153)" fill="#ff7f8e" />
    <ellipse cx="471.18" cy="377.22" rx="4.38" ry="5.17" transform="rotate(-44.19 232.938 394.33)" fill="#ff7f8e" />
    <path
      d="M386.79 224.12a5.41 5.41 0 00-1.56-4.7c-2-2-5-2.34-6.75-.65s-1.52 4.71.47 6.76a5.25 5.25 0 00.42.4l.49-.25zM390.66 204.75a4 4 0 001.17-3.07l-9.45-2.41a5.36 5.36 0 001.53 4.83c1.96 2.06 4.98 2.35 6.75.65zM405.32 211.67a4.23 4.23 0 001.07-4c-2.91-1.28-5.84-2.54-8.17-3.54l-.11.09c-1.73 1.69-1.52 4.72.47 6.77s5.01 2.37 6.74.68zM415.12 217.75a5.71 5.71 0 00-1-1.35c-2-2.05-5-2.34-6.74-.65a4.18 4.18 0 00-1.06 4z"
      fill="#ff7f8e"
    />
    <path
      d="M375.66 202.15l1.19-1.15a9.11 9.11 0 002.66 2.85l.23.12c1.53.66-1.19 1.15-1.19 1.15s-2.72-1.99-2.89-2.97zM380.82 213.75l1.18-1.16a9.1 9.1 0 002.66 2.86l.23.11c1.53.67-1.19 1.16-1.19 1.16s-2.76-2.05-2.88-2.97zM386.29 211.18l1.19-1.16a9.1 9.1 0 002.66 2.86l.23.11c1.53.67-1.19 1.16-1.19 1.16s-2.76-1.99-2.89-2.97zM396.97 210.1l1.19-1.16a9.16 9.16 0 002.65 2.86l.24.12c1.52.66-1.19 1.15-1.19 1.15s-2.76-1.99-2.89-2.97zM411.18 213.96l1.19-1.16a9 9 0 002.65 2.86l.24.11c1.52.67-1.19 1.16-1.19 1.16s-2.76-2-2.89-2.97zM398.22 216.31l1.19-1.15a9.11 9.11 0 002.66 2.85 1 1 0 00.23.12c1.53.66-1.19 1.16-1.19 1.16s-2.76-2-2.89-2.98z"
      fill="#94cc79"
    />
    <path
      d="M366.54 192.84s-5.63-.69-3.68 3.58a18.4 18.4 0 009.07 2s23.61 4.46 26.25 6.84c0 0 20.37 8.38 21 9.68 0 0 1.79 0-2.85-3.51s-20.67-9.86-20.67-9.86l-17.28-5.55-6.52-.71z"
      fill="#e7c4c3"
    />
    <path
      d="M366.54 192.84s-5.63-.69-3.68 3.58a18.4 18.4 0 009.07 2s23.61 4.46 26.25 6.84c0 0 20.37 8.38 21 9.68 0 0 1.79 0-2.85-3.51s-20.67-9.86-20.67-9.86l-17.28-5.55-6.52-.71z"
      fill="#fff"
      opacity=".2"
    />
    <path
      d="M337.75 216.13a29.06 29.06 0 007.69-5.85c1-1.18 1.84-2.51 2.87-3.67a18.84 18.84 0 019.68-5.52A47.34 47.34 0 01369.2 200a20.57 20.57 0 017.46.9 7.49 7.49 0 014.94 5.31c.55 3-1.41 5.91-3.76 7.84a18.14 18.14 0 01-7.52 3.65 61.63 61.63 0 01-6.79.79c-13.38 1.4-25.32 8.68-37 15.31-10.3 5.83-16.14 5.56-26.83 10.62-.42-3.2-5.26-.72-5.19-3.95.06-2.41.18-6.09 2.46-7.51 2.65-1.65 7-2 10-3a161.11 161.11 0 0020.15-8.15q5.41-2.65 10.63-5.68z"
      fill="#a0616a"
    />
    <path
      d="M249.39 207.18l51.27 46.4s9.67-19.33 12.57-19.33-34.8-57-34.8-57-9.67-11.6-15.47-5.8-23.24 1.89-13.57 35.73z"
      opacity=".1"
    />
    <path
      d="M248.42 205.25l51.24 46.4s9.67-19.34 12.57-19.34-34.81-57-34.81-57-9.67-11.61-15.47-5.81-23.2 1.91-13.53 35.75z"
      fill={color}
    />
    <path
      d="M332.78 123.46a15.4 15.4 0 002.69-4.2c4.42-10-2.59-22.56-14.56-26-6-1.75-12.72-1.4-18.71-3.26-4.82-1.49-8.88-4.31-13.3-6.51a56.08 56.08 0 00-26.75-5.74c-9.05.32-18.89 3.5-22.6 10.49-5.42 10.18 4.55 23-1.49 32.91-4.44 7.28-15.21 9.43-24.14 12.59a65.88 65.88 0 00-24.64 15.27c-7 7-12 16.6-8.48 25.28 2.05 5.09 6.68 9.07 10.36 13.46a43 43 0 012.69 52.28c-1.72 2.47-3.73 4.88-4.44 7.68-1.7 6.68 5.16 13.4 13.09 14.7s16.16-1.66 22.33-6.03a40.15 40.15 0 0013.31-15.48c7.33-15.54 2.18-33 1.45-49.73-.09-1.95 0-4.07 1.37-5.6 2.12-2.29 6.12-2.23 9.54-2.53 8.85-.78 17-5.74 20.75-12.58 7.17-13.16-1.18-31.55 11.35-41.45 15.77-12.46 38.47 7.24 50.18-5.55z"
      fill="#3f3d56"
    />
    <path
      d="M320.91 93.22h-.1c4.68 5.42 6.44 12.74 3.66 19.06a15.4 15.4 0 01-2.69 4.2c-11.71 12.79-34.41-6.91-50.18 5.55-12.53 9.9-4.18 28.29-11.35 41.45-3.72 6.84-11.9 11.8-20.75 12.58-3.42.3-7.42.24-9.54 2.53-1.41 1.53-1.46 3.65-1.37 5.6.73 16.73 5.88 34.19-1.45 49.73a40.15 40.15 0 01-13.31 15.48c-6.17 4.35-14.39 7.35-22.33 6.05l-.55-.12a17.54 17.54 0 0011.55 7.12c7.94 1.29 16.11-1.71 22.33-6.07a40.15 40.15 0 0013.31-15.48c7.33-15.54 2.18-33 1.45-49.73-.09-1.95 0-4.07 1.37-5.6 2.12-2.29 6.12-2.23 9.54-2.53 8.85-.78 17-5.74 20.75-12.58 7.17-13.16-1.18-31.55 11.35-41.45 15.77-12.46 38.47 7.24 50.18-5.55a15.4 15.4 0 002.69-4.2c4.42-10.03-2.59-22.51-14.56-26.04z"
      opacity=".1"
    />
  </svg>
)

export default WatchingImage

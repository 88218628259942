import { useState, useEffect } from "react"
import firebase from "firebase"

import MOError, { MOErrorType } from "utils/error"

const useQuery = <T>(collectionPath: string) => {
  const [query, setQuery] = useState<firebase.firestore.QuerySnapshot | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<MOError | null>(null)

  useEffect(() => {
    setQuery(null)
    let unsubscribe: (() => void) | undefined
    if (collectionPath) {
      setLoading(true)
      unsubscribe = firebase
        .firestore()
        .collection(collectionPath)
        .onSnapshot(
          snap => {
            setLoading(false)
            setQuery(snap)
            setError(null)
          },
          err => setError(new MOError(MOErrorType.FAILED_OPERATION, err.message))
        )
    } else {
      setLoading(false)
      setError(
        new MOError(MOErrorType.DOES_NOT_MEET_EXPECTATIONS, "Please provide a valid collectionPath to listen to.")
      )
    }

    return () => unsubscribe?.()
  }, [collectionPath])

  return {
    query,
    docs: query?.docs || [],
    data: query?.docs?.map(doc => doc.data() as T) || [],
    loading,
    error,
  }
}

export default useQuery

import React, { FC, useEffect } from "react"
// import { useMatch, useNavigate } from "react-router-dom"
import { useHistory, useParams } from "react-router-dom"

import useMovie from "hooks/useMovie"
import useDebouncedState from "hooks/useDebounceState"
import useRecentlyVisitedMovies from "hooks/useRecentlyVisitedMovies"
import useTrackVisit from "hooks/useTrackVisit"

import MovieProfile from "components/MovieProfile"

const NUM_SECONDS_TO_COUNT_VISIT = 3

const MoviePage: FC = () => {
  const history = useHistory()
  const { movieId, tmdbId } = useParams<{ movieId?: string; tmdbId?: string }>()

  // const navigate = useNavigate()
  // const movieIdMatch = useMatch("/movie/:movieId")
  // const movieId = movieIdMatch?.movieId
  // const tmdbIdMatch = useMatch("/movie/tmdb/:tmdbId")
  // const tmdbIdRaw = tmdbIdMatch?.tmdbId
  const tmdbIdParsed = (tmdbId && !isNaN(parseInt(tmdbId)) && parseInt(tmdbId)) || undefined

  const { movie, error } = useMovie({ movieId, tmdbId: tmdbIdParsed })

  const { add } = useRecentlyVisitedMovies()

  useEffect(() => {
    if (!movieId && !tmdbId) {
      history.push("/movies")
    }
  }, [movieId, tmdbId])

  // NOTE: Require user being on the page for 3 seconds before adding movie to recently visited
  const [_, debouncedMovieId, setDebouncedMovieId] = useDebouncedState(
    movie?.movieId,
    1000 * NUM_SECONDS_TO_COUNT_VISIT
  )
  useEffect(() => {
    setDebouncedMovieId(movie?.movieId)
  }, [movie?.movieId])
  useEffect(() => {
    if (movie) {
      add(movie)
    }
  }, [debouncedMovieId])

  useTrackVisit("movie", { movieId: movie?.movieId, movieTitle: movie?.title }, movie?.movieId)

  return <MovieProfile movie={movie} error={error} />
}

export default MoviePage

import { useState, useEffect } from "react"
import firebase from "firebase"

import MOError, { MOErrorType } from "utils/error"

const useSnapshot = <T>(documentPath: string) => {
  const [snapshot, setSnapshot] = useState<firebase.firestore.DocumentSnapshot | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<MOError | null>(null)

  useEffect(() => {
    setSnapshot(null)
    let unsubscribe: (() => void) | undefined
    if (documentPath) {
      setLoading(true)
      unsubscribe = firebase
        .firestore()
        .doc(documentPath)
        .onSnapshot(
          snap => {
            setLoading(false)
            setSnapshot(snap)
            if (!snap.exists) {
              setError(new MOError(MOErrorType.DOCUMENT_DOES_NOT_EXISTS))
            } else {
              setError(null)
            }
          },
          err => setError(new MOError(MOErrorType.FAILED_OPERATION, err.message))
        )
    } else {
      setLoading(false)
      setError(new MOError(MOErrorType.DOES_NOT_MEET_EXPECTATIONS, "Please provide a valid documentPath to listen to."))
    }

    return () => unsubscribe?.()
  }, [documentPath])

  return {
    snapshot,
    data: snapshot?.data({ serverTimestamps: "estimate" }) as T,
    doc: snapshot?.ref,
    loading,
    error,
  }
}

export default useSnapshot

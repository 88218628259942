import React, { FC, useState, useEffect, useRef, ReactNode } from "react"
import {
  Box,
  Paper,
  makeStyles,
  Fab,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
  Container,
  Grid,
  CircularProgress,
} from "@material-ui/core"
import {
  FavoriteRounded,
  FavoriteBorderRounded,
  MoreVertRounded,
  BookmarkBorderRounded,
  BookmarkRounded,
  CloseRounded,
  CachedRounded,
  AssignmentTurnedInRounded,
  SearchRounded,
  SendRounded,
} from "@material-ui/icons"
import { ClipboardOutline } from "mdi-material-ui"
import { Skeleton } from "@material-ui/lab"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import Ratio from "react-ratio"
import Img from "react-image"
import { Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import firebase from "firebase"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import cx from "classnames"
import { useSnackbar } from "notistack"

import { useAuth } from "providers/AuthProvider"

import useFavoriteMovie from "hooks/useFavoriteMovie"
import useWatchLaterMovie from "hooks/useWatchLaterMovie"
import useWatchedMovie from "hooks/useWatchedMovie"
import useUserSearch from "hooks/useUserSearch"

import DestructiveFab from "components/DestructiveFab"
import RaisedTextField from "components/RaisedTextField"
import Person from "components/Person"
import PopupModal from "components/PopupModal"

dayjs.extend(duration)

export type MovieSource = "MovieOtter" | "TMDB"

export type MoviePosterAction = "menu" | "delete" | "result" | "none"

export interface MoviePosterProps {
  movieData?: movieotter.SimpleMovieData
  source?: MovieSource
  displayTitle?: boolean
  link?: boolean
  onClick?: (movieData: movieotter.SimpleMovieData) => void
  action?: MoviePosterAction
  onDelete?: (movieData: movieotter.SimpleMovieData) => void
}

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  paper: {
    overflow: "hidden",
    display: "flex",
    maxHeight: "100%",
    maxWidth: "100%",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  skeleton: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  rotate: {
    // Ref: https://material-ui.com/components/progress/
    animation: `RotateAnimation-keyframes-circular-rotate-counter-clockwise 1.4s linear infinite`,
  },
  fab: {
    position: "absolute",
    top: spacing(),
    right: spacing(),
  },
  tag: {
    position: "absolute",
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    borderRadius: shape.borderRadius,
    padding: spacing(0.25, 1),
  },
  releaseDate: {
    top: spacing(),
    left: spacing(),
  },
  runtime: {
    left: spacing(),
    bottom: spacing(),
  },
}))

const MoviePoster: FC<MoviePosterProps> = ({
  movieData,
  source = "MovieOtter",
  displayTitle = true,
  link = true,
  onClick,
  action = "menu",
  onDelete,
}) => {
  const classes = useStyles()
  const [to, setTo] = useState<string | null>(null)

  const [anchorEl, setAnchorEl] = useState<any>()
  const fabRef = useRef<any>()

  const { enqueueSnackbar } = useSnackbar()

  const { currentUser, openLoginModal } = useAuth()

  const [tmdbMovie, setTMDBMovie] = useState<movieotter.Movie | null>(null)

  const { exists: favorited, toggleDoc: toggleFavorite } = useFavoriteMovie(
    (tmdbMovie ?? movieData) as movieotter.SimpleMovieData
  )

  const { exists: watchLatered, toggleDoc: toggleWatchLater } = useWatchLaterMovie(
    (tmdbMovie ?? movieData) as movieotter.SimpleMovieData
  )

  const { watchedMovie, processing, logWatch } = useWatchedMovie((tmdbMovie ?? movieData) as movieotter.SimpleMovieData)

  const [recommending, setRecommending] = useState(false)

  // TODO: Refactor to better handle movie from MovieOtter or TMDB (or any source for that matter)
  useEffect(() => {
    let running = true
    if (source == "TMDB" && movieData) {
      const tmdbId = parseInt(movieData.movieId)
      if (isNaN(tmdbId)) {
        console.log("Could not parse tmdbId from provided data")
        setTMDBMovie(null)
      } else {
        firebase
          .firestore()
          .collection(movieotter.Movie.COLLECTION_NAME)
          .where("externalIds.tmdb", "==", tmdbId)
          .limit(1)
          .onSnapshot(query => {
            if (query.empty) {
              console.log("No movie exists with TMDB ID provided")
            } else {
              const data = query.docs[0].data({
                serverTimestamps: "estimate",
              }) as movieotter.MovieData<movieotter.FirebaseTimestamp>
              if (running) setTMDBMovie(movieotter.Movie.fromDocument(data))
            }
          })
      }
    } else {
      setTMDBMovie(null)
    }

    return () => {
      running = false
    }
  }, [source, movieData?.movieId])

  useEffect(() => {
    if (!movieData || onClick) {
      setTo(null)
      return
    }
    switch (source) {
      case "TMDB":
        setTo(`/movie/tmdb/${movieData.movieId}`)
        break
      default:
        setTo(`/movie/${movieData.movieId}`)
        break
    }
  }, [movieData, source, link])

  // Result Actions
  const [resultActionRunning, setResultActionRunning] = useState(false)

  const [favoritingTMDBMovie, setFavoritingTMDBMovie] = useState(false)
  const favoriteTMDBMovie = () => {
    if (!currentUser) return openLoginModal()

    if (source == "TMDB" && !tmdbMovie) {
      setResultActionRunning(true)
      setFavoritingTMDBMovie(true)
      firebase
        .functions()
        .httpsCallable("favoriteMovie")({ tmdbId: movieData?.movieId })
        .then(() => {
          console.log("Movie Favorited!")
          setResultActionRunning(false)
          setFavoritingTMDBMovie(false)
          enqueueSnackbar("Movie Favorited!", { variant: "success" })
        })
        .catch(e => {
          console.warn("FAVORITE ERROR: ", e)
        })
    } else {
      toggleFavorite()
    }
  }

  const [watchLateringTMDBMovie, setWatchLateringTMDBMovie] = useState(false)
  const watchLaterTMDBMovie = () => {
    if (!currentUser) return openLoginModal()

    if (source == "TMDB" && !tmdbMovie) {
      setResultActionRunning(true)
      setWatchLateringTMDBMovie(true)
      firebase
        .functions()
        .httpsCallable("watchLaterMovie")({ tmdbId: movieData?.movieId })
        .then(() => {
          console.log("Movie Watched Latered!")
          setResultActionRunning(false)
          setWatchLateringTMDBMovie(false)
          enqueueSnackbar("Movie Bookmarked!", { variant: "success" })
        })
        .catch(e => {
          console.warn("WATCH LATERED ERROR: ", e)
        })
    } else {
      toggleWatchLater()
    }
  }

  const [loggingTMDBMovieWatched, setLoggingTMDBMovieWatched] = useState(false)
  const logTMDBMovieWatched = () => {
    if (!currentUser) return openLoginModal()

    if (source == "TMDB" && !tmdbMovie) {
      setResultActionRunning(true)
      setLoggingTMDBMovieWatched(true)
      firebase
        .functions()
        .httpsCallable("logMovieWatched")({ tmdbId: movieData?.movieId })
        .then(() => {
          console.log("Logged Movie Watched!")
          setResultActionRunning(false)
          setLoggingTMDBMovieWatched(false)
          enqueueSnackbar("Movie Logged!", { variant: "success" })
        })
        .catch(e => {
          console.warn("MOVIE WATCHED ERROR: ", e)
        })
    } else {
      logWatch()
    }
  }

  const [reimporting, setReimporting] = useState(false)
  const reImport = async () => {
    if (!reimporting && source === "MovieOtter") {
      setReimporting(true)
      firebase.analytics().logEvent("user-reimport_movie", {
        movieId: movieData?.movieId,
        movieTitle: movieData?.title,
      })
      await firebase
        .functions()
        .httpsCallable("reimportMovie")({ movieId: movieData?.movieId })
        .catch(e => {
          console.warn("REIMPORT ERROR: ", e)
        })
      setReimporting && setReimporting(false)
    }
  }

  const [userSearchModalOpen, setUserSearchModalOpen] = useState(false)

  const recommendTo = async (userId: string) => {
    firebase.analytics().logEvent("user-recommend_movie", {
      movieId: movieData?.movieId,
      movieTitle: movieData?.title,
    })
    const recommend = firebase.functions().httpsCallable("recommendMovie")
    try {
      await recommend({
        recipientId: userId,
        movieId: movieData?.movieId,
      })
      enqueueSnackbar("Recommendation Sent!", { variant: "success" })
    } catch (e) {
      console.log("Recommend Error: ", e)
      enqueueSnackbar("Could not send recommendation...", {
        variant: "success",
      })
    }
  }

  let posterAction: ReactNode
  switch (action) {
    case "menu":
      if (source === "MovieOtter") {
        posterAction = (
          <>
            <Fab
              ref={fabRef}
              className={classes.fab}
              color="primary"
              size="small"
              onClick={() => setAnchorEl(fabRef.current)}
            >
              <MoreVertRounded />
            </Fab>
            <Popover
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuList>
                <MenuItem onClick={toggleFavorite}>
                  <ListItemIcon>
                    {favorited ? <FavoriteRounded color="primary" /> : <FavoriteBorderRounded color="primary" />}
                  </ListItemIcon>
                  <ListItemText primary={favorited ? "Remove from Favorites" : "Add to Favorites"} />
                </MenuItem>

                <MenuItem onClick={toggleWatchLater}>
                  <ListItemIcon>
                    {watchLatered ? <BookmarkRounded color="primary" /> : <BookmarkBorderRounded color="primary" />}
                  </ListItemIcon>
                  <ListItemText primary={watchLatered ? "Remove from Watch Later" : "Add to Watch Later"} />
                </MenuItem>

                <MenuItem onClick={() => logWatch()} disabled={processing}>
                  <ListItemIcon>
                    {watchedMovie ? (
                      <AssignmentTurnedInRounded color="primary" />
                    ) : (
                      <ClipboardOutline color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Log watch"
                    secondary={
                      watchedMovie ? `Last Watched ${dayjs(watchedMovie.dateTime).format("MMM D, YYYY")}` : null
                    }
                  />
                </MenuItem>

                <MenuItem onClick={() => setUserSearchModalOpen(true)} disabled={recommending}>
                  <ListItemIcon>
                    {recommending ? (
                      <CachedRounded color="primary" className={classes.rotate} />
                    ) : (
                      <SendRounded color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={recommending ? "Recommending..." : "Recommend"} />
                </MenuItem>

                {currentUser &&
                  currentUser.mvp && [
                    <Divider key="mvp-divider" />,
                    <ListSubheader key="mvp-subhead">MVP Controls</ListSubheader>,
                    <MenuItem key="mvp-reimport" onClick={reImport} disabled={reimporting}>
                      <ListItemIcon>
                        <CachedRounded color="primary" className={reimporting ? classes.rotate : undefined} />
                      </ListItemIcon>
                      <ListItemText
                        primary={reimporting ? "Reimporting..." : "Reimport Movie"}
                        secondary={
                          movieData?.lastImportDateTime
                            ? `Last Imported ${dayjs(movieData.lastImportDateTime).format("MMM D, YYYY")}`
                            : null
                        }
                      />
                    </MenuItem>,
                  ]}
              </MenuList>
              <PopupModal open={userSearchModalOpen} setOpen={setUserSearchModalOpen}>
                <UserSearch
                  onSelection={async user => {
                    setUserSearchModalOpen(false)
                    setRecommending(true)
                    await recommendTo(user.userId)
                    setRecommending(false)
                  }}
                />
              </PopupModal>
            </Popover>
          </>
        )
      }
      break
    case "result":
      if (source === "TMDB" && movieData) {
        posterAction = (
          <>
            <Fab
              ref={fabRef}
              className={classes.fab}
              color="primary"
              size="small"
              onClick={() => setAnchorEl(fabRef.current)}
            >
              <MoreVertRounded />
            </Fab>
            <Popover
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuList>
                <MenuItem onClick={favoriteTMDBMovie} disabled={resultActionRunning}>
                  <ListItemIcon>
                    {favoritingTMDBMovie ? (
                      <CachedRounded color="primary" className={classes.rotate} />
                    ) : favorited ? (
                      <FavoriteRounded color="primary" />
                    ) : (
                      <FavoriteBorderRounded color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      favoritingTMDBMovie
                        ? "Adding to favorites..."
                        : favorited
                        ? "Remove from Favorites"
                        : "Add to Favorites"
                    }
                  />
                </MenuItem>

                <MenuItem onClick={watchLaterTMDBMovie} disabled={resultActionRunning}>
                  <ListItemIcon>
                    {watchLateringTMDBMovie ? (
                      <CachedRounded color="primary" className={classes.rotate} />
                    ) : watchLatered ? (
                      <BookmarkRounded color="primary" />
                    ) : (
                      <BookmarkBorderRounded color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      watchLateringTMDBMovie
                        ? "Adding to Watch Later..."
                        : watchLatered
                        ? "Remove from Watch Later"
                        : "Add to Watch Later"
                    }
                  />
                </MenuItem>

                <MenuItem onClick={() => logTMDBMovieWatched()} disabled={resultActionRunning}>
                  <ListItemIcon>
                    {loggingTMDBMovieWatched ? (
                      <CachedRounded color="primary" className={classes.rotate} />
                    ) : watchedMovie ? (
                      <AssignmentTurnedInRounded color="primary" />
                    ) : (
                      <ClipboardOutline color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={loggingTMDBMovieWatched ? "Logging movie..." : "Log watch"}
                    secondary={
                      watchedMovie ? `Last Watched ${dayjs(watchedMovie.dateTime).format("MMM D, YYYY")}` : null
                    }
                  />
                </MenuItem>
              </MenuList>
            </Popover>
          </>
        )
      }
      break
    case "delete":
      posterAction = (
        <DestructiveFab
          ref={fabRef}
          className={classes.fab}
          color="secondary"
          size="small"
          onClick={() => movieData && onDelete?.(movieData)}
        >
          <CloseRounded />
        </DestructiveFab>
      )
      break
    default:
      posterAction = null
  }

  return (
    <Box position="relative">
      <Ratio ratio={2 / 3}>
        <Img
          className={classes.image}
          onClick={() => movieData && onClick?.(movieData)}
          src={movieData?.posterURL}
          container={children =>
            link && to ? (
              <Link to={to}>
                <Paper className={classes.paper}>{children}</Paper>
              </Link>
            ) : (
              <Paper className={classes.paper}>{children}</Paper>
            )
          }
          loader={<Skeleton className={classes.skeleton} variant="rect" width="100%" height="100%" />}
          loaderContainer={children => <>{children}</>}
          unloader={<Skeleton className={classes.skeleton} variant="rect" width="100%" height="100%" />}
          unloaderContainer={children => <>{children}</>}
        />
        {movieData?.releaseDate && (
          <Typography className={cx(classes.tag, classes.releaseDate)}>
            {dayjs(movieData.releaseDate).format("YYYY")}
          </Typography>
        )}
        {Boolean(movieData?.runtime) && (
          <Typography className={cx(classes.tag, classes.runtime)}>
            {dayjs.duration(movieData?.runtime ?? undefined, "m").hours()}h{" "}
            {dayjs.duration(movieData?.runtime ?? undefined, "m").minutes()}m
          </Typography>
        )}
      </Ratio>
      {displayTitle ? (
        movieData ? (
          <Typography>{link && to ? <Link to={to}>{movieData.title}</Link> : movieData.title}</Typography>
        ) : (
          <Skeleton className={classes.skeleton} variant="text" width="75%" />
        )
      ) : null}
      {movieData && posterAction}
    </Box>
  )
}

export default MoviePoster

const useUserSearchStyles = makeStyles(({ spacing, breakpoints }) => ({
  person: {
    height: spacing(8),
    width: spacing(8),

    [breakpoints.up("sm")]: {
      height: spacing(10),
      width: spacing(10),
    },
    [breakpoints.up("md")]: {
      height: spacing(12),
      width: spacing(12),
    },
    [breakpoints.up("lg")]: {
      height: spacing(15),
      width: spacing(15),
    },
    [breakpoints.up("xl")]: {
      height: spacing(20),
      width: spacing(20),
    },
  },
}))

const UserSearch: FC<{
  onSelection: (user: movieotter.SimpleUserData) => void
}> = ({ onSelection }) => {
  const classes = useUserSearchStyles()
  const { query, setQuery, loading, users } = useUserSearch()

  return (
    <Box py={2}>
      <Container>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={10} md={6}>
            <RaisedTextField
              placeholder="Search Users..."
              trailing={loading ? <CircularProgress size={16} /> : <SearchRounded color="disabled" />}
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </Grid>
        </Grid>
      </Container>
      <Box pt={4}>
        <Container>
          <Grid container spacing={2}>
            {users.map(user => (
              <Grid key={user.userId} item xs={4} sm={3} md={2}>
                <Person
                  key={user.userId}
                  onClick={() => onSelection(user)}
                  className={classes.person}
                  primary={user.displayName}
                  secondary={`@${user.username}`}
                  src={user.photoURL}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

import React, { FC, ReactNode } from "react"
import { Box, Typography, Button, makeStyles } from "@material-ui/core"
import { TypographyProps } from "@material-ui/core/Typography"
import { Link } from "react-router-dom"

export interface HeadingProps extends TypographyProps {
  heading?: ReactNode
  to?: string
  onClick?: () => void
  onClickLabel?: ReactNode
  onClickDisabled?: boolean
  onClickVariant?: "text" | "outlined" | "contained"
}

const useStyles = makeStyles({
  typography: {
    flexGrow: 1,
  },
})

const Heading: FC<HeadingProps> = ({
  className,
  children,
  heading,
  to,
  onClick,
  onClickLabel,
  onClickDisabled = false,
  onClickVariant = "contained",
  variant = "h6",
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Box className={className} display="flex" justifyContent="space-between">
      {to ? (
        <Link to={to}>
          <Typography className={classes.typography} variant={variant} gutterBottom {...rest}>
            {heading || children}
          </Typography>
        </Link>
      ) : (
        <Typography className={classes.typography} variant={variant} gutterBottom {...rest}>
          {heading || children}
        </Typography>
      )}
      {onClickLabel && (
        <Box>
          <Button
            color="primary"
            size="small"
            variant={onClickVariant}
            onClick={() => onClick?.()}
            disabled={onClickDisabled}
          >
            {onClickLabel}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default Heading

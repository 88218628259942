import React, { FC } from "react"
import { Box, Paper, Modal, Container, ClickAwayListener, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(({ palette }) => ({
  contentContainer: {
    outline: 0,
  },
  paper: {
    height: "75vh",
    backgroundColor: palette.background.default,
  },
}))

const PopupModal: FC<{
  open: boolean
  setOpen: (newOpen: boolean) => void
}> = ({ open, setOpen, children }) => {
  const classes = useStyles()
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        className={classes.contentContainer}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Container>
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false)
            }}
          >
            <Paper className={classes.paper}>{children}</Paper>
          </ClickAwayListener>
        </Container>
      </Box>
    </Modal>
  )
}

export default PopupModal

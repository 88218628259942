import React, { FC } from "react"
import { Button, makeStyles } from "@material-ui/core"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import useFollow from "hooks/useFollow"

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(),
  },
}))

interface FollowActionsProps {
  user: movieotter.User
}

const FollowActions: FC<FollowActionsProps> = ({ user }) => {
  const { following, follow, unfollow, error } = useFollow(user)
  const classes = useStyles()

  if (error) return null

  if (following) {
    return (
      <Button className={classes.root} color="primary" variant="outlined" fullWidth onClick={() => unfollow()}>
        Unfollow
      </Button>
    )
  }

  return (
    <Button className={classes.root} color="primary" variant="contained" fullWidth onClick={() => follow()}>
      Follow
    </Button>
  )
}

export default FollowActions

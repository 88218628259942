import React, { FC } from "react"

import { UserProvider } from "providers/UserProvider"
import { useAuth } from "providers/AuthProvider"

import useMovieOtterUser from "hooks/useMovieOtterUser"

import SEO from "components/SEO"
import UserProfile from "components/UserProfile"

const SelfPage: FC = () => {
  const { currentUser } = useAuth()
  const userContextProps = useMovieOtterUser({ userId: currentUser?.userId })

  return (
    <>
      <SEO title={"Your Profile"} />
      <UserProvider {...userContextProps}>
        <UserProfile />
      </UserProvider>
    </>
  )
}

export default SelfPage

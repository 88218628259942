import { useState, useEffect } from "react"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import MOError, { MOErrorType } from "utils/error"

const useUsername = (username: string) => {
  const [userId, setUserId] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<MOError>()

  const usernameError = (u?: string) => {
    if (!u?.length || u.length > movieotter.User.USERNAME_MAX_LENGTH)
      return new MOError(
        MOErrorType.DOES_NOT_MEET_EXPECTATIONS,
        `Usernames must be between 1 and ${movieotter.User.USERNAME_MAX_LENGTH} characters`
      )
    return undefined
  }

  useEffect(() => {
    let unsubscribe: (() => void) | undefined

    if (username) {
      setLoading(true)
      unsubscribe = firebase
        .firestore()
        .doc(movieotter.User.documentPathForUsername(username))
        .onSnapshot(snapshot => {
          setLoading(false)
          if (snapshot.exists) {
            setUserId(snapshot.data()!.userId)
          } else {
            setUserId(undefined)
          }
        })
    } else {
      setLoading(false)
      setUserId(undefined)
    }

    return () => unsubscribe?.()
  }, [username])

  useEffect(() => setError(usernameError(username)), [username])

  const available = username && !loading && !userId
  return { loading, userId, username, error, available }
}

export default useUsername

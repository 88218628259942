import React, { FC } from "react"

interface AwardsImageProps {
  color?: string
}

const AwardsImage: FC<AwardsImageProps> = ({ color = "#6c5c43" }) => (
  <svg viewBox="0 0 902.567 748.955" style={{ maxHeight: 175 }}>
    <defs>
      <linearGradient id="linear-gradient" x1="0.505" y1="1.007" x2="0.505" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="gray" stopOpacity="0.251" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.122" />
        <stop offset="1" stopColor="gray" stopOpacity="0.102" />
      </linearGradient>
    </defs>
    <g id="Group_22" data-name="Group 22" transform="translate(-282 -122.601)">
      <g id="Group_2" data-name="Group 2" transform="translate(556.248 122.601)">
        <rect
          id="Rectangle_76"
          data-name="Rectangle 76"
          width="10.994"
          height="90.604"
          rx="2.29"
          transform="translate(361.847 169.558)"
          fill="#3f3d56"
        />
        <rect
          id="Rectangle_80"
          data-name="Rectangle 80"
          width="367.778"
          height="747.455"
          rx="38.99"
          transform="translate(0)"
          fill="#3f3d56"
        />
        <path
          id="Path_176"
          data-name="Path 176"
          d="M757.9,199H715.712v5.075a24.047,24.047,0,0,1-24.06,24.047H557.483a24.047,24.047,0,0,1-24.06-24.047V199h-39.5a29.209,29.209,0,0,0-29.209,29.209v650.15a29.209,29.209,0,0,0,29.209,29.209H757.9a29.209,29.209,0,0,0,29.209-29.209v-650.1A29.209,29.209,0,0,0,757.9,199Z"
          transform="translate(-441.311 -179.531)"
          fill="#fff"
        />
        <rect
          id="Rectangle_81"
          data-name="Rectangle 81"
          width="51.357"
          height="10.423"
          rx="3.87"
          transform="translate(148.414 22.322)"
          fill="#e6e8ec"
        />
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="5.919"
          cy="5.919"
          r="5.919"
          transform="translate(207.539 21.615)"
          fill="#e6e8ec"
        />
        <circle
          id="Ellipse_11"
          data-name="Ellipse 11"
          cx="69.573"
          cy="69.573"
          r="69.573"
          transform="translate(116.562 313.331)"
          fill="#fff"
        />
        <path
          id="Path_222"
          data-name="Path 222"
          d="M706.184,502.6A92.987,92.987,0,1,1,613.2,409.61,92.987,92.987,0,0,1,706.184,502.6ZM602.451,551.82l68.977-68.977a5.993,5.993,0,0,0,0-8.487l-8.487-8.425a5.981,5.981,0,0,0-8.475,0l-56.258,56.259-26.268-26.33a6.018,6.018,0,0,0-8.487,0l-8.487,8.537a6.018,6.018,0,0,0,0,8.487l39,38.986a5.993,5.993,0,0,0,8.487,0Z"
          transform="translate(-428.541 -129.411)"
          fill={color}
        />
      </g>
      <g id="Group_1" data-name="Group 1" transform="translate(312.096 220.737)">
        <rect
          id="Rectangle_77"
          data-name="Rectangle 77"
          width="5.543"
          height="26.813"
          rx="2.29"
          transform="translate(252.414 49.221)"
          fill="#3f3d56"
        />
        <rect
          id="Rectangle_78"
          data-name="Rectangle 78"
          width="6.246"
          height="46.666"
          rx="2.29"
          transform="translate(252.213 98.318)"
          fill="#3f3d56"
        />
        <rect
          id="Rectangle_79"
          data-name="Rectangle 79"
          width="5.945"
          height="47.067"
          rx="2.29"
          transform="translate(252.313 161.313)"
          fill="#3f3d56"
        />
        <path
          id="Path_190"
          data-name="Path 190"
          d="M377.46,474.88a1.026,1.026,0,0,0,1.216.156,4.305,4.305,0,0,1-1.216-.156Z"
          transform="translate(-198.815 -189.106)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_192"
          data-name="Path 192"
          d="M425.255,491l-1.2,6.536-.926,5-1.684,9.146-16.329,11.432s-1.472-1.26-3.9-3.246c-6.692-5.51-20.757-16.73-31.085-22.753a34.786,34.786,0,0,0-8.544-3.937Z"
          transform="translate(-200.647 -187.247)"
          fill="#feb9b9"
        />
        <path
          id="Path_194"
          data-name="Path 194"
          d="M414.642,497.22s19.039,3.268,20.154-4.863-9.8-12.525-19.05-13.072-64.768,3.268-64.768,3.268-9.793,13.061,25.575,15.247Z"
          transform="translate(-202.013 -188.604)"
          fill="#3f3d56"
        />
        <rect
          id="Rectangle_82"
          data-name="Rectangle 82"
          width="99.6"
          height="118.104"
          transform="translate(154.665 40.309)"
          fill="#2f2e41"
        />
        <rect
          id="Rectangle_83"
          data-name="Rectangle 83"
          width="99.6"
          height="118.104"
          transform="translate(154.665 40.845)"
          opacity="0.1"
        />
        <path
          id="Path_198"
          data-name="Path 198"
          d="M255.223,437.085c-1.64.413-3.736,2-6.067,4.294-10.038,9.938-24.415,33.26-24.415,33.26C206.36,452.744,226.7,435.445,235.37,429.5c2.108-1.439,3.524-2.231,3.524-2.231Z"
          transform="translate(-217.289 -194.598)"
          fill="#feb9b9"
        />
        <path
          id="Path_199"
          data-name="Path 199"
          d="M423.149,288.22s4.9,34.286,21.771,42.45-21.771,23.422-21.771,23.422L370.36,334.484s29.936-9.8,23.946-38.647Z"
          transform="translate(-199.634 -210.636)"
          fill="#feb9b9"
        />
        <path
          id="Path_200"
          data-name="Path 200"
          d="M373.53,473.16l-1.194,6.514-.97,5.019-1.684,9.146L353.353,505.26s-1.472-1.249-3.9-3.234c-6.692-5.51-20.756-16.73-31.1-22.753a34.572,34.572,0,0,0-8.544-3.937Z"
          transform="translate(-206.618 -189.305)"
          fill="#feb9b9"
        />
        <path
          id="Path_202"
          data-name="Path 202"
          d="M360.794,482.55s17.087.121,18.115-8.033-9.312-12.525-18.115-13.072-62.289,3.522-62.289,3.522-7.588,15.4,26.055,17.582Z"
          transform="translate(-208.007 -190.662)"
          fill="#2f2e41"
        />
        <path
          id="Path_204"
          data-name="Path 204"
          d="M475.315,610.635c-.747-.368-1.495-.747-2.231-1.115l-2.008-2.532-26.333-33.393v.58c-.178,5.577-2.074,49.767-16.864,53.849-15.782,4.35-34.821,5.443-43.5-23.946s-24.537-93.611-40.822-97.425l24.225-19.864s16.039,18.76,22.574,36.717c-.245-1.115-11.321-52.867-2.231-62.024H467.6s12.269,34.944,20.678,52.254c6.547,13.44,16.373,45.216,22.7,66.72C517.241,601.69,495.235,620.439,475.315,610.635Z"
          transform="translate(-202.725 -190.652)"
          fill="#3f3d56"
        />
        <path
          id="Path_205"
          data-name="Path 205"
          d="M468.826,478.11c-12.269,7.261-66.129,4.305-83.651,3.123l-4.774-.346c-.513,0,.078-.29.078-.29l.491-4.941,13.061-33.193,69.932-40.554c-1.417,4.272-1.885,10.808-1.773,18.292C462.513,444.248,468.826,478.11,468.826,478.11Z"
          transform="translate(-198.5 -197.523)"
          opacity="0.1"
        />
        <path
          id="Path_206"
          data-name="Path 206"
          d="M468.886,476.637c-12.269,7.261-66.129,4.305-83.651,3.123l-4.774-.335-.792-.067.87-.223,4.305-1.115,9.246-37.007,69.932-40.543c-1.416,4.272-1.885,10.808-1.773,18.292C462.573,442.786,468.886,476.637,468.886,476.637Z"
          transform="translate(-198.56 -197.689)"
          fill="#efefef"
        />
        <path
          id="Path_207"
          data-name="Path 207"
          d="M462.128,418.739c-14.221,3.279-45.517,12.9-57.541,36.137-8.019,15.47-14.767,22.106-19.474,24.883l-4.774-.335a2.749,2.749,0,0,0,.078-.29l4.305-1.115,9.246-37.007L463.9,400.47C462.5,404.719,462.016,411.255,462.128,418.739Z"
          transform="translate(-198.483 -197.689)"
          opacity="0.1"
        />
        <path
          id="Path_208"
          data-name="Path 208"
          d="M237.025,427.29l16.329,9.793c-1.64.413-3.736,2-6.067,4.294-1.361-3.971-9.9-9.514-13.786-11.878C235.608,428.082,237.025,427.29,237.025,427.29Z"
          transform="translate(-215.42 -194.595)"
          opacity="0.1"
        />
        <path
          id="Path_209"
          data-name="Path 209"
          d="M377.845,471.846a.97.97,0,0,1-1.115-.156,3.87,3.87,0,0,0,1.115.156Z"
          transform="translate(-198.899 -189.474)"
          opacity="0.1"
        />
        <path
          id="Path_210"
          data-name="Path 210"
          d="M431.352,332.938s-14.7,1.628-25.039-9.257c0,0-43.5,12.525-58.232,40.821S233.2,448.32,233.2,448.32s17.422,9.793,15.782,14.689l65.861-38.1s23.422-16.864,29.925-20.678,42.461-27.214,50.079,36.461,0,56.057,0,56.057,10.886,3.815,26.668-26.668,64.757-37.554,64.757-37.554l2.721-32.1s9.715-21.281,2.231-30.114a18.961,18.961,0,0,1-4.361-9.213c-1.238-8.153,0-21.738,17.332-33.025,26.668-17.422,37.007-31.564,37.007-31.564s.546-10.35-15.782-15.247S442.695,249.7,442.695,249.7l-2.231,9.257,52.388,36.974s-26.121,21.191-46.844,20.678C446.007,316.609,449.855,332.391,431.352,332.938Z"
          transform="translate(-215.454 -215.079)"
          fill={color}
        />
        <path
          id="Path_211"
          data-name="Path 211"
          d="M423.262,302.975a106.3,106.3,0,0,1-3.011-13.295L391.408,297.3a31.621,31.621,0,0,1-.658,16.641A31.018,31.018,0,0,0,423.262,302.975Z"
          transform="translate(-197.282 -210.468)"
          opacity="0.1"
        />
        <circle
          id="Ellipse_10"
          data-name="Ellipse 10"
          cx="31.018"
          cy="31.018"
          r="31.018"
          transform="translate(171.005 40.856)"
          fill="#feb9b9"
        />
        <path
          id="Path_213"
          data-name="Path 213"
          d="M428.67,507.07s17.957,35.379,12.514,46.253"
          transform="translate(-192.908 -185.394)"
          opacity="0.1"
        />
        <path
          id="Path_221"
          data-name="Path 221"
          d="M421.94,218.894c8.61.982,16.786,4.673,25.263,6.692a12.537,12.537,0,0,0,6.536.245c3.29-1.015,5.967-4.461,9.38-4.361,2.1.067,3.959,1.495,5.688,2.866a69.968,69.968,0,0,1,7.807,6.96c5.844,6.346,9.469,14.89,14.8,21.794s12.934,15,20.507,11.834C506.769,285,490.7,297.559,472.443,298.809c-7.283.5-14.745-1.015-21.794,1.115a18.884,18.884,0,0,1-5.5,1.338c-4.06,0-7.328-3.725-9.86-7.35-5.934-8.521-10.629-18.191-17.31-25.965-2.231-2.576-4.807-5.019-7.93-5.487s-5.989,1.026-8.766,2.465c-4.584,2.376-9.268,4.829-12.826,8.923s-5.8,10.138-4.328,15.615c.792,2.967,2.6,5.644,2.632,8.744.067,4.952-4.305,8.566-5.164,13.384a50.184,50.184,0,0,0-.212,6.09c-.379,6.6-4.7,12-9.2,16.161-1.762,1.606-3.7,3.346-4.216,5.833-.346,1.706,0,3.5-.223,5.231-.658,4.283-4.93,6.458-8.711,7.016a25.128,25.128,0,0,1-13.072-1.55c5.287-2.5,8.544-9.614,7.317-15.983-1.684-8.834-10.562-15.18-9.8-24.169a28,28,0,0,1,2.454-7.952,72.856,72.856,0,0,0,4.194-15.068c.669-3.982.993-8.209-.257-12.012-1.115-3.458-3.446-6.212-5.421-9.135a51.942,51.942,0,0,1-6.279-12.86,9.046,9.046,0,0,1-.6-4.261,8.79,8.79,0,0,1,2.733-4.406c4.852-5.1,10.038-10.395,16.541-11.856a37.232,37.232,0,0,1,9.391-.357,110.84,110.84,0,0,0,16.317-.692c4.461-.524,12-.457,15.782-3.346,3.524-2.7,5.51-6.09,9.848-7.807a29,29,0,0,1,13.763-1.573Z"
          transform="translate(-203.422 -218.659)"
          fill="#2f2e41"
        />
      </g>
      <ellipse
        id="Ellipse_9"
        data-name="Ellipse 9"
        cx="44.995"
        cy="7.609"
        rx="44.995"
        ry="7.609"
        transform="translate(1061.967 852.981)"
        fill={color}
      />
      <path
        id="Path_179"
        data-name="Path 179"
        d="M921.506,843.366a13.1,13.1,0,0,0,4.286-6.479c.559-2.562-.537-5.6-3-6.591-2.753-1.052-5.7.862-7.923,2.786s-4.789,4.129-7.71,3.715A11.727,11.727,0,0,0,910.8,825.82a4.622,4.622,0,0,0-1.018-2.238c-1.522-1.634-4.3-.94-6.121.358-5.819,4.084-7.441,12-7.475,19.1-.593-2.562-.09-5.237-.112-7.833s-.727-5.6-2.954-6.971a8.885,8.885,0,0,0-4.476-1.052c-2.618-.1-5.528.168-7.318,2.081-2.238,2.372-1.634,6.356.291,8.952s4.868,4.263,7.576,6.065a16.786,16.786,0,0,1,5.4,5.159,4.656,4.656,0,0,1,.4.929h16.349a45.945,45.945,0,0,0,10.161-7Z"
        transform="translate(204.065 4.781)"
        fill={color}
      />
      <ellipse
        id="Ellipse_9-2"
        data-name="Ellipse 9"
        cx="29.694"
        cy="5.022"
        rx="29.694"
        ry="5.022"
        transform="translate(465.518 861.513)"
        fill="#efefef"
      />
      <path
        id="Path_179-2"
        data-name="Path 179"
        d="M907.354,836.313a8.648,8.648,0,0,0,2.828-4.276,3.813,3.813,0,0,0-1.979-4.35c-1.817-.694-3.759.569-5.228,1.839s-3.161,2.725-5.088,2.452a7.739,7.739,0,0,0,2.4-7.244,3.05,3.05,0,0,0-.672-1.477c-1-1.078-2.836-.62-4.039.236-3.84,2.7-4.911,7.916-4.933,12.606-.391-1.691-.059-3.456-.074-5.169s-.48-3.692-1.95-4.6a5.864,5.864,0,0,0-2.954-.694c-1.728-.066-3.648.111-4.83,1.374-1.477,1.566-1.078,4.195.192,5.908s3.212,2.814,5,4a11.077,11.077,0,0,1,3.567,3.4,3.078,3.078,0,0,1,.266.613h10.789a30.322,30.322,0,0,0,6.705-4.623Z"
        transform="translate(-399.861 22.01)"
        fill="#efefef"
      />
      <ellipse
        id="Ellipse_12"
        data-name="Ellipse 12"
        cx="29.694"
        cy="5.022"
        rx="29.694"
        ry="5.022"
        transform="translate(297.586 861.513)"
        fill="#efefef"
      />
      <path
        id="Path_223"
        data-name="Path 223"
        d="M907.354,836.313a8.648,8.648,0,0,0,2.828-4.276,3.813,3.813,0,0,0-1.979-4.35c-1.817-.694-3.759.569-5.228,1.839s-3.161,2.725-5.088,2.452a7.739,7.739,0,0,0,2.4-7.244,3.05,3.05,0,0,0-.672-1.477c-1-1.078-2.836-.62-4.039.236-3.84,2.7-4.911,7.916-4.933,12.606-.391-1.691-.059-3.456-.074-5.169s-.48-3.692-1.95-4.6a5.864,5.864,0,0,0-2.954-.694c-1.728-.066-3.648.111-4.83,1.374-1.477,1.566-1.078,4.195.192,5.908s3.212,2.814,5,4a11.077,11.077,0,0,1,3.567,3.4,3.078,3.078,0,0,1,.266.613h10.789a30.322,30.322,0,0,0,6.705-4.623Z"
        transform="translate(-567.793 22.01)"
        fill="#efefef"
      />
      <rect
        id="Rectangle_22"
        data-name="Rectangle 22"
        width="902.567"
        height="1.821"
        transform="translate(282 868.689)"
        fill="#2f2e41"
      />
      <path
        id="Path_177"
        data-name="Path 177"
        d="M854.367,156.943s-94.221-5.685-83.12,60.337c0,0-2.238,11.671,8.37,16.964,0,0,.168-4.89,9.668-3.223a44.2,44.2,0,0,0,10.239.492,21.329,21.329,0,0,0,12.51-5.114h0s26.487-10.944,36.782-54.227c0,0,7.62-9.444,7.318-11.873l-15.9,6.792s5.438,11.47,1.119,21c0,0-.515-20.59-3.57-20.142-.615.09-8.258,3.972-8.258,3.972s9.344,19.974,2.294,34.5c0,0,2.674-24.618-5.215-33.056l-11.19,6.535s10.922,20.635,3.514,37.476c0,0,1.9-25.827-5.875-35.887l-10.138,7.911s10.261,20.332,4.006,34.3c0,0-.817-30.057-6.2-32.328,0,0-8.874,7.833-10.228,11.033,0,0,7.027,14.771,2.663,22.559,0,0-2.674-20.03-4.868-20.142,0,0-8.84,13.271-9.758,22.38,0,0,.381-13.529,7.62-23.633,0,0-8.549,1.466-13.54,7,0,0,1.376-9.377,15.722-10.205,0,0,7.307-10.071,9.254-10.675,0,0-14.256-1.2-22.906,2.641,0,0,7.609-8.851,25.525-4.823l10.015-8.18s-18.777-2.563-26.744.269c0,0,9.165-7.833,29.441-2.126l10.9-6.513s-16.013-3.447-25.547-2.238c0,0,10.071-5.427,28.747.459l7.833-3.5s-11.727-2.305-15.163-2.674-3.614-1.309-3.614-1.309a40.676,40.676,0,0,1,22.033,2.451S854.647,157.984,854.367,156.943Z"
        transform="translate(286.143 16.182)"
        fill={color}
      />
      <path
        id="Path_178"
        data-name="Path 178"
        d="M763.1,130.7s-42.522-2.563-37.554,27.27c0,0-1,5.271,3.782,7.665,0,0,.078-2.238,4.375-1.466a19.593,19.593,0,0,0,4.621.224,9.579,9.579,0,0,0,5.651-2.328h0s11.973-4.946,16.628-24.506c0,0,3.435-4.263,3.3-5.36l-7.184,3.066s2.451,5.181.526,9.489c0,0-.235-9.3-1.611-9.086-.28,0-3.738,1.8-3.738,1.8s4.23,9.03,1.041,15.588c0,0,1.208-11.123-2.361-14.939l-5.058,3s4.935,9.321,1.589,16.931c0,0,.862-11.671-2.652-16.214l-4.577,3.581s4.633,9.187,1.813,15.5c0,0-.38-13.585-2.809-14.614,0,0-4.006,3.536-4.622,4.991,0,0,3.178,6.714,1.209,10.194,0,0-1.209-9.053-2.238-9.1,0,0-3.995,6-4.409,10.071a21.842,21.842,0,0,1,3.447-10.675,12.04,12.04,0,0,0-6.121,3.167s.627-4.241,7.106-4.61c0,0,3.357-4.554,4.185-4.834,0,0-6.445-.537-10.351,1.2,0,0,3.435-3.995,11.526-2.182l4.476-3.693S734.6,139.7,731,140.946c0,0,4.14-3.536,13.305-.962l4.924-2.943s-7.24-1.555-11.548-1c0,0,4.554-2.451,12.992.2l3.525-1.578s-5.3-1.041-6.86-1.209-1.634-.593-1.634-.593a18.34,18.34,0,0,1,9.959,1.119S763.231,131.166,763.1,130.7Z"
        transform="translate(248.313 7.472)"
        fill="#3f3d56"
      />
      <path
        id="Path_224"
        data-name="Path 224"
        d="M763.1,130.7s-42.522-2.563-37.554,27.27c0,0-1,5.271,3.782,7.665,0,0,.078-2.238,4.375-1.466a19.593,19.593,0,0,0,4.621.224,9.579,9.579,0,0,0,5.651-2.328h0s11.973-4.946,16.628-24.506c0,0,3.435-4.263,3.3-5.36l-7.184,3.066s2.451,5.181.526,9.489c0,0-.235-9.3-1.611-9.086-.28,0-3.738,1.8-3.738,1.8s4.23,9.03,1.041,15.588c0,0,1.208-11.123-2.361-14.939l-5.058,3s4.935,9.321,1.589,16.931c0,0,.862-11.671-2.652-16.214l-4.577,3.581s4.633,9.187,1.813,15.5c0,0-.38-13.585-2.809-14.614,0,0-4.006,3.536-4.622,4.991,0,0,3.178,6.714,1.209,10.194,0,0-1.209-9.053-2.238-9.1,0,0-3.995,6-4.409,10.071a21.842,21.842,0,0,1,3.447-10.675,12.04,12.04,0,0,0-6.121,3.167s.627-4.241,7.106-4.61c0,0,3.357-4.554,4.185-4.834,0,0-6.445-.537-10.351,1.2,0,0,3.435-3.995,11.526-2.182l4.476-3.693S734.6,139.7,731,140.946c0,0,4.14-3.536,13.305-.962l4.924-2.943s-7.24-1.555-11.548-1c0,0,4.554-2.451,12.992.2l3.525-1.578s-5.3-1.041-6.86-1.209-1.634-.593-1.634-.593a18.34,18.34,0,0,1,9.959,1.119S763.231,131.166,763.1,130.7Z"
        transform="translate(-407.428 462.909)"
        fill="#3f3d56"
      />
    </g>
  </svg>
)

export default AwardsImage

import { useEffect, useReducer, Reducer, useState } from "react"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import { recentNotificationsByUserIdQuery } from "utils/queries"
import MOError, { MOErrorType } from "utils/error"

import { useAuth } from "providers/AuthProvider"

export interface IUseNotifications {
  userNotifications?: movieotter.UserNotifications
  error?: MOError
  loading: boolean
}

type UseNotifications =
  | { type: "ON_ERROR"; error: MOError }
  | {
      type: "ON_USER_CHANGE"
    }
  | {
      type: "ON_LOADING"
    }
  | {
      type: "ON_CHANGE"
      data: movieotter.UserNotifications
    }

export const useUserNotifications = (userId?: string) => {
  const { currentUser } = useAuth()

  const [userNotificationState, dispatch] = useReducer<Reducer<IUseNotifications, UseNotifications>>(
    (currentState, action) => {
      switch (action.type) {
        case "ON_ERROR":
          return {
            ...currentState,
            loading: false,
            error: action.error,
          }
        case "ON_USER_CHANGE":
          return {
            ...currentState,
            error: undefined,
            loading: true,
            userNotifications: undefined,
          }
        case "ON_LOADING":
          return {
            ...currentState,
            error: undefined,
            loading: true,
          }
        case "ON_CHANGE":
          return {
            ...currentState,
            loading: false,
            error: undefined,
            userNotifications: action.data,
          }
      }
    },
    {
      userNotifications: undefined,
      error: undefined,
      loading: true,
    }
  )

  const userIdToUse = userId ?? currentUser?.userId

  useEffect(() => {
    let unsubscribe: (() => void) | undefined

    if (userIdToUse) {
      dispatch({ type: "ON_USER_CHANGE" })
      unsubscribe = firebase
        .firestore()
        .doc(movieotter.UserNotifications.documentPathFor(userIdToUse))
        .onSnapshot(snapshot => {
          if (snapshot.exists) {
            const data = snapshot.data({
              serverTimestamps: "estimate",
            }) as movieotter.IUserNotifications
            const userNotification = movieotter.UserNotifications.fromDocument(data)

            dispatch({ type: "ON_CHANGE", data: userNotification })
          }
        })
    } else {
      dispatch({
        type: "ON_ERROR",
        error: new MOError(MOErrorType.REQUIRES_LOGIN, "Please provide a userId fetch notifications for."),
      })
    }

    return () => unsubscribe?.()
  }, [userIdToUse])

  return { ...userNotificationState }
}

export default useUserNotifications

import React, { FC, useState, useEffect } from "react"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import dayjs from "dayjs"
import { Container, Typography, Button, Grid, Box, List, ListSubheader } from "@material-ui/core"

import { useAuth } from "providers/AuthProvider"

import useNotifications from "hooks/useNotifications"
import useUserNotifications from "hooks/useUserNotifications"

import SEO from "components/SEO"
import PromptLoginDisplay from "components/PromptLoginDisplay"
import NotificationListItem from "components/NotificationListItem"
import Heading from "components/Heading"

const NotificationsPage: FC = () => {
  const { currentUser } = useAuth()

  return (
    <>
      <SEO title="Notifications" />
      <NotificationsPageContent user={currentUser} />
    </>
  )
}

interface NotificationsPageContentProps {
  user?: movieotter.User
}

const NotificationsPageContent: FC<NotificationsPageContentProps> = ({ user }) => {
  if (!user) {
    return <PromptLoginDisplay />
  }

  return (
    <>
      <Container maxWidth="sm">
        <Heading variant="h5" align="center">
          Notifications
        </Heading>
        <Box py={2}>
          <Heading align="center">Unread</Heading>
          <NotificationsList state={movieotter.NotificationState.UNREAD} emptyMessage="No unread notifications..." />
        </Box>
        <Box py={2}>
          <Heading align="center">Read</Heading>
          <NotificationsList state={movieotter.NotificationState.READ} />
        </Box>
      </Container>
    </>
  )
}

const NotificationsList: FC<{
  state?: movieotter.NotificationState
  emptyMessage?: string
}> = ({ state, emptyMessage = "No notifications..." }) => {
  const { userNotifications } = useUserNotifications()
  const { loading, notifications, more } = useNotifications(undefined, state)

  const [canLoadMore, setCanLoadMore] = useState(loading)

  useEffect(() => {
    if (userNotifications) {
      const read = userNotifications.total - userNotifications.unreadCount
      const numNotifications = notifications.length

      switch (state) {
        case movieotter.NotificationState.READ:
          setCanLoadMore(read < numNotifications)
          break
        case movieotter.NotificationState.UNREAD:
          setCanLoadMore(userNotifications.unreadCount < numNotifications)
          break
        default:
          setCanLoadMore(userNotifications.total < numNotifications)
          break
      }
    }
  }, [userNotifications?.total, userNotifications?.unreadCount])

  const notificationsByDay = movieotter.utils.bucket(notifications, notification =>
    dayjs(notification.createDateTime).format("MMM D, YYYY")
  )

  // TODO: display error message

  if (!loading && notifications.length === 0) {
    return <Typography align="center">{emptyMessage}</Typography>
  }

  return (
    <>
      <List>
        {Object.keys(notificationsByDay)
          .sort((a, b) => (b < a ? 1 : -1))
          .map(key => (
            <Box key={key}>
              <ListSubheader>{key}</ListSubheader>
              {notificationsByDay[key].map(notification => (
                <NotificationListItem key={notification.notificationId} notification={notification} />
              ))}
            </Box>
          ))}
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {canLoadMore ? (
            <Button onClick={more} disabled={loading} variant="contained" color="primary" fullWidth>
              {loading ? "Loading..." : "Load More"}
            </Button>
          ) : (
            <Typography align="center">All caught up!</Typography>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default NotificationsPage

import * as movieotter from "@isaiahbydayah/movieotter-core"
import firebase from "firebase"

export interface UserListsByUpdatedAtQueryVariables {
  userId: string
  count?: number
}
export const userListsByUpdatedAtQuery = ({ userId, count = 3 }: UserListsByUpdatedAtQueryVariables) =>
  firebase
    .firestore()
    .collection(movieotter.MovieList.collectionPath())
    .where("creatorId", "==", userId)
    .orderBy("updatedAt", "desc")
    .limit(count)

export interface RecentlyWatchedMoviesByUserIdQueryVariables {
  userId: string
  count?: number
}
export const recentlyWatchedMoviesByUserIdQuery = ({
  userId,
  count = 5,
}: RecentlyWatchedMoviesByUserIdQueryVariables) =>
  firebase
    .firestore()
    .collection(movieotter.WatchedMovie.collectionPath())
    .where("userId", "==", userId)
    .orderBy("dateTime", "desc")
    .limit(count)

export interface MovieWatchesForUserQueryVariables {
  userId: string
  movieId: string
  count?: number
}
export const movieWatchesForUserQuery = ({ userId, movieId, count = 5 }: MovieWatchesForUserQueryVariables) =>
  firebase
    .firestore()
    .collection(movieotter.WatchedMovie.collectionPath())
    .where("userId", "==", userId)
    .where("movie.movieId", "==", movieId)
    .orderBy("dateTime", "desc")
    .limit(count)

export interface RecentNotificationsByUserIdQueryVariables {
  userId: string
  count?: number
  state?: movieotter.NotificationState
}
export const recentNotificationsByUserIdQuery = ({
  userId,
  count = 5,
  state,
}: RecentNotificationsByUserIdQueryVariables) => {
  let query = firebase
    .firestore()
    .collection(movieotter.Notification.collectionPath())
    .where("recipientId", "==", userId)

  if (state !== null && state !== undefined) {
    query = query.where("state", "==", state)
  }

  return query.orderBy("createDateTime", "desc").limit(count)
}

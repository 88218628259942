import { useEffect, useReducer, Reducer, useState } from "react"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import { recentNotificationsByUserIdQuery } from "utils/queries"
import MOError, { MOErrorType } from "utils/error"

import { useAuth } from "providers/AuthProvider"

const NUM_NOTIFICATIONS_TO_FETCH = 10

export interface IUseNotifications {
  notifications: movieotter.Notification[]
  error?: MOError
  loading: boolean
}

type UseNotifications =
  | { type: "ON_ERROR"; error: MOError }
  | {
      type: "ON_USER_CHANGE"
    }
  | {
      type: "ON_LOADING"
    }
  | {
      type: "ON_LIST_SNAPSHOT"
      data: movieotter.Notification[]
    }

export const useNotifications = (userId?: string, state?: movieotter.NotificationState) => {
  const { currentUser } = useAuth()
  const [count, setCount] = useState(NUM_NOTIFICATIONS_TO_FETCH)

  const [userNotificationState, dispatch] = useReducer<Reducer<IUseNotifications, UseNotifications>>(
    (currentState, action) => {
      switch (action.type) {
        case "ON_ERROR":
          return {
            ...currentState,
            loading: false,
            error: action.error,
          }
        case "ON_USER_CHANGE":
          return {
            ...currentState,
            error: undefined,
            loading: true,
            notifications: [],
          }
        case "ON_LOADING":
          return {
            ...currentState,
            error: undefined,
            loading: true,
          }
        case "ON_LIST_SNAPSHOT":
          return {
            ...currentState,
            loading: false,
            error: undefined,
            notifications: action.data,
          }
      }
    },
    {
      notifications: [],
      error: undefined,
      loading: true,
    }
  )

  const userIdToUse = userId ?? currentUser?.userId

  useEffect(() => {
    setCount(NUM_NOTIFICATIONS_TO_FETCH)
    if (!userIdToUse) {
      dispatch({
        type: "ON_ERROR",
        error: new MOError(MOErrorType.REQUIRES_LOGIN, "Please provide a userId fetch notifications for."),
      })
    }
    dispatch({ type: "ON_USER_CHANGE" })
  }, [userIdToUse])

  useEffect(() => {
    let unsubscribe: (() => void) | undefined

    if (userIdToUse) {
      unsubscribe = recentNotificationsByUserIdQuery({
        userId: userIdToUse,
        count,
        state,
      }).onSnapshot(snapshot => {
        const notifications = snapshot.docs.map(doc => {
          const data = doc.data({
            serverTimestamps: "estimate",
          }) as movieotter.INotification<movieotter.FirebaseTimestamp>
          return movieotter.Notification.fromDocument(data)
        })
        dispatch({ type: "ON_LIST_SNAPSHOT", data: notifications })
      })
    }

    return () => unsubscribe?.()
  }, [userIdToUse, count, state])

  const more = () => {
    firebase.analytics().logEvent("user-notifications-view_more", {
      userId: userIdToUse,
    })
    setCount(count + NUM_NOTIFICATIONS_TO_FETCH)
  }

  return { ...userNotificationState, more }
}

export default useNotifications

import React, { FC } from "react"
import { Avatar, Box, Typography, makeStyles } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { AvatarProps } from "@material-ui/core/Avatar"
import cx from "classnames"

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    "&.left": {
      alignItems: "flex-start",
    },
    "&.center": {
      alignItems: "center",
    },
    "&.right": {
      alignItems: "flex-end",
    },
  },
  text: {
    "&.left": {
      textAlign: "left",
    },
    "&.center": {
      textAlign: "center",
    },
    "&.right": {
      textAlign: "right",
    },
  },
  secondary: {
    fontSize: spacing(1.5),
    fontWeight: 400,
  },
  circle: {
    height: spacing(7),
    width: spacing(7),
  },
  skeleton: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
}))

interface PersonProps extends AvatarProps {
  primary?: string
  secondary?: string
  align?: "left" | "center" | "right"
  loading?: boolean
}

const Person: FC<PersonProps> = ({ primary, secondary, align = "center", loading = false, ...rest }) => {
  const classes = useStyles()
  return (
    <Box
      className={cx(classes.root, {
        [align]: align,
      })}
    >
      {loading ? (
        <Skeleton className={classes.circle} variant="circle" />
      ) : (
        <>
          <Avatar
            className={classes.circle}
            // style={{ height: 8 * 7, width: 8 * 7 }}
            alt={primary}
            {...rest}
          />
          {primary && (
            <Typography
              className={cx(classes.text, {
                [align]: align,
              })}
            >
              {primary}
            </Typography>
          )}
          {secondary && (
            <Typography
              className={cx(classes.secondary, classes.text, {
                [align]: align,
              })}
            >
              {secondary}
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}

export default Person

import React, { FC, useState, useEffect } from "react"
import {
  Box,
  Button,
  Grid,
  Container,
  Typography,
  Tooltip,
  Paper,
  Modal,
  CircularProgress,
  makeStyles,
  colors,
} from "@material-ui/core"
import {
  LocationOnRounded,
  LocalActivityRounded,
  VerifiedUserRounded,
  EditRounded,
  ErrorRounded,
  CheckCircleRounded,
} from "@material-ui/icons"
import { titleCase } from "title-case"
import cx from "classnames"
import { useDropzone } from "react-dropzone"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import firebase from "firebase"
import { Link, useRouteMatch } from "react-router-dom"
// import { useMatch } from "@reach/router"

import { useUser } from "providers/UserProvider"
import { useAuth } from "providers/AuthProvider"

import { MOErrorType } from "utils/error"

import useUserLists from "hooks/useUserLists"
import useWatchedMovies from "hooks/useWatchedMovies"

import UserAvatar from "components/UserAvatar"
import UserFollowings from "components/UserFollowings"
import ErrorDisplay from "components/ErrorDisplay"
import LoadingDisplay from "components/LoadingDisplay"
import MovieList from "components/MovieList"
import Heading from "components/Heading"

const useStyles = makeStyles(({ spacing, typography }) => ({
  badge: {
    fontSize: spacing(2),
  },
  username: {
    fontSize: typography.h6.fontSize,
    fontWeight: 900,
  },
  displayName: {
    fontStyle: "italic",
  },
  socialBadge: {
    marginLeft: spacing(),
  },
}))

const UserProfile: FC = () => {
  const { user, error, loading } = useUser()
  const classes = useStyles()

  const selfMatch = useRouteMatch("/self")
  const { currentUser, openProfileEditModal } = useAuth()

  const userLists = useUserLists(user?.userId ?? "")
  const { movies: watchedMovies, more, loading: watchedMoviesLoading } = useWatchedMovies(user?.userId)

  if (loading) return <LoadingDisplay />

  if (error) {
    if (error.type === MOErrorType.USER_DOES_NOT_EXISTS || error.type === MOErrorType.USERNAME_DOES_NOT_EXISTS) {
      return (
        <Container>
          <ErrorDisplay title="Uh oh!" message="Looks like this user doesn't exist!" />
        </Container>
      )
    }

    return null
  }

  const isOwnProfile = selfMatch && currentUser && currentUser.userId === user?.userId

  return (
    <Box>
      <Container>
        {isOwnProfile && (
          <Grid container spacing={2} justify="center">
            <Grid item xs={12}>
              <Button variant="contained" color="primary" size="small" onClick={openProfileEditModal}>
                <Box py={0.5} pr={1} display="flex">
                  <EditRounded className={classes.badge} />
                </Box>
                Edit Profile
              </Button>
            </Grid>
          </Grid>
        )}
        <UserProfileData />
      </Container>

      <Box pt={4}>
        <MovieList
          movies={watchedMovies.map(movie => movie.movie)}
          generateKey={(_, index) => watchedMovies[index].watchedId}
          headingProps={{
            heading: "Watch History",
            onClick: () => {
              firebase.analytics().logEvent("user-history-view_more", {
                userId: user?.userId,
              })
              more()
            },
            onClickLabel: "Load More",
            onClickDisabled: watchedMoviesLoading,
          }}
        />
      </Box>

      <Box pt={4}>
        <Container>
          <Heading variant="h5">Custom Lists</Heading>
        </Container>
        {userLists.map(list => (
          <MovieList
            key={`${list.listId}-${list.updatedAt.valueOf()}`}
            movies={list.recentlyAdded}
            headingProps={{
              heading: `${list.title} (${list.count})`,
              to: `/list/${list.listId}`,
            }}
          />
        ))}
      </Box>
    </Box>
  )
}

export const UserProfileData: FC = () => {
  const classes = useStyles()
  const { user, error, loading } = useUser()

  if (loading) return <LoadingDisplay />

  // TODO: Display proper error version of profile data on error
  if (error || !user) return null

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={8} md={6}>
        <Box display="flex">
          <Box pr={2}>
            <UserAvatar user={user} size="xLarge" />
          </Box>
          <Box flexGrow={1}>
            <Link to={`/user/${user.username}`}>
              <Typography className={classes.username}>@{user.username}</Typography>
            </Link>
            <Box display="flex" flexGrow={1} alignItems="center">
              <Typography className={classes.displayName} variant="body2">
                {titleCase(user.displayName)}
              </Typography>
              {user.verified && (
                <Tooltip title="Verified">
                  <VerifiedUserRounded className={cx(classes.badge, classes.socialBadge)} fontSize="small" />
                </Tooltip>
              )}
              {user.mvp && (
                <Tooltip title="MovieOtter MVP">
                  <LocalActivityRounded className={cx(classes.badge, classes.socialBadge)} fontSize="small" />
                </Tooltip>
              )}
            </Box>
            <Box py={1}>
              <Typography variant="body2">{user.bio}</Typography>
            </Box>
            <Box display="flex" flexGrow={1} alignItems="center">
              <LocationOnRounded className={classes.badge} />
              <Typography variant="caption">{titleCase(user.location)}</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <UserFollowings />
    </Grid>
  )
}

export default UserProfile

import React, { FC } from "react"
import { Container, Box, Grid, CircularProgress, makeStyles } from "@material-ui/core"
import { SearchRounded } from "@material-ui/icons"
import { Link } from "react-router-dom"

import useUserSearch from "hooks/useUserSearch"

import SEO from "components/SEO"
import RaisedTextField from "components/RaisedTextField"
import Person from "components/Person"

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  person: {
    height: spacing(8),
    width: spacing(8),

    [breakpoints.up("sm")]: {
      height: spacing(10),
      width: spacing(10),
    },
    [breakpoints.up("md")]: {
      height: spacing(12),
      width: spacing(12),
    },
    [breakpoints.up("lg")]: {
      height: spacing(15),
      width: spacing(15),
    },
    [breakpoints.up("xl")]: {
      height: spacing(20),
      width: spacing(20),
    },
  },
}))

const UsersPage: FC = () => {
  return (
    <>
      <SEO title="Users" />
      <UsersPageContent />
    </>
  )
}

const UsersPageContent: FC = () => {
  const classes = useStyles()
  const { query, setQuery, loading, users } = useUserSearch()

  return (
    <Box py={2}>
      <Container>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={10} md={6}>
            <RaisedTextField
              placeholder="Search Users..."
              trailing={loading ? <CircularProgress size={16} /> : <SearchRounded color="disabled" />}
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </Grid>
        </Grid>
      </Container>
      <Box pt={4}>
        <Container>
          <Grid container spacing={2}>
            {users.map(user => (
              <Grid key={user.userId} item xs={4} sm={3} md={2}>
                <Link to={`/user/${user.username}`}>
                  <Person
                    key={user.userId}
                    className={classes.person}
                    primary={user.displayName}
                    secondary={`@${user.username}`}
                    src={user.photoURL}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default UsersPage

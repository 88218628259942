import React, { FC, ReactNode } from "react"
import { Box, Paper, TextField } from "@material-ui/core"
import { StandardTextFieldProps } from "@material-ui/core/TextField"

interface RaisedTextFieldProps extends StandardTextFieldProps {
  trailing?: ReactNode
  textFieldClassName?: string
}

const RaisedTextField: FC<RaisedTextFieldProps> = ({ className, textFieldClassName, trailing, ...rest }) => (
  <Paper className={className}>
    <Box px={2} display="flex" alignItems="center">
      <Box flexGrow={1}>
        <TextField
          {...rest}
          className={textFieldClassName}
          fullWidth
          margin="dense"
          size="small"
          InputProps={{ ...rest?.InputProps, disableUnderline: true }}
        />
      </Box>
      {trailing && trailing}
    </Box>
  </Paper>
)

export default RaisedTextField

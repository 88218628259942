import React, { FC } from "react"

import ErrorDisplay from "components/ErrorDisplay"
import LoginButton from "components/LoginButton"

import { useAuth } from "providers/AuthProvider"

interface PromptLoginDisplayProps {
  title?: string
}

const PromptLoginDisplay: FC<PromptLoginDisplayProps> = ({ title = "Let's get you up and running!" }) => {
  const { currentUser } = useAuth()

  if (currentUser) return null

  return (
    <ErrorDisplay title={title}>
      <LoginButton />
    </ErrorDisplay>
  )
}

export default PromptLoginDisplay

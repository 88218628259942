import React, { FC } from "react"
import { Avatar, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton } from "@material-ui/core"
import { CheckRounded } from "@material-ui/icons"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import { useHistory } from "react-router-dom"

interface NotificationListItemProps {
  notification: movieotter.INotification
}
const NotificationListItem: FC<NotificationListItemProps> = ({ notification }) => {
  const history = useHistory()

  const markAsRead = () => {
    firebase
      .firestore()
      .doc(movieotter.Notification.documentPathFor(notification.notificationId))
      .update({ state: movieotter.NotificationState.READ })
  }

  let onClick: (() => void) | undefined
  if (notification.type == movieotter.NotificationType.RECOMMENDATION) {
    const movieId = notification.metadata["movieId"] as string
    onClick = movieId
      ? () => {
          firebase.analytics().logEvent("user-notification_click_through", {
            notificationType: notification.type
              ? movieotter.NotificationType[notification.type]
              : "NO_NOTIFICATION_TYPE",
          })
          markAsRead()
          history.push(`/movie/${movieId}`)
        }
      : undefined
  }

  return (
    // <ListItem button={onClick ? true : false} onClick={() => onClick?.()}>
    <ListItem onClick={() => onClick?.()}>
      {(notification.senderPhotoURL || notification.senderDisplayName) && (
        <ListItemAvatar>
          <Avatar src={notification.senderPhotoURL} alt={notification.senderDisplayName} />
        </ListItemAvatar>
      )}
      <ListItemText primary={notification.subject} secondary={notification.message} />
      {notification.state != movieotter.NotificationState.READ && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            onClick={() => {
              firebase.analytics().logEvent("user-notification_read", {
                notificationType: notification.type
                  ? movieotter.NotificationType[notification.type]
                  : "NO_NOTIFICATION_TYPE",
              })
              markAsRead()
            }}
          >
            <CheckRounded />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

export default NotificationListItem

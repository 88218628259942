import React, { FC, forwardRef } from "react"
import { Fab } from "@material-ui/core"
import { FabProps } from "@material-ui/core/Fab"

import { DestructiveThemeOverride } from "components/ThemeOverride"

const DestructiveFab: FC<FabProps> = forwardRef((props, ref) => {
  return (
    <DestructiveThemeOverride>
      <Fab {...props} ref={ref} color="primary" />
    </DestructiveThemeOverride>
  )
})

export default DestructiveFab

import React, { FC } from "react"
import { ThemeProvider, createMuiTheme, responsiveFontSizes } from "@material-ui/core"

import MOColors from "utils/colors"

const ProjectThemeProvider: FC = ({ children }) => {
  const theme = responsiveFontSizes(
    createMuiTheme({
      typography: {
        fontFamily: "'Nunito', sans-serif",
        h1: {
          fontWeight: 900,
        },
        h2: {
          fontWeight: 900,
        },
        h3: {
          fontWeight: 900,
        },
        h4: {
          fontWeight: 900,
        },
        h5: {
          fontWeight: 900,
        },
        h6: {
          fontWeight: 900,
        },
        body1: {
          fontWeight: "bold",
        },
      },
      palette: {
        primary: {
          main: MOColors.SPACE_CADET,
        },
        secondary: {
          main: MOColors.PLATINUM,
        },
        text: {
          primary: MOColors.SPACE_CADET,
        },
        background: {
          default: MOColors.PLATINUM,
        },
        error: {
          main: MOColors.DEEP_PUCE,
        },
        common: {
          black: MOColors.ONYX,
        },
        info: {
          main: MOColors.DARK_CORNFLOWER_BLUE,
        },
        success: {
          main: MOColors.PERSIAN_GREEN,
        },
      },
      overrides: {
        MuiCssBaseline: {
          "@global": {
            "*": {
              "-webkit-overflow-scrolling": "touch",

              "&::-webkit-scrollbar": {
                backgroundColor: MOColors.PLATINUM,
                width: 16,
              },

              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#babac0",
                borderRadius: 16,
                border: `4px solid ${MOColors.PLATINUM}`,

                "&:hover": {
                  backgroundColor: "#a0a0a5",
                },
              },

              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },

              "&::-webkit-scrollbar-button": {
                display: "none",
              },
            },
            "@keyframes RotateAnimation-keyframes-circular-rotate-clockwise": {
              "100%": {
                transform: "rotate(360deg)",
              },
            },
            "@keyframes RotateAnimation-keyframes-circular-rotate-counter-clockwise": {
              "100%": {
                transform: "rotate(-360deg)",
              },
            },
            a: {
              "&:link": {
                color: "inherit",
              },
              "&:visited": {
                color: "inherit",
              },
              "&:hover": {
                color: "inherit",
              },
              "&:active": {
                color: "inherit",
              },
            },
          },
        },
      },
    })
  )

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ProjectThemeProvider

import React, { FC } from "react"
import { makeStyles, Box, Grid, Container } from "@material-ui/core"
import firebase from "firebase"

import MOColors from "utils/colors"

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    backgroundColor: palette.primary.main,
    color: MOColors.WHITE,
  },
  message: {
    textAlign: "center",

    [breakpoints.up("sm")]: {
      textAlign: "start",
    },
  },
  policies: {
    justifyContent: "space-evenly",

    [breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
}))

const Footer: FC = () => {
  const classes = useStyles()
  return (
    <Box className={classes.root} component="footer" p={2}>
      <Container disableGutters={true}>
        <Grid container spacing={2} justify="center">
          <Grid className={classes.message} item xs={12} sm={6}>
            © {new Date().getFullYear()}, Made{" "}
            <a
              onClick={() => {
                firebase.analytics().logEvent("navigation-twitch")
              }}
              href="https://twitch.tv/isaiahbydayah"
              style={{ color: MOColors.WHITE }}
              target="_blank"
            >
              live and in the open
            </a>{" "}
            by{" "}
            <a
              onClick={() => {
                firebase.analytics().logEvent("navigation-twitter")
              }}
              href="https://twitter.com/IsaiahByDayah"
              style={{ color: MOColors.WHITE }}
              target="_blank"
            >
              this person
            </a>{" "}
            👋
          </Grid>
          <Grid className={classes.policies} item xs={12} sm={6} container spacing={2}>
            <Grid item>
              <a
                onClick={() => {
                  firebase.analytics().logEvent("navigation-support_form")
                }}
                rel="noopener noreferrer"
                href="https://forms.gle/KdTT2FLN2augzZWVA"
                target="_blank"
              >
                Support Form
              </a>
            </Grid>
            <Grid item>
              <a
                onClick={() => {
                  firebase.analytics().logEvent("navigation-privacy_policy")
                }}
                rel="noopener noreferrer"
                href={`${process.env.PUBLIC_URL}/MovieOtter-Privacy-Policy-5-22-2020.pdf`}
                target="_blank"
              >
                Privacy Policy
              </a>
            </Grid>
            <Grid item>
              <a
                onClick={() => {
                  firebase.analytics().logEvent("navigation-terms_of_service")
                }}
                rel="noopener noreferrer"
                href={`${process.env.PUBLIC_URL}/MovieOtter-Terms-of-Service-5-22-2020.pdf`}
                target="_blank"
              >
                Terms of Service
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer

import { useState, useEffect } from "react"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import { userListsByUpdatedAtQuery } from "utils/queries"

const useUserLists = (userId: string, count = 3) => {
  const [userLists, setUserLists] = useState<movieotter.MovieList[]>([])

  useEffect(() => {
    let unsubscribe: (() => void) | undefined
    setUserLists([])
    if (userId) {
      unsubscribe = userListsByUpdatedAtQuery({ userId, count }).onSnapshot(snapshot => {
        const listDatas = snapshot.docs.map(
          doc =>
            doc.data({
              serverTimestamps: "estimate",
            }) as movieotter.IMovieList<movieotter.FirebaseTimestamp>
        )
        const lists = listDatas.map(data => movieotter.MovieList.fromDocument(data))

        setUserLists(lists)
      })
    }

    return () => unsubscribe?.()
  }, [userId, count])

  return userLists
}

export default useUserLists

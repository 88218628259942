import React, { FC, useState, useEffect } from "react"
import {
  Avatar,
  Box,
  Button,
  Grid,
  Container,
  Typography,
  Paper,
  Modal,
  CircularProgress,
  makeStyles,
  colors,
} from "@material-ui/core"
import { ErrorRounded, CheckCircleRounded } from "@material-ui/icons"
import cx from "classnames"
import { useDropzone } from "react-dropzone"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import firebase from "firebase"

import { useAuth } from "providers/AuthProvider"

import useDebounceState from "hooks/useDebounceState"
import useUsername from "hooks/useUsername"

import RaisedTextField from "components/RaisedTextField"

const useStyles = makeStyles(({ palette, spacing }) => ({
  contentContainer: {
    outline: 0,
  },
  paper: {
    height: "75vh",
    backgroundColor: palette.background.default,
    padding: spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  title: {
    flexShrink: 0,
    flexGrow: 0,
  },
  content: {
    flexGrow: 1,
    overflowY: "auto",
    margin: spacing(2, 0),
  },
  section: {
    marginTop: spacing(2),

    "&:last-child": {
      marginBottom: spacing(2),
    },
  },
  photoSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    height: spacing(10),
    width: spacing(10),
  },
  controls: {
    flexShrink: 0,
    flexGrow: 0,
    display: "flex",
    justifyContent: "space-evenly",
  },
  savingSpinner: {
    marginRight: spacing(),
  },
}))

const UserProfileEdit: FC<{ open: boolean }> = ({ open }) => {
  const classes = useStyles()
  const { currentUser, logout, closeProfileEditModal } = useAuth()

  const [loading, setLoading] = useState(false)

  // Profile Picture
  const [photoURL, setPhotoURL] = useState(currentUser?.photoURL || "")
  const [photoFile, setPhotoFile] = useState<File>()
  useEffect(() => {
    if (currentUser) setPhotoURL(currentUser.photoURL)
  }, [currentUser?.photoURL])
  const url = (photoFile && URL.createObjectURL(photoFile)) || photoURL
  const { open: openFileSelect, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDropAccepted: files => setPhotoFile(files[0]),
  })

  // Display Name
  const [displayName, setDisplayName] = useState(currentUser?.displayName || "")
  useEffect(() => {
    if (currentUser) setDisplayName(currentUser.displayName)
  }, [currentUser?.displayName])

  // Username
  const [username, debouncedUsername, setUsername] = useDebounceState(currentUser?.username || "")
  const { available, userId: usernameUserId, loading: usernameLoading, error: usernameError } = useUsername(
    debouncedUsername
  )
  useEffect(() => {
    if (currentUser) setUsername(currentUser.username)
  }, [currentUser?.username])
  useEffect(() => {
    const formattedUsername = movieotter.User.formatUsername(username)
    if (username !== formattedUsername) setUsername(formattedUsername)
  }, [username])
  const usernameOk = available || usernameUserId === currentUser?.userId
  let usernameTrailing
  if (usernameError) usernameTrailing = <ErrorRounded color="error" />
  else if (usernameLoading) usernameTrailing = <CircularProgress size={8} />
  else if (!usernameOk) usernameTrailing = <ErrorRounded color="error" />
  else usernameTrailing = <CheckCircleRounded htmlColor={colors.green[400]} />

  // Bio
  const [bio, setBio] = useState(currentUser?.bio || "")
  useEffect(() => {
    if (currentUser) setBio(currentUser.bio)
  }, [currentUser?.bio])
  useEffect(() => {
    const formattedBio = movieotter.User.formatBio(bio)
    if (bio !== formattedBio) setBio(formattedBio)
  }, [bio])

  // Location
  const [location, setLocation] = useState(currentUser?.location || "")
  useEffect(() => {
    if (currentUser) setLocation(currentUser.location)
  }, [currentUser?.location])

  const userRequiresSetup = (currentUser && !currentUser.setup) || false

  const onSave = async () => {
    setLoading(true)

    try {
      // Upload image
      let newPhotoUrl
      if (photoFile) {
        const userProfilePictureRef = firebase
          .storage()
          .ref()
          .child(`${movieotter.User.documentPathForUser(currentUser?.userId ?? "")}/profile_picture_${Date.now()}.jpg`)
        const snapshot = await userProfilePictureRef.put(photoFile, {
          contentType: "image/jpeg",
        })
        newPhotoUrl = await snapshot.ref.getDownloadURL()
      }

      // call function to update userInfo
      const updateData: {
        displayName: string
        location: string
        bio: string
        photoURL?: string
      } = {
        displayName,
        location,
        bio,
      }
      if (newPhotoUrl) updateData["photoURL"] = newPhotoUrl
      await firebase.functions().httpsCallable("updateUserData")(updateData)

      // call function to update username
      if (username !== currentUser?.username) {
        await firebase.functions().httpsCallable("changeUsername")({ username })
      }

      if (userRequiresSetup) {
        await firebase.functions().httpsCallable("checkUserSetup")()
      }

      setLoading(false)
      setPhotoFile(undefined)
    } catch (e) {
      console.warn("User profile update error: ", e)
    }
  }

  const saveDisabled = loading || usernameError != null || usernameLoading || !usernameOk

  return (
    <Modal open={open || userRequiresSetup}>
      <Box
        className={classes.contentContainer}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Container>
          <Paper className={classes.paper}>
            <Typography className={classes.title} variant="h5" align="center">
              {userRequiresSetup ? "Create Profile" : "Edit Profile"}
            </Typography>

            <Box className={classes.content}>
              <Grid container>
                {/* User Profile Picture */}
                <Grid className={cx(classes.section, classes.photoSection)} item xs={12}>
                  <Avatar className={classes.avatar} src={url} alt={currentUser?.displayName} />
                  <input {...getInputProps()} />
                  <Button disabled={loading} onClick={() => openFileSelect()}>
                    Select Photo
                  </Button>
                </Grid>

                {/* User Display Name */}
                <Grid className={classes.section} item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Display Name
                  </Typography>
                  <RaisedTextField value={displayName} onChange={e => setDisplayName(e.target.value)} />
                </Grid>

                {/* User Username */}
                <Grid className={classes.section} item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Username
                  </Typography>
                  <RaisedTextField
                    value={`@${username}`}
                    onChange={e => setUsername(e.target.value)}
                    trailing={usernameTrailing}
                  />
                </Grid>

                {/* User Bio */}
                <Grid className={classes.section} item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Bio
                  </Typography>
                  <RaisedTextField value={bio} rowsMax={3} multiline={true} onChange={e => setBio(e.target.value)} />
                </Grid>

                {/* User Location */}
                <Grid className={classes.section} item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Location
                  </Typography>
                  <RaisedTextField value={location} onChange={e => setLocation(e.target.value)} />
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.controls}>
              {/* <Button disabled={loading} onClick={onClose}> */}
              {userRequiresSetup ? (
                <Button disabled={loading} onClick={logout}>
                  Logout
                </Button>
              ) : (
                <Button disabled={loading} onClick={closeProfileEditModal}>
                  Cancel
                </Button>
              )}
              <Button variant="contained" color="primary" disabled={saveDisabled} onClick={onSave}>
                {loading ? (
                  <Box display="flex" alignItems="center">
                    <CircularProgress className={classes.savingSpinner} size={8} />
                    Saving...
                  </Box>
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
          </Paper>
        </Container>
      </Box>
    </Modal>
  )
}

export default UserProfileEdit

import React, { FC } from "react"

interface GoogleIconProps {
  size?: number
}

const GoogleIcon: FC<GoogleIconProps> = ({ size = 18 }) => (
  <svg viewBox="14 14 18 18" style={{ height: size, width: size }}>
    <g fill="none" fillRule="evenodd">
      <g filter="url(#a)" transform="translate(3 3)"></g>
      <path
        fill="#4285F4"
        d="M31.64 23.20454545c0-.63818181-.0572727-1.25181818-.1636364-1.84090909H23V24.845h4.8436364c-.2086364 1.125-.8427273 2.0781818-1.7959091 2.7163636v2.2581819h2.9086363C30.6581818 28.2527273 31.64 25.9454545 31.64 23.20454545z"
      />
      <path
        fill="#34A853"
        d="M23 32c2.43 0 4.4672727-.8059091 5.9563636-2.1804545l-2.9086363-2.2581819c-.8059091.54-1.8368182.8590909-3.0477273.8590909-2.34409091 0-4.32818182-1.5831818-5.03590909-3.7104545h-3.00681818v2.3318182C16.43818182 29.9831818 19.48181818 32 23 32z"
      />
      <path
        fill="#FBBC05"
        d="M17.96409091 24.71c-.18-.54-.28227273-1.11681818-.28227273-1.71 0-.59318182.10227273-1.17.28227273-1.71v-2.33181818h-3.00681818C14.34772727 20.17318182 14 21.54772727 14 23c0 1.4522727.34772727 2.8268182.95727273 4.0418182L17.9640909 24.71z"
      />
      <path
        fill="#EA4335"
        d="M23 17.57954545c1.3213636 0 2.5077273.45409091 3.4404545 1.3459091l2.5813637-2.58136364C27.4631818 14.89181818 25.4259091 14 23 14c-3.51818182 0-6.56181818 2.01681818-8.04272727 4.95818182L17.9640909 21.29C18.67181818 19.16272727 20.65590909 17.57954545 23 17.57954545z"
      />
      <path d="M14 14h18v18H14V14z" />
    </g>
  </svg>
)

export default GoogleIcon

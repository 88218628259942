import React, { FC } from "react"
import { Button, Box, Input, Typography, Grid, FormHelperText } from "@material-ui/core"
import { MailRounded } from "@material-ui/icons"
import firebase from "firebase"

import RaisedTextField from "components/RaisedTextField"

interface NewsletterSignupProps {
  message?: string
  tags?: string[]
}

const NewsletterSignup: FC<NewsletterSignupProps> = ({ message, tags = [] }) => {
  return (
    <Box p={2}>
      {message && (
        <Typography align="center" variant="body2">
          {message}
        </Typography>
      )}
      <form action="https://buttondown.email/api/emails/embed-subscribe/MovieOtter" method="post" target="popupwindow">
        <Input type="hidden" value="1" name="embed" />
        {tags.map(tag => (
          <input key={tag} type="hidden" name="tag" value={tag} checked readOnly />
        ))}
        <Grid container spacing={2} justify="center" alignItems="flex-start">
          <Grid item xs={12} sm md={5}>
            <RaisedTextField placeholder="otto@movieotter.com" type="email" name="email" id="bd-email" />
            <Box px={2}>
              <FormHelperText>Don't worry, I'll never share your email.</FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <Button
              onClick={() => {
                firebase.analytics().logEvent("newsletter-signup_clicked", {
                  message,
                  tags,
                })
              }}
              type="submit"
              value="Subscribe"
              variant="contained"
              color="primary"
              fullWidth
              endIcon={<MailRounded />}
            >
              Join Newsletter
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default NewsletterSignup

import { useEffect, useReducer, Reducer, useState } from "react"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import { recentlyWatchedMoviesByUserIdQuery } from "utils/queries"
import MOError, { MOErrorType } from "utils/error"

import { useAuth } from "providers/AuthProvider"

const NUM_MOVIES_TO_FETCH = 10

export interface IUseWatchedMovies {
  movies: movieotter.WatchedMovie[]
  error?: MOError
  loading: boolean
}

type UseWatchedMoviesAction =
  | { type: "ON_ERROR"; error: MOError }
  | {
      type: "ON_USER_CHANGE"
    }
  | {
      type: "ON_LOADING"
    }
  | {
      type: "ON_LIST_SNAPSHOT"
      data: movieotter.WatchedMovie[]
    }

export const useWatchedMovies = (userId?: string) => {
  const { currentUser } = useAuth()
  const [count, setCount] = useState(NUM_MOVIES_TO_FETCH)

  const [state, dispatch] = useReducer<Reducer<IUseWatchedMovies, UseWatchedMoviesAction>>(
    (currentState, action) => {
      switch (action.type) {
        case "ON_ERROR":
          return {
            ...currentState,
            loading: false,
            error: action.error,
          }
        case "ON_USER_CHANGE":
          return {
            ...currentState,
            error: undefined,
            loading: true,
            movies: [],
          }
        case "ON_LOADING":
          return {
            ...currentState,
            error: undefined,
            loading: true,
          }
        case "ON_LIST_SNAPSHOT":
          return {
            ...currentState,
            loading: false,
            error: undefined,
            movies: action.data,
          }
      }
    },
    {
      movies: [],
      error: undefined,
      loading: true,
    }
  )

  const userIdToUse = userId ?? currentUser?.userId

  useEffect(() => {
    setCount(NUM_MOVIES_TO_FETCH)
    if (!userIdToUse) {
      dispatch({
        type: "ON_ERROR",
        error: new MOError(MOErrorType.REQUIRES_LOGIN, "Please provide a userId to fetch history for."),
      })
    }
    dispatch({ type: "ON_USER_CHANGE" })
  }, [userIdToUse])

  useEffect(() => {
    let unsubscribe: (() => void) | undefined

    if (userIdToUse) {
      unsubscribe = recentlyWatchedMoviesByUserIdQuery({
        userId: userIdToUse,
        count,
      }).onSnapshot(snapshot => {
        const newMovies = snapshot.docs.map(doc => {
          const data = doc.data({
            serverTimestamps: "estimate",
          }) as movieotter.IWatchedMovie<movieotter.FirebaseTimestamp>
          return movieotter.WatchedMovie.fromDocument(data)
        })
        dispatch({ type: "ON_LIST_SNAPSHOT", data: newMovies })
      })
    }

    return () => unsubscribe?.()
  }, [userIdToUse, count])

  const more = () => {
    firebase.analytics().logEvent("user-history-view_more", {
      userId: userIdToUse,
    })
    setCount(count + NUM_MOVIES_TO_FETCH)
  }

  return { ...state, more }
}

export default useWatchedMovies

import * as movieotter from "@isaiahbydayah/movieotter-core"

import useLocalStorage from "./useLocalStorage"

const useRecentlyVisitedMovies = () => {
  const [recentlyVisitedMovies, setRecentlyVisitedMovies] = useLocalStorage<movieotter.MovieData[]>(
    "MOVIEOTTER_RECENTLY_VISITED_MOVIES",
    []
  )

  const add = (movie: movieotter.Movie) => {
    // Remove this movie from recents list
    const list = recentlyVisitedMovies.filter(m => {
      let keep = true
      if (movie.movieId == m.movieId) keep = false
      if (movie.externalIds.tmdb && movie.externalIds.tmdb == m.externalIds.tmdb) keep = false
      if (movie.externalIds.imdb && movie.externalIds.imdb == m.externalIds.imdb) keep = false
      return keep
    })
    list.unshift(movie.data)
    setRecentlyVisitedMovies(list.slice(0, 6))
  }

  const clear = () => setRecentlyVisitedMovies([])

  return {
    movies: recentlyVisitedMovies.map(data =>
      movieotter.Movie.fromJSONData((data as unknown) as movieotter.MovieData<string>)
    ),
    add,
    clear,
  }
}

export default useRecentlyVisitedMovies

import React, { FC, useState, useEffect } from "react"
import { Container, Grid, Box, Typography, useTheme, makeStyles } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import firebase from "firebase"

import MOError, { MOErrorType } from "utils/error"

import SEO from "components/SEO"
import MoviePoster from "components/MoviePoster"
import ScrollList from "components/ScrollList"
import Person from "components/Person"

dayjs.extend(duration)

const INITIAL_CAST_COUNT = 5

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  paper: {
    overflow: "hidden",
    display: "flex",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  skeleton: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  title: {
    textAlign: "center",
  },
  runtime: {
    marginRight: spacing(2),
  },
  releaseDate: {},
  description: {
    textAlign: "center",
    color: palette.primary.light,

    [breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
}))

interface MovieProfileProps {
  movie?: movieotter.Movie
  error?: MOError
}
const MovieProfile: FC<MovieProfileProps> = ({ movie, error }) => {
  const theme = useTheme()
  const classes = useStyles()
  const [castCount, setCastCount] = useState(INITIAL_CAST_COUNT)

  const [cast, setCast] = useState<movieotter.CastMember[]>()

  useEffect(() => {
    let unsubscribe: (() => void) | undefined
    if (movie) {
      unsubscribe = firebase
        .firestore()
        .collection(movieotter.CastMember.collectionPathFor(movie.movieId))
        .orderBy("order")
        .limit(castCount)
        .onSnapshot(snapshot => {
          if (!snapshot.empty) {
            const castMembers = snapshot.docs.map(doc => {
              const data = doc.data({
                serverTimestamps: "estimate",
              }) as movieotter.ICastMember
              return movieotter.CastMember.fromDocument(data)
            })
            setCast(castMembers)
          }
        })
    }

    return () => unsubscribe?.()
  }, [movie?.movieId, castCount])

  if (error) {
    console.warn(error)
    if (error.type === MOErrorType.DOCUMENT_DOES_NOT_EXISTS) {
      return (
        <Container>
          <Box py={4}>
            <Typography variant="h4" align="center" gutterBottom>
              Uh oh!
            </Typography>
            <Typography variant="body2" align="center">
              Looks like this movie doesn't exists!
            </Typography>
          </Box>
        </Container>
      )
    }

    return null
  }

  const runtime = movie && movie.runtime ? dayjs.duration(movie.runtime, "m") : null

  return (
    <Container>
      <SEO title={movie?.title || "Movie"} />
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={4} container justify="center">
          <Grid item xs={8} sm={12}>
            <MoviePoster movieData={movie} displayTitle={false} link={false} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          {movie ? (
            <>
              <Typography className={classes.title} variant="h6">
                {movie.title}
              </Typography>
              <Box textAlign="center" pb={2}>
                {runtime && (
                  <Typography className={classes.runtime} variant="caption">
                    Runtime: {runtime.hours()}h {runtime.minutes()}m
                  </Typography>
                )}
                {movie.releaseDate && (
                  <Typography className={classes.releaseDate} variant="caption">
                    Release Date: {dayjs(movie.releaseDate).format("MMM D, YYYY")}
                  </Typography>
                )}
              </Box>
              <Typography className={classes.description} variant="body2">
                {movie.description}
              </Typography>
              <ScrollList
                headingProps={{
                  heading: "Cast & Characters",
                  onClickLabel: "Show More",
                  onClick: () => {
                    firebase.analytics().logEvent("movie-cast-view_more", {
                      movieId: movie.movieId,
                      movieTitle: movie.title,
                    })
                    setCastCount(castCount + 5)
                  },
                }}
              >
                {cast
                  ? cast.map(cast => (
                      <Person
                        key={cast.castMemberId}
                        primary={cast.personName}
                        secondary={cast.characterName}
                        src={cast.personProfileURL ?? undefined}
                      />
                    ))
                  : [1, 2, 3, 4, 5].map(num => <Person key={num} loading={true} />)}
              </ScrollList>
            </>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Skeleton className={classes.skeleton} variant="text" height={theme.typography.h6.fontSize} width="80%" />
              <Skeleton className={classes.skeleton} variant="text" height={theme.typography.h6.fontSize} width="70%" />
              <Box height={theme.spacing()}></Box>
              <Skeleton
                className={classes.skeleton}
                variant="text"
                height={theme.typography.body2.fontSize}
                width="85%"
              />
              <Skeleton
                className={classes.skeleton}
                variant="text"
                height={theme.typography.body2.fontSize}
                width="100%"
              />
              <Skeleton
                className={classes.skeleton}
                variant="text"
                height={theme.typography.body2.fontSize}
                width="85%"
              />
              <Skeleton
                className={classes.skeleton}
                variant="text"
                height={theme.typography.body2.fontSize}
                width="90%"
              />
              <Skeleton
                className={classes.skeleton}
                variant="text"
                height={theme.typography.body2.fontSize}
                width="60%"
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default MovieProfile

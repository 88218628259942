import React, { FC, ReactNode } from "react"
import { Box, Typography, Paper, makeStyles, colors } from "@material-ui/core"
import cx from "classnames"

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: "relative",
  },
  comingSoon: {
    // backgroundColor: palette.augmentColor(palette.grey).light,
    backgroundColor: palette.grey[200],
  },
  ribbon: {
    position: "absolute",
    right: "-7px",
    top: "-7px",
    zIndex: 1,
    overflow: "hidden",
    width: spacing(10),
    height: spacing(10),
    textAlign: "right",
  },
  ribbonMessage: {
    fontSize: spacing(1.5),
    color: palette.primary.contrastText,
    textAlign: "center",
    lineHeight: 2,
    transform: "rotate(45deg)",
    width: spacing(13),
    display: "block",
    background: palette.primary.main,
    boxShadow: "0 3px 10px -5px rgba(0, 0, 0, 1)",
    position: "absolute",
    top: "19px",
    right: "-21px",

    "&::before": {
      content: '""',
      position: "absolute",
      left: "0px",
      top: "100%",
      zIndex: "-1",
      borderLeft: `3px solid ${palette.primary.main}`,
      borderRight: "3px solid transparent",
      borderBottom: "3px solid transparent",
      borderTop: `3px solid ${palette.primary.main}`,
    },

    "&::after": {
      content: '""',
      position: "absolute",
      right: "0px",
      top: "100%",
      zIndex: "-1",
      borderLeft: "3px solid transparent",
      borderRight: `3px solid ${palette.primary.main}`,
      borderBottom: "3px solid transparent",
      borderTop: `3px solid ${palette.primary.main}`,
    },
  },
}))

interface FeatureProps {
  className?: string
  title: string
  blurb: string
  img?: ReactNode
  comingSoon?: boolean
}

const Feature: FC<FeatureProps> = ({ className, img, comingSoon, title, blurb }) => {
  const classes = useStyles()
  return (
    <Paper className={cx(classes.root, { [classes.comingSoon]: comingSoon }, className)}>
      <Box p={2}>
        {img && (
          <Box pb={2} px={4}>
            {img}
          </Box>
        )}
        <Typography variant="h6" align="center">
          {title}
        </Typography>
        <Box pt={2}>
          <Typography variant="body2" align="center">
            {blurb}
          </Typography>
        </Box>
      </Box>
      {comingSoon && (
        <Box className={classes.ribbon}>
          <Typography className={classes.ribbonMessage}>Coming Soon</Typography>
        </Box>
      )}
    </Paper>
  )
}

export default Feature

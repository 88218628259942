import React, { FC } from "react"
import { CssBaseline, makeStyles, Box, BottomNavigation, BottomNavigationAction } from "@material-ui/core"
import { BrowserRouter } from "react-router-dom"
import { SnackbarProvider } from "notistack"
import { Restore, Favorite, LocationOn } from "@material-ui/icons"

import ProjectThemeProvider from "providers/ProjectThemeProvider"
import ConfirmationProvider from "providers/ConfirmationProvider"
import AuthProvider from "providers/AuthProvider"

import Header from "components/Header"
import Footer from "components/Footer"

const useSnackbarStyles = makeStyles(({ palette }) => ({
  success: {
    backgroundColor: palette.success.main,
  },
  error: {
    backgroundColor: palette.error.main,
  },
  info: {
    backgroundColor: palette.info.main,
    color: palette.info.contrastText,
  },
  warning: {
    backgroundColor: palette.warning.main,
  },
}))

const Base: FC = ({ children }) => {
  const snackbarClasses = useSnackbarStyles()

  return (
    <ProjectThemeProvider>
      <CssBaseline />
      <ConfirmationProvider>
        <AuthProvider>
          <SnackbarProvider
            maxSnack={3}
            classes={{
              variantSuccess: snackbarClasses.success,
              variantError: snackbarClasses.error,
              variantInfo: snackbarClasses.info,
              variantWarning: snackbarClasses.warning,
            }}
          >
            <BrowserRouter>
              <Box display="flex" flexDirection="column" height="100vh">
                <Header />
                <Box pt={10} pb={2} flexGrow={1}>
                  <main>{children}</main>
                </Box>
                <Footer />
              </Box>
            </BrowserRouter>
          </SnackbarProvider>
        </AuthProvider>
      </ConfirmationProvider>
    </ProjectThemeProvider>
  )
}

export default Base

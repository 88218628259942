import { useState, useEffect } from "react"
import firebase from "firebase"
import algoliasearch from "algoliasearch"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import useDebounceState from "./useDebounceState"

// TODO: refactor into api for DRY searching
const algolia = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID ?? "", process.env.REACT_APP_ALGOLIA_API_KEY ?? "")
const listIndex = algolia.initIndex(
  `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX ?? "err_"}${movieotter.MovieList.COLLECTION_NAME}`
)
if (
  !process.env.REACT_APP_ALGOLIA_APP_ID ||
  !process.env.REACT_APP_ALGOLIA_API_KEY ||
  !process.env.REACT_APP_ALGOLIA_INDEX_PREFIX
) {
  console.error("MISSING ALGOLIA ENVIRONMENT VARIABLES")
}

const useUserSearch = (initialQuery = "") => {
  const [loading, setLoading] = useState(false)
  const [query, debouncedQuery, setQuery] = useDebounceState(initialQuery)
  const [lists, setLists] = useState<movieotter.MovieListAlgoliaObject[]>([])

  useEffect(() => {
    let running = true

    if (debouncedQuery) {
      firebase.analytics().logEvent("search-users", {
        query: debouncedQuery,
      })
      listIndex.search<movieotter.MovieListAlgoliaObject>(debouncedQuery).then(({ hits }) => {
        console.log("LISTS HITS: ", hits)
        if (running) {
          setLoading(false)
          setLists(hits)
        }
      })
    } else {
      setLoading(false)
      setLists([])
    }

    return () => {
      running = false
    }
  }, [initialQuery, debouncedQuery])

  useEffect(() => {
    if (query === debouncedQuery) setLoading(false)
    else setLoading(true)
  }, [query])

  return { query, debouncedQuery, loading, lists, setQuery }
}

export default useUserSearch

import { useState, useEffect } from "react"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import { useAuth } from "providers/AuthProvider"

import MOError, { MOErrorType } from "utils/error"

const useFollow = (user: movieotter.User) => {
  const { currentUser, openLoginModal } = useAuth()

  const [following, setFollowing] = useState<movieotter.UserFollowing>()
  const [followingRef, setFollowingRef] = useState<firebase.firestore.DocumentReference>()
  const [error, setError] = useState<MOError>()

  // Following yourself error
  useEffect(() => {
    if (currentUser?.userId === user.userId) {
      setError(new MOError(MOErrorType.FAILED_OPERATION, "Unable to follow self."))
    }
  }, [user, currentUser])

  // Set following doc ref
  useEffect(() => {
    if (currentUser && user) {
      setFollowingRef(
        firebase.firestore().doc(movieotter.UserFollowing.documentPathFor(user.userId, currentUser.userId))
      )
    } else {
      setFollowingRef(undefined)
    }
  }, [currentUser, user])

  useEffect(() => {
    let unsubscribe: (() => void) | undefined

    if (followingRef) {
      unsubscribe = followingRef.onSnapshot(snapshot => {
        if (snapshot.exists) {
          const data = snapshot.data({
            serverTimestamps: "estimate",
          }) as movieotter.UserFollowingData<movieotter.FirebaseTimestamp>
          setFollowing(movieotter.UserFollowing.fromDocument(data))
        } else {
          setFollowing(undefined)
        }
      })
    }

    return () => unsubscribe?.()
  }, [followingRef])

  const follow = () => {
    if (!currentUser) {
      openLoginModal()
    } else if (followingRef && !following) {
      const newFollowingData = movieotter.UserFollowing.new(
        user,
        currentUser,
        firebase.firestore.FieldValue.serverTimestamp()
      )
      followingRef!.set(newFollowingData).then(() => {
        firebase.analytics().logEvent("user-follow", {
          followedId: newFollowingData.user.userId,
          followsBack: newFollowingData.followsBack,
        })
      })
    }
  }

  const unfollow = () => {
    if (Boolean(followingRef) && Boolean(following)) {
      const f = following!
      followingRef!.delete().then(() => {
        const followDuration = (Date.now() - f.followingSince.getTime()) / (1000 * 3600 * 24)
        firebase.analytics().logEvent("user-unfollow", {
          followedId: f.user.userId,
          wasFollowingBack: f.followsBack,
          followDuration,
        })
      })
    }
  }

  return {
    following,
    follow,
    unfollow,
    error,
  }
}

export default useFollow

import { useEffect, useState } from "react"
import * as movieotter from "@isaiahbydayah/movieotter-core"
import firebase from "firebase"
import { useSnackbar } from "notistack"

import { movieWatchesForUserQuery } from "utils/queries"

import { useAuth } from "providers/AuthProvider"

const useWatchedMovie = (movie: movieotter.SimpleMovieData, userId?: string) => {
  const { currentUser, openLoginModal } = useAuth()
  const [processing, setProcessing] = useState(false)
  const [watchedMovie, setWatchedMovie] = useState<movieotter.WatchedMovie | null>()
  const { enqueueSnackbar } = useSnackbar()

  const userIdOfWatchedMovie = userId ?? currentUser?.userId

  useEffect(() => {
    let unsubscribe: (() => void) | undefined

    if (movie?.movieId && userIdOfWatchedMovie) {
      unsubscribe = movieWatchesForUserQuery({
        userId: userIdOfWatchedMovie,
        movieId: movie.movieId,
        count: 1,
      }).onSnapshot(snapshot => {
        if (snapshot.empty) {
          setWatchedMovie(null)
        } else {
          const data = snapshot.docs[0].data({
            serverTimestamps: "estimate",
          }) as movieotter.IWatchedMovie<movieotter.FirebaseTimestamp>
          setWatchedMovie(movieotter.WatchedMovie.fromDocument(data))
        }
      })
    }

    return () => unsubscribe?.()
  }, [movie?.movieId, userIdOfWatchedMovie])

  const logWatch = async ({
    dateTime,
    notes,
    reaction,
  }: {
    dateTime?: Date
    notes?: string
    reaction?: movieotter.Reaction
  } = {}) => {
    if (currentUser) {
      if (userIdOfWatchedMovie && userIdOfWatchedMovie === currentUser.userId) {
        const m = movie
        const doc = firebase.firestore().collection(movieotter.WatchedMovie.collectionPath()).doc()
        const data: movieotter.IWatchedMovie = {
          watchedId: doc.id,
          userId: currentUser.userId,
          movie: {
            movieId: movie.movieId,
            title: movie.title,
            posterURL: movie.posterURL,
            releaseDate: movie.releaseDate ?? null,
            runtime: movie.runtime ?? null,
            lastImportDateTime: null,
          },
          dateTime: dateTime ?? ((firebase.firestore.FieldValue.serverTimestamp() as unknown) as Date),
          notes: notes ?? null,
          reaction: reaction ?? null,
        }
        setProcessing(true)
        await doc.set(data)
        firebase.analytics().logEvent("user-watched_movie", {
          movieId: m.movieId,
          movieTitle: m.title,
          reaction: reaction ?? null,
          includesNotes: !!notes,
        })
        setProcessing?.(false)
        enqueueSnackbar("Movie Logged!", { variant: "success" })
      }
    } else {
      openLoginModal()
    }
  }

  const updateLog = async ({
    dateTime,
    notes,
    reaction,
  }: {
    dateTime?: Date
    notes?: string | null
    reaction?: movieotter.Reaction | null
  }) => {
    if (userIdOfWatchedMovie && userIdOfWatchedMovie === currentUser?.userId && watchedMovie) {
      const m = movie
      const data: Partial<movieotter.IWatchedMovie> = {
        dateTime,
        notes,
        reaction,
      }
      setProcessing(true)
      await firebase.firestore().doc(watchedMovie.documentPath).update(data)
      firebase.analytics().logEvent("user-watched_movie_updated", {
        movieId: m.movieId,
        movieTitle: m.title,
        reaction: reaction ?? null,
        includesNotes: !!notes,
      })
      setProcessing?.(false)
    }
  }

  const deleteLog = async () => {
    if (userIdOfWatchedMovie && userIdOfWatchedMovie === currentUser?.userId && watchedMovie) {
      const m = movie
      setProcessing(true)
      await firebase.firestore().doc(watchedMovie.documentPath).delete()
      firebase.analytics().logEvent("user-watched_movie_deleted", {
        movieId: m.movieId,
        movieTitle: m.title,
      })
      setProcessing?.(false)
    }
  }

  return { processing, watchedMovie, logWatch, updateLog, deleteLog }
}

export default useWatchedMovie

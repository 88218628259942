import React, { FC } from "react"

interface StatsImageProps {
  color?: string
  height?: number
  width?: number
}

const StatsImage: FC<StatsImageProps> = ({ color = "#6c5c43" }) => (
  <svg viewBox="0 0 1002 823.11858" style={{ maxHeight: 200 }}>
    <path
      d="M1101,511.44071c-335.92242-127.29407-669.97177-130.56268-1002,0v-473H1101Z"
      transform="translate(-99 -38.44071)"
      fill="#3f3d56"
    />
    <rect x="36" y="30" width="197" height="9" fill="#d0cde1" />
    <rect x="36" y="53" width="197" height="9" fill="#d0cde1" />
    <rect x="36" y="76" width="197" height="9" fill="#d0cde1" />
    <rect x="69" y="114" width="20" height="20" fill="#d0cde1" />
    <rect x="125" y="114" width="20" height="20" fill={color} />
    <rect x="181" y="114" width="20" height="20" fill="#ff6584" />
    <rect x="51.00005" y="189.00004" width="168" height="28.87712" fill="#d0cde1" />
    <rect x="51.00005" y="233.31148" width="168" height="28.87712" fill={color} />
    <rect x="51.00005" y="277.62291" width="168" height="28.87712" fill="#ff6584" />
    <rect x="51.00006" y="189.00005" width="69.49999" height="28.87712" opacity="0.15" />
    <rect x="50.99995" y="233.31145" width="22.5" height="28.87712" opacity="0.15" />
    <rect x="51.00005" y="277.62291" width="112" height="28.87712" opacity="0.15" />
    <path d="M591,61.44071v330a165,165,0,0,0,0-330Z" transform="translate(-99 -38.44071)" fill="#d0cde1" />
    <path
      d="M580,68.6943a151.56745,151.56745,0,0,0-135.51031,83.60891L580,232.51707Z"
      transform="translate(-99 -38.44071)"
      fill="#d0cde1"
    />
    <path
      d="M433.48969,154.45353A165.04589,165.04589,0,0,0,581,393.44071v-151.67Z"
      transform="translate(-99 -38.44071)"
      fill={color}
    />
    <rect x="768" y="109" width="101" height="9" fill="#d0cde1" />
    <rect x="735" y="103" width="20" height="20" fill={color} />
    <rect x="768" y="139" width="101" height="9" fill="#d0cde1" />
    <rect x="735" y="133" width="20" height="20" fill={color} />
    <rect x="768" y="169" width="101" height="9" fill="#d0cde1" />
    <rect x="735" y="163" width="20" height="20" fill={color} />
    <polygon
      points="438.707 250.707 437.293 249.293 542.586 144 726 144 726 146 543.414 146 438.707 250.707"
      fill="#2f2e41"
    />
    <path d="M1101,770.44071H99v-227q483-188.20473,1002,0Z" transform="translate(-99 -38.44071)" fill="#3f3d56" />
    <path
      d="M510.80283,643.32181,519.94,671.60339s-8.26693,26.54118-4.351,56.12808,1.74041,30.45709,1.74041,30.45709-39.12624-2.25247-35.21033,9.49527,42.172,4.86307,42.172,4.86307,7.34724-1.97821,10.828-22.86307,12.23232-61.11149,12.23232-61.11149L539.122,650.59745Z"
      transform="translate(-99 -38.44071)"
      fill="#ffb8b8"
    />
    <path
      d="M513.84854,585.45333s-16.53385,6.09142-16.53385,22.62527,6.09142,39.15912,10.00733,40.46442,30.022,19.14446,34.80811,6.52652S513.84854,585.45333,513.84854,585.45333Z"
      transform="translate(-99 -38.44071)"
      fill="#d0cde1"
    />
    <polygon
      points="553.211 742.808 588.019 741.503 614.56 737.587 606.728 763.693 542.333 765.433 553.211 742.808"
      fill="#ffb8b8"
    />
    <path
      d="M702.24742,784.72974s-.4351-9.57223-4.351-9.13713-1.30531-3.48081,2.1755-4.351,13.48815-5.22122,13.48815-5.22122l39.59422,2.61061s25.671,22.62527,15.22855,34.80811-37.41872,1.3053-37.41872,1.3053l-29.15179.4351S692.24009,789.51586,702.24742,784.72974Z"
      transform="translate(-99 -38.44071)"
      fill="#2f2e41"
    />
    <polygon
      points="618.911 712.786 631.529 771.96 657.635 766.739 642.842 692.772 627.178 684.505 618.911 712.786"
      fill="#ffb8b8"
    />
    <path
      d="M567.80111,743.83022s-73.097,33.0677-42.20483,66.5705c0,0,0,15.66365,32.6326,12.61794s95.72229-8.702,98.768-6.96162,12.61794-34.373,4.351-36.11341-18.27425-3.91592-18.27425-3.91592,8.702-1.3053,8.702-8.702c0,0,24.80078-1.74041,32.1975-17.40406s14.35835-27.41138,14.35835-27.41138l16.969,43.51013s19.57956-36.11341,34.373-20.01466c0,0-16.53385-103.98922-29.15179-113.56145s-21.32-12.61794-33.0677-3.91591-22.62527,30.89219-22.62527,30.89219Z"
      transform="translate(-99 -38.44071)"
      fill="#2f2e41"
    />
    <path
      d="M742.27674,792.56157a98.03729,98.03729,0,0,0-8.702,7.39672c-3.48081,3.48081-8.26693,1.7404-8.702,0s-8.26692,1.3053-9.13713,13.92324-6.09142,23.93058,7.83183,27.84649,13.48814,15.22854,13.48814,15.22854a46.93427,46.93427,0,0,0,35.24321,2.17551c19.57956-6.52652,12.61794-13.48814,12.61794-13.48814l-26.10608-43.075S751.849,789.51586,742.27674,792.56157Z"
      transform="translate(-99 -38.44071)"
      fill="#2f2e41"
    />
    <path
      d="M670.04992,576.7513s41.76973,14.35834,46.12074,17.404,23.93058,17.40406,14.35835,24.80078S713.125,631.139,694.4156,625.48265s-39.59423-23.06037-39.59423-23.06037Z"
      transform="translate(-99 -38.44071)"
      fill="#ffb8b8"
    />
    <path
      d="M628.28019,557.60684s16.53385-3.04571,28.28159,3.48081,21.75507,19.57956,21.75507,19.57956-10.87754,23.06037-18.70936,26.54118-22.19017-13.92324-22.19017-13.92324Z"
      transform="translate(-99 -38.44071)"
      fill="#d0cde1"
    />
    <path
      d="M552.13746,544.9889s.4351,16.969-1.30531,18.70936,20.88487,53.08236,45.68564,30.45709,5.65632-31.76239,5.65632-31.76239-9.13713-16.969-8.702-18.70936-40.46442,0-40.46442,0Z"
      transform="translate(-99 -38.44071)"
      fill="#ffb8b8"
    />
    <path
      d="M552.13746,544.9889s.4351,16.969-1.30531,18.70936,20.88487,53.08236,45.68564,30.45709,5.65632-31.76239,5.65632-31.76239-9.13713-16.969-8.702-18.70936-40.46442,0-40.46442,0Z"
      transform="translate(-99 -38.44071)"
      opacity="0.1"
    />
    <path
      d="M559.09908,577.6215s16.53385,11.31264,23.49547,8.702,15.66365-13.92324,16.53385-14.79345,45.25054,88.32558,45.25054,88.32558-6.52652,32.6326-23.93057,42.20483-17.1865,17.1865-19.362,23.713-30.23954-20.66731-30.23954-20.66731l-17.83916-75.70764V583.27782Z"
      transform="translate(-99 -38.44071)"
      fill="#d0cde1"
    />
    <path
      d="M551.91991,557.38929s-5.43877-1.08775-11.09509,3.69836-32.1975,22.62527-30.89219,24.36568,13.053,26.54118,15.66365,36.54851,6.52652,11.31263,6.52652,11.31263,9.13712,6.52652,7.83182,11.31264-6.96162,70.05132.8702,84.40966,6.52652,26.97628,4.351,30.45709,20.88486-10.00733,20.88486-10.00733,34.373-27.84648,36.98361-36.11341a97.2633,97.2633,0,0,0,3.48082-17.404S584.335,608.0786,577.37334,603.29248,551.91991,557.38929,551.91991,557.38929Z"
      transform="translate(-99 -38.44071)"
      fill="#575a89"
    />
    <path
      d="M599.71989,557.73339s10.72115-6.65307,12.89665-4.91266,22.62527,1.7404,23.06038,3.48081,1.3053,43.51013,16.09874,54.38766,5.22122,32.63261,5.22122,32.63261l10.87753,17.404s-59.17285,56.99363-55.25787,37.41871c.21755-1.08775,0-1.3053,0-1.3053s-23.06037-98.768-20.01466-103.98922S601.18158,563.95137,599.71989,557.73339Z"
      transform="translate(-99 -38.44071)"
      fill="#575a89"
    />
    <path
      d="M690.93479,597.63616,631.761,618.08593s-58.73868,13.92324-49.60155,30.022,47.86115-11.31264,47.86115-11.31264l78.79959-8.82909S740.10124,601.55208,690.93479,597.63616Z"
      transform="translate(-99 -38.44071)"
      fill="#ffb8b8"
    />
    <circle cx="476.63293" cy="490.44944" r="32.1975" fill="#ffb8b8" />
    <path
      d="M549.992,496.8914l-5.95587-2.16577s12.45312-12.45313,29.77935-11.3702l-4.8731-4.873s11.91173-4.33147,22.74054,7.03872c5.69249,5.97707,12.27878,13.00279,16.38466,20.9172h6.37831l-2.66207,5.32414,9.31726,5.32415-9.56326-.95636a26.866,26.866,0,0,1-.90452,13.789l-2.1657,5.95579s-8.66311-17.32606-8.66311-19.49184v5.41441s-5.95587-4.87294-5.95587-8.12157L590.6,517.46609l-1.62431-5.95587-20.0333,5.95587,3.24862-4.87294-12.45312,1.62431,4.8731-5.95586s-14.07743,7.03871-14.619,12.99458c-.54138,5.95579-7.58,13.536-7.58,13.536l-3.24862-5.4144S534.29021,505.013,549.992,496.8914Z"
      transform="translate(-99 -38.44071)"
      fill="#2f2e41"
    />
    <path
      d="M970.06726,772.39362l-2-.03906a463.83471,463.83471,0,0,1,7.09961-66.28711c8.64844-46.88086,23.0293-77.66992,42.74316-91.51172l1.14844,1.63672C973.00671,648.52643,970.0907,771.15827,970.06726,772.39362Z"
      transform="translate(-99 -38.44071)"
      fill="#d0cde1"
    />
    <path
      d="M995.06726,771.91511l-2-.03907c.043-2.21484,1.293-54.41406,21.84277-68.84179l1.14844,1.63672C996.33777,718.51667,995.077,771.38288,995.06726,771.91511Z"
      transform="translate(-99 -38.44071)"
      fill="#d0cde1"
    />
    <circle cx="931.4364" cy="567.93338" r="10" fill={color} />
    <circle cx="926.48425" cy="653.93338" r="10" fill={color} />
    <path
      d="M990.406,622.3687c1.87935,12.004-3.0189,22.74063-3.0189,22.74063s-7.9453-8.72581-9.82465-20.72984,3.0189-22.74063,3.0189-22.74063S988.52667,610.36466,990.406,622.3687Z"
      transform="translate(-99 -38.44071)"
      fill={color}
    />
    <path
      d="M1023.06191,651.43122c-11.493,3.9422-22.9035,1.0342-22.9035,1.0342s7.22323-9.29957,18.71618-13.24177,22.90349-1.03419,22.90349-1.03419S1034.55486,647.489,1023.06191,651.43122Z"
      transform="translate(-99 -38.44071)"
      fill="#d0cde1"
    />
    <path
      d="M1023.46245,727.68359a31.134,31.134,0,0,1-16.06421.69365,28.37377,28.37377,0,0,1,29.172-10.0063A31.134,31.134,0,0,1,1023.46245,727.68359Z"
      transform="translate(-99 -38.44071)"
      fill="#d0cde1"
    />
    <path
      d="M904.98425,592.776a13,13,0,1,1,13-13A13.01442,13.01442,0,0,1,904.98425,592.776Zm0-24a11,11,0,1,0,11,11A11.01245,11.01245,0,0,0,904.98425,568.776Z"
      transform="translate(-99 -38.44071)"
      fill="#d0cde1"
    />
    <circle cx="745" cy="614" r="8" fill="#d0cde1" />
    <circle cx="36" cy="523" r="8" fill="#d0cde1" />
    <path d="M185,695.44071H159v-26h26Zm-24-2h22v-22H161Z" transform="translate(-99 -38.44071)" fill="#d0cde1" />
    <path
      d="M350,603.44071a50,50,0,1,0,50,50A50,50,0,0,0,350,603.44071Zm-8.62518,72.37488-21.50721-21.50733,6.0326-6.03253,15.49487,15.49494,32.70483-32.70484,6.03248,6.0326Z"
      transform="translate(-99 -38.44071)"
      fill={color}
    />
  </svg>
)

export default StatsImage

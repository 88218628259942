import React, { FC } from "react"
import { Box, CircularProgress } from "@material-ui/core"

const LoadingDisplay: FC = () => (
  <Box py={4} display="flex" justifyContent="center">
    <CircularProgress color="primary" />
  </Box>
)

export default LoadingDisplay

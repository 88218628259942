import React, { FC, useState, useEffect } from "react"
import { Box, Button, Container, Typography, Paper, Modal, makeStyles, colors, Tabs, Tab, Fab } from "@material-ui/core"
import { CheckCircleRounded, CloseRounded, Twitter } from "@material-ui/icons"
import cx from "classnames"
import firebase from "firebase"

import { GoogleIcon } from "components/svgs"
import RaisedTextField from "components/RaisedTextField"

const useStyles = makeStyles(({ palette, spacing }) => ({
  contentContainer: {
    outline: 0,
  },
  container: {
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    maxHeight: "75vh",
    backgroundColor: palette.background.default,
    padding: spacing(2),
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: spacing(-2),
    right: spacing(-2),
  },
  tabs: {
    marginBottom: spacing(2),
  },
  field: {
    marginBottom: spacing(2),
  },
  suggestion: {
    marginBottom: spacing(),
  },
  suggestionAction: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  error: {
    marginTop: spacing(),
  },
}))

export const UserLoginModal: FC<{
  open: boolean
  close: () => void
}> = ({ open, close }) => {
  const classes = useStyles()
  return (
    <Modal open={open}>
      <Box
        className={classes.contentContainer}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Container className={classes.container}>
          <Paper className={classes.paper}>
            <UserLogin />
            <Fab className={classes.closeButton} size="small" color="primary" onClick={close}>
              <CloseRounded />
            </Fab>
          </Paper>
        </Container>
      </Box>
    </Modal>
  )
}

const UserLogin: FC = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)

  return (
    <Box>
      <Tabs
        className={classes.tabs}
        value={tabValue}
        onChange={(_, newTabValue) => setTabValue(newTabValue)}
        centered
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Sign Up" />
        <Tab label="Log In" />
        <Tab label="" style={{ display: "none" }} />
      </Tabs>

      <SignUpPanel value={tabValue} index={0} gotoLogin={() => setTabValue(1)} />
      <LogInPanel value={tabValue} index={1} onForgotPassword={() => setTabValue(2)} />

      <ForgotPasswordPanel value={tabValue} index={2} />

      <SocialLogin value={tabValue} />
    </Box>
  )
}

function validateEmail(email: string) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const SignUpPanel: FC<{
  value: number
  index: number
  gotoLogin: () => void
}> = ({ value, index, gotoLogin }) => {
  const classes = useStyles()

  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")

  const passwordMinLength = password1.length >= 6
  const passwordsOK = password1 && password2 && password1 === password2 && passwordMinLength

  const canSubmit = email && validateEmail(email) && passwordsOK

  const submit = async () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password1)
      .then(() => {
        firebase.analytics().logEvent("sign_up", { method: "email_and_password" })
      })
      .catch((e: firebase.auth.Error) => {
        console.log("Email Password Signup Error: ", e)
        setMessage(e.message)
        setPassword1("")
        setPassword2("")
      })
  }

  useEffect(() => setMessage(""), [value])

  return (
    <div hidden={value != index}>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <RaisedTextField
          className={classes.field}
          placeholder="email"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <RaisedTextField
          className={classes.field}
          placeholder="password"
          type="password"
          value={password1}
          onChange={e => setPassword1(e.target.value)}
        />
        <RaisedTextField
          className={classes.field}
          placeholder="confirm password"
          type="password"
          value={password2}
          onChange={e => setPassword2(e.target.value)}
          trailing={<CheckCircleRounded htmlColor={passwordsOK ? colors.green[400] : colors.grey[400]} />}
        />

        <Typography className={classes.suggestion} variant="caption" align="center">
          Already have an account?{" "}
          <span className={classes.suggestionAction} onClick={gotoLogin}>
            Log in here
          </span>
        </Typography>

        <Button variant="contained" color="primary" disabled={!canSubmit} onClick={submit}>
          Sign up
        </Button>

        {message && (
          <Typography className={classes.error} variant="caption" align="center" color="error">
            {message}
          </Typography>
        )}
      </Box>
    </div>
  )
}

const LogInPanel: FC<{
  value: number
  index: number
  onForgotPassword: () => void
}> = ({ value, index, onForgotPassword }) => {
  const classes = useStyles()

  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const passwordsOK = password.length >= 6

  const canSubmit = email && validateEmail(email) && passwordsOK

  const submit = async () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        firebase.analytics().logEvent("login", { method: "email_and_password" })
      })
      .catch((e: firebase.auth.Error) => {
        console.log("Email Password Login Error: ", e)
        setMessage(e.message)
        setPassword("")
      })
  }

  useEffect(() => setMessage(""), [value])

  return (
    <div hidden={value != index}>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <RaisedTextField
          className={classes.field}
          placeholder="email"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <RaisedTextField
          className={classes.field}
          placeholder="password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />

        <Typography
          className={cx(classes.suggestion, classes.suggestionAction)}
          variant="caption"
          align="center"
          onClick={onForgotPassword}
        >
          Forgot Password?
        </Typography>

        <Button variant="contained" color="primary" disabled={!canSubmit} onClick={submit}>
          Log In
        </Button>

        {message && (
          <Typography className={classes.error} variant="caption" align="center" color="error">
            {message}
          </Typography>
        )}
      </Box>
    </div>
  )
}

const ForgotPasswordPanel: FC<{
  value: number
  index: number
}> = ({ value, index }) => {
  const classes = useStyles()

  const [message, setMessage] = useState("")
  const [error, setError] = useState("")
  const [email, setEmail] = useState("")

  const canSubmit = email && validateEmail(email)

  const submit = async () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        firebase.analytics().logEvent("auth-password_reset_request")
        setMessage("A password reset email has been sent to you.")
      })
      .catch((e: firebase.auth.Error) => {
        setError(e.message)
      })
  }

  useEffect(() => setMessage(""), [value])

  return (
    <div hidden={value != index}>
      <Box display="flex" flexDirection="column" justifyContent="center">
        {message && (
          <Typography className={classes.suggestion} variant="caption" align="center">
            {message}
          </Typography>
        )}

        <RaisedTextField
          className={classes.field}
          placeholder="email"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />

        <Button variant="contained" color="primary" disabled={!canSubmit} onClick={submit}>
          Send Reset Email
        </Button>

        {error && (
          <Typography className={classes.error} variant="caption" align="center" color="error">
            {error}
          </Typography>
        )}
      </Box>
    </div>
  )
}

const SocialLogin: FC<{
  value: number
}> = ({ value }) => {
  const classes = useStyles()
  const [error, setError] = useState("")

  useEffect(() => setError(""), [value])

  const google = async () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(userCreds => {
        console.log("Google User Credentials: ", userCreds)
        if (userCreds.additionalUserInfo?.isNewUser) {
          firebase.analytics().logEvent("sign_up", { method: "google" })
        } else {
          firebase.analytics().logEvent("login", { method: "google" })
        }
      })
      .catch((e: firebase.auth.Error) => {
        console.log("Google Login Error: ", e)
        setError(e.message)
      })
  }

  const twitter = async () => {
    const provider = new firebase.auth.TwitterAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(userCreds => {
        console.log("Twitter User Credentials: ", userCreds)
        if (userCreds.additionalUserInfo?.isNewUser) {
          firebase.analytics().logEvent("sign_up", { method: "twitter" })
        } else {
          firebase.analytics().logEvent("login", { method: "twitter" })
        }
      })
      .catch((e: firebase.auth.Error) => {
        console.log("Twitter Login Error: ", e)
        setError(e.message)
      })
  }

  return (
    <>
      <Box py={2} display="flex" justifyContent="center">
        <Typography variant="body2" align="center">
          or
        </Typography>
      </Box>

      <Button className={classes.field} variant="outlined" fullWidth onClick={google}>
        <Box pr={2} display="flex">
          <GoogleIcon />
        </Box>{" "}
        Sign in with Google
      </Button>

      <Button className={classes.field} variant="outlined" fullWidth onClick={twitter}>
        <Box pr={2} display="flex">
          <Twitter htmlColor="#1DA1F2" />
        </Box>{" "}
        Sign in with Twitter
      </Button>

      {error && (
        <Typography className={classes.error} variant="caption" align="center" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}

export default UserLogin

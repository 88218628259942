import MOError from "utils/error"

import useDocExists from "hooks/useDocExists"

interface DocExistenceOptions {
  path?: string
  onCreate: () => void
  onDelete: () => void
  onError: (error: MOError) => void
}
const useDocExistence = ({ path, onCreate, onDelete, onError }: DocExistenceOptions) => {
  const { loading, exists, error } = useDocExists(path)

  const ready = !loading && !error

  const createDoc = () => {
    if (!ready && error) return onError(error)
    if (ready && !exists) onCreate()
  }

  const deleteDoc = () => {
    if (!ready && error) return onError(error)
    if (ready && exists) onDelete()
  }

  const toggleDoc = () => {
    if (!ready && error) return onError(error)
    if (ready) {
      if (exists) onDelete()
      else onCreate()
    }
  }

  return { loading, exists, error, createDoc, deleteDoc, toggleDoc }
}

export default useDocExistence

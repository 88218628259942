export enum MOErrorType {
  MISSING_PARAMETERS,
  DOCUMENT_DOES_NOT_EXISTS,
  USER_DOES_NOT_EXISTS,
  USERNAME_DOES_NOT_EXISTS,
  FAILED_OPERATION,
  DOES_NOT_MEET_EXPECTATIONS,
  QUERY_IS_EMPTY,
  REQUIRES_LOGIN,
}

class MOError extends Error {
  type: MOErrorType
  constructor(type: MOErrorType, message?: string) {
    super(message)
    this.type = type
  }
}

export default MOError

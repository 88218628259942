import React, { FC, createContext, useState, useContext, useEffect, useReducer, Reducer } from "react"
import firebase from "firebase"
import * as movieotter from "@isaiahbydayah/movieotter-core"

import UserProfileEdit from "components/UserProfileEdit"
import { UserLoginModal } from "components/UserLogin"

interface AuthState {
  loginModalOpen: boolean
  editProfileModalOpen: boolean

  firebaseUser?: firebase.User
  currentUser?: movieotter.User

  unreadNotifications: number
}

type AuthAction =
  | { type: "OPEN_LOGIN_MODAL" }
  | { type: "CLOSE_LOGIN_MODAL" }
  | { type: "OPEN_PROFILE_EDIT_MODAL" }
  | { type: "CLOSE_PROFILE_EDIT_MODAL" }
  | { type: "LOGIN"; user: movieotter.User; firebaseUser: firebase.User }
  | { type: "LOGOUT" }
  | { type: "UNREAD_NOTIFICATIONS_COUNT_CHANGED"; count: number }

const authReducer: Reducer<AuthState, AuthAction> = (state, action) => {
  switch (action.type) {
    case "OPEN_LOGIN_MODAL":
      return {
        ...state,
        loginModalOpen: true,
        editProfileModalOpen: false,
      }
    case "CLOSE_LOGIN_MODAL":
      return {
        ...state,
        loginModalOpen: false,
      }
    case "OPEN_PROFILE_EDIT_MODAL":
      return {
        ...state,
        editProfileModalOpen: state.currentUser ? true : false,
        loginModalOpen: false,
      }
    case "CLOSE_PROFILE_EDIT_MODAL":
      return {
        ...state,
        editProfileModalOpen: false,
      }
    case "LOGIN":
      return {
        ...state,
        firebaseUser: action.firebaseUser,
        currentUser: action.user,
        loginModalOpen: false,
      }
    case "LOGOUT":
      return {
        ...state,
        firebaseUser: undefined,
        currentUser: undefined,
        loginModalOpen: false,
        editProfileModalOpen: false,
        unreadNotifications: 0,
      }
    case "UNREAD_NOTIFICATIONS_COUNT_CHANGED":
      return {
        ...state,
        unreadNotifications: action.count,
      }
  }
}

interface AuthContext {
  currentUser?: movieotter.User

  unreadNotifications: number

  openLoginModal: () => void
  openProfileEditModal: () => void
  closeLoginModal: () => void
  closeProfileEditModal: () => void
  logout: () => void
}

const AuthContext = createContext<AuthContext>({
  unreadNotifications: 0,

  openLoginModal: () => null,
  openProfileEditModal: () => null,
  closeLoginModal: () => null,
  closeProfileEditModal: () => null,
  logout: () => null,
})

const AuthProvider: FC = ({ children }) => {
  const [fbUser, setFBUser] = useState<firebase.User>()

  const [{ loginModalOpen, currentUser, editProfileModalOpen, unreadNotifications }, dispatch] = useReducer(
    authReducer,
    {
      loginModalOpen: false,
      editProfileModalOpen: false,
      firebaseUser: undefined,
      currentUser: undefined,
      unreadNotifications: 0,
    }
  )

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(u => {
      setFBUser(u ?? undefined)
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    let unsubscribe: (() => void) | undefined
    if (fbUser?.uid) {
      unsubscribe = firebase
        .firestore()
        .doc(movieotter.User.documentPathForUser(fbUser.uid))
        .onSnapshot(snapshot => {
          if (snapshot.exists) {
            const userData = snapshot.data({
              serverTimestamps: "estimate",
            }) as movieotter.UserData<movieotter.FirebaseTimestamp>
            const u = movieotter.User.fromDocument(userData)
            dispatch({ type: "LOGIN", user: u, firebaseUser: fbUser })
            if (!u.isUpToDate) {
              try {
                firebase.functions().httpsCallable("updateUser")({
                  userId: u.userId,
                })
              } catch (e) {
                console.warn("UPDATE USER ERROR: ", e)
              }
            }
          }
        })
    }

    return () => unsubscribe?.()
  }, [fbUser?.uid])

  useEffect(() => {
    let unsubscribe: (() => void) | undefined
    if (currentUser?.userId) {
      unsubscribe = firebase
        .firestore()
        .doc(movieotter.UserNotifications.documentPathFor(currentUser.userId))
        .onSnapshot(snap => {
          if (snap.exists) {
            const unreadNotificationsData = snap.data({
              serverTimestamps: "estimate",
            }) as movieotter.IUserNotifications

            dispatch({
              type: "UNREAD_NOTIFICATIONS_COUNT_CHANGED",
              count: unreadNotificationsData.unreadCount,
            })
          } else {
            dispatch({
              type: "UNREAD_NOTIFICATIONS_COUNT_CHANGED",
              count: 0,
            })
          }
        })
    }

    return () => unsubscribe?.()
  }, [currentUser?.userId])

  const logout = () => {
    firebase.auth().signOut()
    dispatch({ type: "LOGOUT" })
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        unreadNotifications,
        openLoginModal: () => dispatch({ type: "OPEN_LOGIN_MODAL" }),
        openProfileEditModal: () => dispatch({ type: "OPEN_PROFILE_EDIT_MODAL" }),
        closeLoginModal: () => dispatch({ type: "CLOSE_LOGIN_MODAL" }),
        closeProfileEditModal: () => dispatch({ type: "CLOSE_PROFILE_EDIT_MODAL" }),
        logout,
      }}
    >
      {children}
      <UserLoginModal open={loginModalOpen} close={() => dispatch({ type: "CLOSE_LOGIN_MODAL" })} />
      <UserProfileEdit open={editProfileModalOpen} />
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext)
